import { Util } from "src/utils/Util";

export class PaymentCost {

  /**
   *
   */
  private _netAmount: number;
  set netAmount(_netAmount: number) { this._netAmount = _netAmount };
  get netAmount(): number { return this._netAmount };
	/**
	 * 
	 */
	private _civilTax: number;
	set civilTax(_civilTax: number) { this._civilTax = _civilTax };
	get civilTax(): number { return this._civilTax };
  /**
   *
   */
  private _subTotal: number;
  set subTotal(_subTotal: number) { this._subTotal = _subTotal };
  get subTotal(): number { return this._subTotal };
  /**
   *
   */
  private _expeditionRights: number;
  set expeditionRights(_expeditionRights: number) { this._expeditionRights = _expeditionRights };
  get expeditionRights(): number { return this._expeditionRights };
  /**
   *
   */
  private _tax: number;
  set tax(_tax: number) { this._tax = _tax };
  get tax(): number { return this._tax };
	/**
	 * 
	 */
	private _monthsFee: number;
	set monthsFee(_monthsFee: number) { this._monthsFee = _monthsFee };
	get monthsFee(): number { return this._monthsFee };
	/**
	 * 
	 */
	private _monthsFeePercent: number;
	set monthsFeePercent(_monthsFeePercent: number) { this._monthsFeePercent = _monthsFeePercent };
	get monthsFeePercent(): number { return this._monthsFeePercent };
  /**
   *
   */
  private _total: number;
  set total(_total: number) { this._total = _total };
  get total(): number { return this._total };
  /**
   *
   */
  private _isSubscription: boolean = false;
  set isSubscription(_isSubscription: boolean) { this._isSubscription = _isSubscription };
  get isSubscription(): boolean { return this._isSubscription };
	/**
	 * 
	 */
	private _monthsQuantity: number;
	set monthsQuantity(_monthsQuantity: number) { this._monthsQuantity = _monthsQuantity };
	get monthsQuantity(): number { return this._monthsQuantity };

  constructor(_subTotal?: number, _tax?: number, _total?: number, _netAmount?: number, _civilTax?: number, _monthsQuantity?: number, _monthsFee?: number, _monthsFeePercent?: number) {
    this._subTotal = _subTotal ? _subTotal : 0;
    this._tax = _tax ? _tax : 0;
		this._monthsFee = _monthsFee ? _monthsFee : 0;
		this._monthsFeePercent = _monthsFeePercent ? _monthsFeePercent: 0;
    this._total = _total ? _total : 0;
    this._netAmount = _netAmount ? _netAmount : 0;
		this._monthsQuantity = _monthsQuantity ? _monthsQuantity : 0;
		this._civilTax = _civilTax ? _civilTax : 0;
    this._expeditionRights = 0;
  }

  /**
   * 
   */
  public getTotal(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._total);
  }

  /**
   * 
   */
  public getTaxes(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._tax);
  }

  /**
   * 
   */
  public getSubTotal(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._subTotal);
  }

  /**
   * 
   */
  public getNetAmount(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._netAmount);
  }

	/**
	 * 
	 * @returns 
	 */
	public getMonthFeeAmount(): string {
		return Util.TRANSFORM.CURRENCY.MXN(this._monthsFee);
	}

	/**
	 * 
	 * @returns 
	 */
	public getCivilTaxAmount(): string {
		return Util.TRANSFORM.CURRENCY.MXN(this._civilTax);
	}

	/**
	 * 
	 * @returns 
	 */
	public getFeePercent(): string {
		return `${this._monthsFeePercent}%`;
	}

	/**
	 * 
	 */
	public getAmountByMonth(): string {
		if(this.monthsQuantity > 0){
			const amountPerMonth = +(this.total / this.monthsQuantity).toFixed(2);
			return Util.TRANSFORM.CURRENCY.MXN(amountPerMonth);
		} else 
			return '';
	}
}