import { Controller } from 'react-hook-form';
import { BackButton, Button, FormError, Input, Text } from 'src/components';
import { IconFactory } from "src/factories/icon.factory";
import { AccountFacade } from 'src/modules/quoter/controller/facades/account.facade';
import { QUOTER_PATH } from 'src/routes/paths/quoter.paths';
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterPersonAccount.scss';

/**
 *
 */
const QuoterPersonAccountPage = (): JSX.Element => {
  const { person, form, validatePassword, passwordState, showRules, setShowRules } = AccountFacade.usePasswordValidation();
  const { creating, search, createAccount } = AccountFacade.useAccount();

  return (
    <div className="personAccount">
      {
        person &&

        <QuoterContent
          backButton={<BackButton to={`${QUOTER_PATH.QUOTER_PLAN_REVIEW}${search}`} />}
          title="Puedes ver y modificar tu póliza desde tu cuenta"
          subtitle="Accede creando tu contraseña"
        >
          <div className="personAccount__password">
            <Text type="paragraph" align="center" className="personAccount__password__email personAccount__password__email--title" bold>Tu correo electr&oacute;nico</Text>
            <Text type="paragraph" align="center" className="personAccount__password__email">{person.email}</Text>

            <form className="personAccount__password__input">
              <Controller
                control={form.control}
                name="newPassword"
                rules={
                  {
                    required: true,
                    validate: {
                      patternSpecificWord: (password) => validatePassword.validateSpecificWord(password),
                      repeatedElements: (password) => validatePassword.validateRepeatedElements(password),
                      secuentialElements: (password) => validatePassword.validateSecuentialElements(password),
                      includesCurrentEmail: (password) => validatePassword.validateCurrentEmail(password, person.email),
                      invalidParams: (password) => validatePassword.validatePasswordParams(password),
                    }
                  }
                }
                render={({ field: { onChange } }) => (
                  <Input
                    required
                    label="CREA TU CONTRASEÑA"
                    type="password"
                    autoComplete="new-password"
                    errors={!!form.formState.errors.newPassword}
                    didFocus={() => setShowRules(true)}
                    didBlur={() => setShowRules(false)}
                    onChange={(e) => { onChange(e.target.value); validatePassword.validateOnChange(e.target.value as string, form.watch().confirmNewPassword) }} />
                )}
              />

              <FormError classNames="personAccount__password__input__error" schema={AccountFacade.PASSWORD_ERRORS} type={form.formState.errors.newPassword?.type} />
              {
                showRules &&
                <div className="personAccount__password__input__current-rules">
                  <div className="personAccount__password__input__current-rules__bg"></div>
                  <span className="personAccount__password__input__current-rules__arrow personAccount__password__input__current-rules__arrow--shadow"></span>
                  <span className="personAccount__password__input__current-rules__arrow personAccount__password__input__current-rules__arrow"></span>

                  <Text type="label" block bold>La contrase&ntilde;a debe tener:</Text>

                  <div className="personAccount__password__input__current-rules__item">
                    {
                      !passwordState.hasUppercase ?
                        <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                          {IconFactory.closeIcon()}
                        </span>
                        :
                        <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                          {IconFactory.checkIcon()}
                        </span>
                    }
                    <Text type="label" className="personAccount__password__input__current-rules__item__text">Una may&uacute;scula</Text>
                  </div>

                  <div className="personAccount__password__input__current-rules__item">
                    {
                      !passwordState.hasLowercase ?
                        <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                          {IconFactory.closeIcon()}
                        </span>
                        :
                        <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                          {IconFactory.checkIcon()}
                        </span>
                    }
                    <Text type="label" className="personAccount__password__input__current-rules__item__text">Una min&uacute;scula</Text>
                  </div>

                  <div className="personAccount__password__input__current-rules__item">
                    {
                      !passwordState.hasDigit ?
                        <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                          {IconFactory.closeIcon()}
                        </span>
                        :
                        <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                          {IconFactory.checkIcon()}
                        </span>
                    }
                    <Text type="label" className="personAccount__password__input__current-rules__item__text">Un n&uacute;mero</Text>
                  </div>

                  <div className="personAccount__password__input__current-rules__item">
                    {
                      !passwordState.validLength ?
                        <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                          {IconFactory.closeIcon()}
                        </span>
                        :
                        <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                          {IconFactory.checkIcon()}
                        </span>
                    }
                    <Text type="label" className="personAccount__password__input__current-rules__item__text">8 caracteres m&iacute;nimo</Text>
                  </div>
                </div>
              }

            </form>

            <section className="personAccount__password__input">
              <Controller
                control={form.control}
                name="confirmNewPassword"
                rules={
                  {
                    required: true,
                    validate: {
                      notSame: (confirmNewPassword) => validatePassword.validateSamePassword(confirmNewPassword, form.watch().newPassword),
                    }
                  }
                }
                render={({ field: { onChange } }) => (
                  <Input
                    required
                    label="CONFIRMA TU CONTRASEÑA"
                    type="password"
                    autoComplete="new-password"
                    errors={!!form.formState.errors.confirmNewPassword}
                    onChange={(e) => onChange(e.target.value)} />
                )}
              />

              <FormError schema={AccountFacade.CONFIRM_PASSWORD_ERRORS} type={form.formState.errors.confirmNewPassword?.type} />
            </section>

            <section className="personAccount__password__rules">
              <Text type="label" block>Por tu seguridad la contraseña <b>no</b> debe incluir</Text>
              <ul>
                <li>
                  <Text type="label" block>La palabra Crabi <b>(ej. crabi, Crabi, CRABi, etc.)</b></Text>
                </li>
                <li>
                  <Text type="label" block>3 o más caracteres iguales y juntos <b>(ej. 111, aAa, BBB, etc.)</b>.</Text>
                </li>
                <li>
                  <Text type="label" block>Secuencia de 3 o más números o letras <b>(ej. 123, abc, etc.)</b>.</Text>
                </li>
                <li>
                  <Text type="label" block>El nombre del correo que registrarás o registraste <b>(ej. si el correo es paula@gmail.com, no puede contener Paula, PAULA, paula, etc.)</b>.</Text>
                </li>
              </ul>
            </section>

            <section className="personAccount__password__button">
              <Button
                type="button"
                disabled={!form.formState.isValid || creating.value}
                loading={creating.value}
                onClick={() => createAccount(form.getValues('newPassword'))}
              >
                Crear cuenta
              </Button>
            </section>
          </div>
        </QuoterContent>
      }

      {
        person &&
        <div className="personAccount__password personAccount__password--mobile">
          <Text type="paragraph" align="center" className="personAccount__password__email">{person.email}</Text>

          <section className="personAccount__password__input">
            <Controller
              control={form.control}
              name="newPassword"
              rules={
                {
                  required: true,
                  validate: {
                    patternSpecificWord: (password) => validatePassword.validateSpecificWord(password),
                    repeatedElements: (password) => validatePassword.validateRepeatedElements(password),
                    secuentialElements: (password) => validatePassword.validateSecuentialElements(password),
                    includesCurrentEmail: (password) => validatePassword.validateCurrentEmail(password, person.email),
                    invalidParams: (password) => validatePassword.validatePasswordParams(password),
                  }
                }
              }
              render={({ field: { onChange } }) => (
                <Input
                  required
                  label="CREA TU CONTRASEÑA"
                  type="password"
                  autoComplete="new-password"
                  errors={!!form.formState.errors.newPassword}
                  didFocus={() => setShowRules(true)}
                  didBlur={() => setShowRules(false)}
                  onChange={(e) => { onChange(e.target.value); validatePassword.validateOnChange(e.target.value as string, form.watch().confirmNewPassword) }} />
              )}
            />

            <FormError classNames="personAccount__password__input__error" schema={AccountFacade.PASSWORD_ERRORS} type={form.formState.errors.newPassword?.type} />
            {
              showRules &&
              <div className="personAccount__password__input__current-rules">
                <div className="personAccount__password__input__current-rules__bg"></div>
                <span className="personAccount__password__input__current-rules__arrow personAccount__password__input__current-rules__arrow--shadow"></span>
                <span className="personAccount__password__input__current-rules__arrow personAccount__password__input__current-rules__arrow"></span>

                <Text type="label" block bold>La contrase&ntilde;a debe tener:</Text>

                <div className="personAccount__password__input__current-rules__item">
                  {
                    !passwordState.hasUppercase ?
                      <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                        {IconFactory.closeIcon()}
                      </span>
                      :
                      <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                        {IconFactory.checkIcon()}
                      </span>
                  }
                  <Text type="label" className="personAccount__password__input__current-rules__item__text">Una may&uacute;scula</Text>
                </div>

                <div className="personAccount__password__input__current-rules__item">
                  {
                    !passwordState.hasLowercase ?
                      <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                        {IconFactory.closeIcon()}
                      </span>
                      :
                      <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                        {IconFactory.checkIcon()}
                      </span>
                  }
                  <Text type="label" className="personAccount__password__input__current-rules__item__text">Una min&uacute;scula</Text>
                </div>

                <div className="personAccount__password__input__current-rules__item">
                  {
                    !passwordState.hasDigit ?
                      <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                        {IconFactory.closeIcon()}
                      </span>
                      :
                      <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                        {IconFactory.checkIcon()}
                      </span>
                  }
                  <Text type="label" className="personAccount__password__input__current-rules__item__text">Un n&uacute;mero</Text>
                </div>

                <div className="personAccount__password__input__current-rules__item">
                  {
                    !passwordState.validLength ?
                      <span className="personAccount__password__input__current-rules__item__icon personAccount__password__input__current-rules__item__icon--invalid">
                        {IconFactory.closeIcon()}
                      </span>
                      :
                      <span className="personAccount__password__input__current-rules__item__icon  personAccount__password__input__current-rules__item__icon--valid">
                        {IconFactory.checkIcon()}
                      </span>
                  }
                  <Text type="label" className="personAccount__password__input__current-rules__item__text">8 caracteres m&iacute;nimo</Text>
                </div>
              </div>
            }

          </section>

          <section className="personAccount__password__input">
            <Controller
              control={form.control}
              name="confirmNewPassword"
              rules={
                {
                  required: true,
                  validate: {
                    notSame: (confirmNewPassword) => validatePassword.validateSamePassword(confirmNewPassword, form.watch().newPassword),
                  }
                }
              }
              render={({ field: { onChange } }) => (
                <Input
                  required
                  label="CONFIRMA TU CONTRASEÑA"
                  type="password"
                  autoComplete="new-password"
                  errors={!!form.formState.errors.confirmNewPassword}
                  onChange={(e) => onChange(e.target.value)} />
              )}
            />

            <FormError schema={AccountFacade.CONFIRM_PASSWORD_ERRORS} type={form.formState.errors.confirmNewPassword?.type} />
          </section>

          <section className="personAccount__password__rules">
            <Text type="label" block>Por tu seguridad la contraseña <b>no</b> debe incluir</Text>
            <ul>
              <li>
                <Text type="label" block>La palabra Crabi <b>(ej. crabi, Crabi, CRABi, etc.)</b></Text>
              </li>
              <li>
                <Text type="label" block>3 o más caracteres iguales y juntos <b>(ej. 111, aAa, BBB, etc.)</b>.</Text>
              </li>
              <li>
                <Text type="label" block>Secuencia de 3 o más números o letras <b>(ej. 123, abc, etc.)</b>.</Text>
              </li>
              <li>
                <Text type="label" block>El nombre del correo que registrarás o registraste <b>(ej. si el correo es paula@gmail.com, no puede contener Paula, PAULA, paula, etc.)</b>.</Text>
              </li>
            </ul>
          </section>

          <section className="personAccount__password__button">
            <Button
              type="button"
              disabled={!form.formState.isValid || creating.value}
              loading={creating.value}
              onClick={() => createAccount(form.getValues('newPassword'))}
            >
              Crear cuenta
            </Button>
          </section>
        </div>
      }
    </div>
  );
};

export default QuoterPersonAccountPage;