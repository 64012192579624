import { PropsWithChildren } from 'react';
import './Text.scss';

/**
 *
 */
interface IText {
  /**
   * 
   */
  type: 'title' | 'subtitle' | 'paragraph' | 'label';
  /**
   * 
   */
  align?: 'left' | 'center' | 'right';
  /**
   * 
   */
  color?: 'main' | 'white' | 'red' | 'green' | 'light-grey';
	/**
	 * 
	 */
	uppercase?: boolean;
  /**
   * 
   */
  bold?: boolean;
  /**
   * 
   */
  block?: boolean;
  /**
   * 
   */
  className?: string;
}

/**
 *
 */
export const Text = (props: PropsWithChildren<IText>): JSX.Element => {

  const { 
    align = 'left',
    color = props.color? props.color : 'default',
		bold = props.type === 'title' || props.bold,
		uppercase
   } = props;

  return (
    <>
      {
        props.type === 'title' &&
        <h1 className={`text text--type-title text--color-${color} text--${align} ${bold? ' text--bold' : ''} ${uppercase? 'text--uppercase' : ''} ${props.className? ` ${props.className}` : ''}`}>
          {props.children}
        </h1>
      }

      {
        props.type === 'subtitle' &&
        <h2 className={`text text--type-subtitle text--color-${color} text--${align} ${bold? ' text--bold' : ''} ${uppercase? 'text--uppercase' : ''} ${props.className? ` ${props.className}` : ''}`}>
          {props.children}
        </h2>
      }

      {
        props.type === 'paragraph' &&
        <p className={`text text--type-paragraph text--color-${color} text--${align} ${bold? ' text--bold' : ''} ${uppercase? 'text--uppercase' : ''} ${props.className? ` ${props.className}` : ''}`}>
          {props.children}
        </p>
      }

      {
        props.type === 'label' &&
        <span className={`text text--type-label text--color-${color} text--${align} ${bold? ' text--bold' : ''} ${uppercase? 'text--uppercase' : ''} ${props.block? ' text--block' : ''} ${props.className? ` ${props.className}` : ''}`}>
          {props.children}
        </span>
      }
    </>
  );
};