import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { User } from "src/classes/person/User";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IPlanStateReducer } from "src/store/states/modules-state/IPlanStateReducer";

/**
 *
 */
export namespace PlanSlice {
  /**
   * 
   */
  const initialState: IPlanStateReducer = {
    plansList: [],
    coverages: [],
    coupon: '',
    isCustomizing: false,
    isUpdating: false,
    isLoading: false,
    user: undefined,
    vehicle: undefined,
    currentPlan: undefined,
    currentRecomendedPlan: undefined,
    isRenewal: false,
    planConfig: undefined
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IPlanStateReducer> = (state) => {
    state.plansList = [];
    state.coverages = [];
    state.coupon = '';
    state.isCustomizing = false;
    state.isUpdating = false;
    state.isLoading = false;
    state.user = undefined;
    state.vehicle = undefined;
    state.currentPlan = undefined;
    state.currentRecomendedPlan = undefined;
    state.isRenewal = false;
    state.planConfig = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const plansList: CaseReducer<IPlanStateReducer, PayloadAction<Plan[]>> = (state, action) => {
    state.plansList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const coverages: CaseReducer<IPlanStateReducer, PayloadAction<Coverage[]>> = (state, action) => {
    state.coverages = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentCoupon: CaseReducer<IPlanStateReducer, PayloadAction<string>> = (state, action) => {
    state.coupon = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const isCustomizing: CaseReducer<IPlanStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isCustomizing = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const isLoading: CaseReducer<IPlanStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isLoading = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const isUpdating: CaseReducer<IPlanStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isUpdating = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const setPlanConfiguration: CaseReducer<IPlanStateReducer, PayloadAction<{ vehicle: Vehicle, user: User, planConfig?: number }>> = (state, action) => {
    state.vehicle = action.payload.vehicle;
    state.user = action.payload.user;
    state.planConfig = action.payload.planConfig;
  }
  /**
   * 
   * @param state 
   * @param action 
   */
  const setCurrentPlan: CaseReducer<IPlanStateReducer, PayloadAction<Plan>> = (state, action) => {
    state.currentPlan = action.payload;
  }
  /**
   * 
   * @param state 
   * @param action 
   */
  const setCurrentRecomendedPlan: CaseReducer<IPlanStateReducer, PayloadAction<Plan>> = (state, action) => {
    state.currentRecomendedPlan = action.payload;
  }

  const setPlanConfig: CaseReducer<IPlanStateReducer, PayloadAction<number>> = (state, action) => {
    state.planConfig = action.payload;
  }

  const reducers = {
    clear,
    plansList,
    isCustomizing,
    isUpdating,
    isLoading,
    currentCoupon,
    coverages,
    setPlanConfiguration,
    setCurrentPlan,
    setCurrentRecomendedPlan,
    setPlanConfig
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'planManagement',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}