import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Text, BackButton, Input, FormError, Button } from 'src/components';
import { Modal } from 'src/components/atoms/Modal/Modal';
import { QuoterVehicleIdFormFacade } from 'src/modules/quoter/controller/facades/quoter-vehicle-id-form.facade';
import { QuoterVehicleIdFacade } from 'src/modules/quoter/controller/facades/quoter-vehicle-id.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { VEHICLE_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import InputsGroup from '../../components/InputsGroup/InputsGroup';
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterVehicleId.scss';

/**
 *
 */
const QuoterVehicleIdPage = (): JSX.Element => {

	/**
	 * 
	 */
	const { storedVehicle } = QuoterFacade.useQuoterActors();
	const {
		stepNumber,
		vehicle,
		isNextDisabled,
		isValidatingVin,
		isVinHelperVisible,
		urlId,
		showVinLocationModal,
		setShowVinLocationModal,
		validateVehicleVin,
		nextPage,
		setIsNextDisabled,
		onBackRoute,
		setVehicleIdValues,
		stepHandler
	} = QuoterVehicleIdFacade.useQuoterVehicleId();
	const { formData, VIN_ERRORS, PLATE_ERRORS } = QuoterVehicleIdFormFacade.useQuoterVehicleIdForm(storedVehicle);
	const [triggerRender, setTriggerRender] = useState<boolean>(false);

	/**
	 * 
	 */
	const onSetData = () => {
		setVehicleIdValues(formData.getValues());
	}

	useEffect(() => {
		setTimeout(() => {
			setTriggerRender(true)
		}, 500)
	}, [])

	/**
	 * 
	 */
	useEffect(() => {
		if (vehicle) {
			const PLATE_LENGTH = 5;
			const VIN_LENGTH = 17;

			const disabledCondition = (urlId === VEHICLE_ROUTE_ID.PLATE && vehicle.plate.length >= PLATE_LENGTH && formData.formState.isValid) ||
				(urlId === VEHICLE_ROUTE_ID.VIN && vehicle.vin.length === VIN_LENGTH && formData.formState.errors.vin === undefined);

			setIsNextDisabled(!disabledCondition)
		}
	}, [urlId, vehicle, setIsNextDisabled, formData.formState.isValid, formData.formState.errors.vin]);

	return (
		<div className="quoterVehicleId">
			<QuoterContent
				backButton={<BackButton to={onBackRoute()} />}
				title="Completa tus datos del auto 🚗"
				subtitle="Necesitamos el número de serie y placas"
			>
				<form className="quoterVehicleId__form" onChange={onSetData}>

					{/* PATCH FOR SAFARI/CHROME NOT RENDERING COMPONENTS BUG ON IOS */}
					{ triggerRender && <div></div> }
			
					<InputsGroup
						elements={[
							{
								title: QuoterVehicleIdFacade.STEPS_TITLES.VIN,
								reactElement:
									<>
										<Controller
											name="vin"
											control={formData.control}
											rules={{
												required: true,
												pattern: Util.PATTERN.VEHICLE_VIN,
												validate: { invalidVin: async (vin) => vin && await validateVehicleVin(vin) }
											}}
											render={({ field }) => (
												<Input
													label="NÚMERO DE SERIE"
													uppercase
													defaultValue={vehicle ? vehicle.vin : ''}
													autoComplete="off"
													type="text"
													loading={isValidatingVin}
													disabled={isValidatingVin}
													maxLength={17}
													errors={!!formData.formState.errors.vin || (!!formData.formState.touchedFields.vin && formData.formState.errors.vin)}
													onChange={(e) => { field.onChange(e.target.value) }}
												/>
											)}
										/>

										<FormError schema={VIN_ERRORS} type={formData.formState.errors.vin?.type} />
									</>,
								hintOnMobile: {
									text: "¿Dónde está el número de serie?",
									trigger: () => {setShowVinLocationModal(true)}
								}
							},
							{
								title: QuoterVehicleIdFacade.STEPS_TITLES.PLATE,
								reactElement:
									<>
										<Controller
											name="plate"
											control={formData.control}
											rules={{
												required: true,
												pattern: Util.PATTERN.VEHICLE_PLATE,
												minLength: 5,
												maxLength: 7
											}}
											render={({ field }) => (
												<Input
													label="PLACAS"
													uppercase
													defaultValue={vehicle ? vehicle.plate : ''}
													autoComplete="off"
													type="text"
													maxLength={7}
													errors={!!formData.formState.errors.plate || (!!formData.formState.touchedFields.plate && formData.formState.errors.plate)}
													onChange={(e) => { field.onChange(e.target.value) }}
												/>
											)}
										/>

										<FormError schema={PLATE_ERRORS} type={formData.formState.errors.plate?.type} />
									</>
							},
						]}
						defaultStep={stepNumber}
						onStepChange={stepHandler}
						disabledButton={isNextDisabled}
						onComplete={nextPage}
					/>

					<button
						className={`quoterVehicleId__form__vinLocation ${isVinHelperVisible && 'quoterVehicleId__form__vinLocation--visible'}`}
						type="button"
						onClick={() => setShowVinLocationModal(true)}
					>
						¿Dónde está el número de serie?
					</button>
				</form>
			</QuoterContent>

			<Modal show={showVinLocationModal} position="center" backgroundDismiss onClose={() => setShowVinLocationModal(false)}>
				<div className="quoterVehicleId__vinLocationModal">
					{/* {IconFactory.closeIcon({ className: 'quoterVehicleId__vinLocationModal__closeIcon', onClick: () => setShowVinLocationModal(false) })} */}
					<img src={Util.ASSET.VIN_LOCATION_GUIDE} alt="All possible vin locations in a car." />

					<div className="quoterVehicleId__vinLocationModal__textWrapper">
						<Text className="quoterVehicleId__vinLocationModal__textWrapper__firstParagraph" type="paragraph">El número de serie (NIV o VIN) está localizado en 4 zonas de tu auto y en el carnet de circulación del auto. (Es un número de 17 caracteres alfanuméricos)</Text>

						<Text type="paragraph">1. Bajo el cofre, en la cara frontal del motor.</Text>
						<Text type="paragraph">2. En la esquina inferior izquierda del parabrisas.</Text>
						<Text type="paragraph">3. En el área del chasis, sobre la rueda delantera.</Text>
						<Text type="paragraph">4. En el marco de la puerta del conductor.</Text>
					</div>

					<Button onClick={() => setShowVinLocationModal(false)}>Entendido</Button>
				</div>
			</Modal>
		</div>
	);
};

export default QuoterVehicleIdPage;