import classNames from "classnames";
import { PropsWithChildren } from "react";
import useCollapse from "react-collapsed";
import { User } from "src/classes/person/User";
import { Plan } from "src/classes/plan/Plan";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Text } from "src/components";
import { IconFactory } from "src/factories/icon.factory";
import { Util } from "src/utils/Util";
import PlanReviewCoverage from "../PlanReviewCoverage/PlanReviewCoverage";
import './PlanReviewCard.scss';

/**
 *
 */
interface IPlanReviewCard {
	/**
	 * 
	 */
	title: string;
	/**
	 * 
	 */
	titleImage: string;
	/**
	 * 
	 */
	type: 'personal' | 'vehicle' | 'contact' | 'plan';
	/**
	 * 
	 */
	dataSource: User | Vehicle | Plan | undefined;
	/**
	 * 
	 */
	coverageVehicle?: Vehicle;
}

enum PlanReviewType {
	PERSONAL = 'personal',
	VEHICLE = 'vehicle',
	CONTACT = 'contact',
	PLAN = 'plan'
};

/**
 *
 */
const PlanReviewCard = (props: PropsWithChildren<IPlanReviewCard>): JSX.Element => {

  const { title, titleImage, type, dataSource, coverageVehicle } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ duration: 250, defaultExpanded: true, })

	const contentClassnames = classNames({
		'planReviewCard__content__personal': type === 'personal',
		'planReviewCard__content__vehicle': type === 'vehicle',
		'planReviewCard__content__contact': type === 'contact',
		'planReviewCard__content__plan': type === 'plan',
	})

  return (
    <div className="planReviewCard">
			<section className="planReviewCard__title" {...getToggleProps()}>
				<div className="planReviewCard__title__image" style={ { backgroundImage: `url(${titleImage})` } }></div>
				<Text type="paragraph" align="left" bold uppercase>{title}</Text>
				<button className={`planReviewCard__title__button ${isExpanded ? 'planReviewCard__title__button--rotated' : ''}`}>
					{IconFactory.downArrowIcon()}
				</button>
			</section>

			<section className={`planReviewCard__content ${contentClassnames}`} {...getCollapseProps()}>
				{
					type === PlanReviewType.PERSONAL && dataSource instanceof User &&
						<div className={`planReviewCard__content__wrapper`}>
							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Nombre</Text>
								<Text type="label">{dataSource.name}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Apellido Paterno</Text>
								<Text type="label">{dataSource.lastName}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Apellido Materno</Text>
								<Text type="label">{dataSource.secondLastName}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Sexo al nacer</Text>
								<Text type="label">{dataSource.profile && dataSource.profile.gender === Util.STATUS_CODE.GENDER.MALE ? 'Masculino' : 'Femenino'}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Nacionalidad</Text>
								<Text type="label">{dataSource.profile && dataSource.profile.nationalityCode === 1 ? 'Mexicana' : 'Extranjera'}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">RFC</Text>
								<Text type="label">{dataSource.profile && dataSource.profile.rfc}</Text>
							</div>
						</div>
				}

				{
					type === PlanReviewType.VEHICLE && dataSource instanceof Vehicle &&
						<div className={`planReviewCard__content__wrapper`}>
							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Año</Text>
								<Text type="label">{dataSource.year && dataSource.year.value}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Marca</Text>
								<Text type="label">{dataSource.brand && dataSource.brand.name}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Modelo</Text>
								<Text type="label">{dataSource.model && dataSource.model.name}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Versión</Text>
								<Text type="label">{dataSource.version && dataSource.version.description}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Placa</Text>
								<Text type="label">{dataSource.plate}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Número de serie</Text>
								<Text type="label">{dataSource.vin}</Text>
							</div>
						</div>
				}

				{
					type === PlanReviewType.CONTACT && dataSource instanceof User &&
						<div className={`planReviewCard__content__wrapper planReviewCard__content__wrapper--twoItems`}>
							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Teléfono celular</Text>
								<Text type="label">{dataSource.profile && dataSource.profile.phone && `(${dataSource.profile.phone.countryCode?.code}) ${dataSource.profile.phone.number}`}</Text>
							</div>

							<div className="planReviewCard__content__wrapper__item">
								<Text type="paragraph">Correo electrónico</Text>
								<Text type="label">{dataSource.email}</Text>
							</div>
						</div>
				}

				{
					type === PlanReviewType.PLAN && dataSource instanceof Plan && coverageVehicle &&
						<div className={`planReviewCard__content__wrapper planReviewCard__content__wrapper__coverages`}>
							{
								dataSource.coveragesList.map((c, idx) => {
									if (c.isEnabled) {
										return <PlanReviewCoverage key={idx} coverage={c} vehicle={coverageVehicle}/>
									} else 
										return null;
								})
							}
						</div>
				}
			</section>
    </div>
  );
};

export default PlanReviewCard;