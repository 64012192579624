import { createRef, useCallback, useEffect } from 'react';
import { PaymentCost } from "src/classes/plan/PaymentCost";
import { Button } from 'src/components';
import { PlanFacade } from "src/modules/quoter/controller/facades/plan.facade";
import './PlanContractingButton.scss';

/**
 *
 */
interface IPlanContractingButton {

  cost: PaymentCost;

  onClick: () => void;

  hideButton: boolean;
}

/**
 *
 */
const PlanContractingButton = (props: IPlanContractingButton): JSX.Element => {
  const buttonWrapperRef = createRef<HTMLDivElement>();
  const { isUpdating } = PlanFacade.usePlanSync();

  /**
   * 
   */
  const buttonController = useCallback(
    () => {
      if (!props.hideButton) {
        if (buttonWrapperRef && buttonWrapperRef.current) {
          const costWrapper = document.getElementById('planManagementButton');
          const offset = -40;
          if (costWrapper) {
            const top = costWrapper.getBoundingClientRect().top;
            const isVisible = (top + offset) >= 0 && (top - offset) <= window.innerHeight;
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
            const scrolled = winScroll / height;

            if (!isVisible && scrolled > 0.1) {
              buttonWrapperRef.current.className = `contractingButton contractingButton--visible`;
            } else {
              buttonWrapperRef.current.className = `contractingButton`;
            }
          }
        }
      }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonWrapperRef]
  );

  /**
   * 
   */
  useEffect(
    () => {
      window.addEventListener('scroll', buttonController)
    }, [buttonController]
  )

  /**
   * 
   */
  useEffect(
    () => {
      return () => window.removeEventListener('scroll', buttonController)
    }, [buttonController]
  )

  return (
    <div className="contractingButton" ref={buttonWrapperRef}>
      {
        !props.hideButton &&
        <>
          {
            props.cost.monthsQuantity > 0 ?
              <Button className="contractingButton__button"
                disabled={isUpdating}
                loading={isUpdating}
                onClick={() => props.onClick()}
              >
                Contratar <span className="contractingButton__button__normal">({props.cost.monthsQuantity} pagos de {props.cost.getAmountByMonth()})</span>
              </Button>
              :
              <>
                {
                  props.cost.isSubscription ?
                    <Button className="contractingButton__button"
                      disabled={isUpdating}
                      loading={isUpdating}
                      onClick={() => props.onClick()}
                    >
                      Contratar <span className="contractingButton__button__normal">(Primer pago de {props.cost.getTotal()})</span>
                    </Button>
                    :
                    <Button className="contractingButton__button contractingButton__button--large"
                      disabled={isUpdating}
                      loading={isUpdating}
                      onClick={() => props.onClick()}
                    >
                      Contratar <span className="contractingButton__button__normal">(Pago único de {props.cost.getTotal()})</span>
                    </Button>
                }
              </>
          }
        </>
      }
    </div>
  );
};

export default PlanContractingButton;