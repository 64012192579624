import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IQuoterVehicleIdDataForm } from "../interfaces/IQuoterVehicleIdDataForm";

/**
 *
 */
export namespace QuoterVehicleIdFormFacade {

  /**
   * 
   */
  const VIN_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'El VIN es obligatorio.'
    },
    {
      key: 'pattern',
      message: 'El formato del VIN es inválido.',
    },
    {
      key: 'invalidVin',
      message: 'El VIN que ingresaste ya está en uso con otra póliza.'
    }
  ];

  /**
   * 
   */
  const PLATE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'La placa es obligatoria.'
    },
    {
      key: 'pattern',
      message: 'El formato de la placa es inválido.',
    },
    {
      key: 'minLength',
      message: 'La placa debe tener al menos 5 caracteres.',
    },
    {
      key: 'maxLength',
      message: 'La placa no debe de tener más de 7 caracteres.',
    }
  ];

	/**
	 * 
	 * @param storedPerson 
	 * @returns 
	 */
	export const useQuoterVehicleIdForm = (storedVehicle?: Vehicle) => {
		
		const { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IQuoterVehicleIdDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
		
    /**
     * 
     */
    const validateDefaultData = useCallback((storedVehicle: Vehicle): void => {
      if (storedVehicle) {
				if (storedVehicle.plate && !touchedFields.plate) {
					setValue('plate', storedVehicle.plate, { shouldValidate: true, shouldTouch: true });
				}
				if (storedVehicle.vin && !touchedFields.vin) {
					setValue('vin', storedVehicle.vin, { shouldValidate: true, shouldTouch: true });
				}
      }

			const allDataCondition = storedVehicle.plate.length > 0 && storedVehicle.vin.length > 0;

			if(allDataCondition)
				trigger();
    }, [setValue, touchedFields, trigger]);

		/**
		 * 
		 */
    useEffect(() => {
      if (storedVehicle) {
        validateDefaultData(storedVehicle);
      }
    }, [storedVehicle, validateDefaultData]);

		return {
      PLATE_ERRORS,
			VIN_ERRORS,
      formData: { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
		}
  }
}