import { useEffect } from "react";
import { Controller } from 'react-hook-form';
import { Button, FormError} from 'src/components';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { Input } from 'src/components/molecules/Input/Input';
import { MapFactory } from "src/factories/map.factory";
import { QuoterLocationFacade } from 'src/modules/quoter/controller/facades/quoter-location.facade';
import { QUOTER_PATH } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterLocation.scss';

/**
 *
 */
const QuoterLocationPage = (): JSX.Element => {
	const { address, selectedMapAddress, addAddressToPerson, search, defaultCoords } = QuoterLocationFacade.useAddress();
	const { isValidatingPostalCode, verifyPostalCode, setFormValuesByAddress, storedPerson, form } = QuoterLocationFacade.usePostalCodeValidation();

	/**
	 * 
	 */
	useEffect(
		() => {
			if (address) {
				setFormValuesByAddress(address);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [address]
	)

	return (
		<div className="quoterLocation">
			<QuoterContent
				backButton={<BackButton to={`${QUOTER_PATH.QUOTER_EMAIL_PATH}${search}`} />}
				title="Para personalizar tu cotización"
				subtitle="Necesitamos saber tu código postal 🏙️"
			>

				<div className="quoterLocation__map">
					<MapFactory.Map
						label="TU DIRECCIÓN"
						defaultCoords={defaultCoords}
						onSelect={(address) => { selectedMapAddress(address) }}
					/>
				</div>

				<form className="quoterLocation__form">
					<div className="quoterLocation__form__inputWrapper">
						<Controller
							name="postalCode"
							control={form.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.NUMBERS,
								minLength: 5,
								validate: { invalidPostalCode: async (postalCode) => await verifyPostalCode(postalCode) }
							}}
							render={({ field }) => (
								<Input
									label="CÓDIGO POSTAL"
									defaultValue={storedPerson && storedPerson.profile && storedPerson.profile.address ? storedPerson.profile.address.postalCode : ''}
									disabled={!address}
									uppercase
									autoComplete="off"
									type="text"
									required
									loading={isValidatingPostalCode}
									maxLength={5}
									errors={!!form.formState.errors.postalCode}
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>
						<FormError schema={QuoterLocationFacade.POSTAL_CODE_ERRORS} type={form.formState.errors.postalCode?.type} />
					</div>
					<div className="quoterLocation__form__inputWrapper">
						<Controller
							name="neighborhood"
							control={form.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.ADDRESS
							}}
							render={({ field }) => (
								<Input
									label="COLONIA"
									defaultValue={form.getValues('neighborhood')}
									disabled={!address}
									maxLength={64}
									type="text"
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>
					</div>
					<div className="quoterLocation__form__inputWrapper">
						<Controller
							name="street"
							control={form.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.ADDRESS
							}}
							render={({ field }) => (
								<Input
									label="CALLE"
									defaultValue={form.getValues('street')}
									disabled={!address}
									maxLength={64}
									type="text"
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>
					</div>
					<div className="quoterLocation__form__inputWrapper">
						<Controller
							name="externalNumber"
							control={form.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.ADDRESS
							}}
							render={({ field }) => (
								<Input
									label="NUMERO EXTERIOR"
									defaultValue={form.getValues('externalNumber')}
									disabled={!address}
									maxLength={16}
									type="text"
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>
					</div>
					<div className="quoterLocation__form__inputWrapper">
						<Controller
							name="internalNumber"
							control={form.control}
							rules={{
								pattern: Util.PATTERN.ADDRESS
							}}
							render={({ field }) => (
								<Input
									label="NUMERO INTERIOR"
									disabled={!address}
									maxLength={40}
									type="text"
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>
					</div>
				</form>
				<Button className="quoterLocation__button"
					disabled={!form.formState.isValid || !storedPerson?.profile?.address?.postalCode || !address}
					onClick={() => addAddressToPerson(form.getValues)}
				>
					Siguiente
				</Button>
			</QuoterContent>

		</div>
	);
};

export default QuoterLocationPage;