import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AlertFactory } from "src/factories/alert.factory";
import { PROFILE_ROUTE_ID, QUOTER_PATH } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterPersonAction } from "../actions/quoter-person.action";
import { QuoterPersonFeature } from "../features/quoter-person.feature";
import { ICountry } from "../interfaces/ICountry";
import { ICountryState } from "../interfaces/ICountryState";
import { IEconomicActivity } from "../interfaces/IEconomicActivity";
import { IQuoterProfileDataForm } from "../interfaces/IQuoterProfileDataForm";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterPersonFacade } from "./quoter-person.facade";
import { QuoterFacade } from "./quoter.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
export namespace QuoterProfileFacade {

	/**
	 * 
	 */
	enum PROFILE_STEPS {
		COUNTRY = 'BIRTH_COUNTRY',
		NATIONALITY = 'NATIONALITY',
		STATE = 'STATE',
		ECONOMIC_ACTIVITIES = 'ECONOMIC_ACTIVITIES',
		ECONOMIC_OWN_BUSINESS = 'ECONOMIC_OWN_BUSINESS',
		RFC = 'RFC'
	};
	const STEPS_ORDER = [
		PROFILE_STEPS.COUNTRY,
		PROFILE_STEPS.NATIONALITY,
		PROFILE_STEPS.STATE,
		PROFILE_STEPS.ECONOMIC_ACTIVITIES,
		PROFILE_STEPS.ECONOMIC_OWN_BUSINESS,
		PROFILE_STEPS.RFC
	];
	/**
	 * 
	 */
	export const STEPS_TITLES = {
		COUNTRY: 'SELECCIONA TU PAIS DE NACIMIENTO',
		NATIONALITY: 'SELECCIONA TU NACIONALIDAD',
		STATE: 'SELECCIONA EL ESTADO EN QUE NACISTE',
		ECONOMIC_ACTIVITIES: 'SELECCIONA TU ACTIVIDAD ECONOMICA',
		ECONOMIC_OWN_BUSINESS: 'SELECCIONA TU SECTOR ECONOMICO',
		RFC: 'ESCRIBE TU RFC'
	}

	/**
	 * 
	 */
	export const useQuoterProfile = (skipSteps: number[]) => {

		const { search } = QuoterParamsFacade.useQuoterParams();
		const { fillPersonData } = QuoterPersonFacade.useFillPerson();
		const [stepNumber, setStepNumber] = useState<number>(0);
		const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
		const [isValidatingRFC, setIsValidatingRFC] = useState<boolean>(false)
		const [validatedRFC, setValidatedRFC] = useState('');
		const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);
		const { id: urlId } = useParams<string>();
		const dispatch = useDispatch();
		const navigate = useNavigate();

		/**
		 * 
		 */
		const nextPage = (rfc?: string) => {
			if (rfc) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.rfc.name,
					[TrackingFactory.EVENTS.rfc.property]: rfc.toLocaleUpperCase()
				})
			}
			navigate(`${QUOTER_PATH.QUOTER_PLAN_REVIEW}${search}`);
		}

		/**
		 * 
		 */
		const stepHandler = (_step: number, _direction: string) => {
			let _routeId: string = '';

			if ((STEPS_ORDER[_step] === PROFILE_STEPS.COUNTRY) && (urlId !== PROFILE_ROUTE_ID.COUNTRY)) {
				_routeId = PROFILE_ROUTE_ID.COUNTRY
			} else if ((STEPS_ORDER[_step] === PROFILE_STEPS.NATIONALITY) && (urlId !== PROFILE_ROUTE_ID.NATIONALITY)) {
				_routeId = PROFILE_ROUTE_ID.NATIONALITY
			} else if ((STEPS_ORDER[_step] === PROFILE_STEPS.STATE) && (urlId !== PROFILE_ROUTE_ID.STATE)) {
				_routeId = PROFILE_ROUTE_ID.STATE
			} else if ((STEPS_ORDER[_step] === PROFILE_STEPS.ECONOMIC_ACTIVITIES) && (urlId !== PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES)) {
				_routeId = PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES
			} else if ((STEPS_ORDER[_step] === PROFILE_STEPS.ECONOMIC_OWN_BUSINESS) && (urlId !== PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS)) {
				_routeId = PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS
			} else if ((STEPS_ORDER[_step] === PROFILE_STEPS.RFC) && (urlId !== PROFILE_ROUTE_ID.RFC)) {
				_routeId = PROFILE_ROUTE_ID.RFC
			}



			navigate(`${QUOTER_PATH.QUOTER_PROFILE_PATH}/${_routeId}${search}`);
		}

		/**
		 * 
		 * @returns 
		 */
		const onBackRoute = (): string => {
			let _route = '';
			const countryStateStep = STEPS_ORDER.indexOf(PROFILE_STEPS.STATE);
			const isCountryStateSkipped = skipSteps.indexOf(countryStateStep) >= 0;
			const ownBusinessStateStep = STEPS_ORDER.indexOf(PROFILE_STEPS.ECONOMIC_OWN_BUSINESS);
			const isOwnBusinessStateSkipped = skipSteps.indexOf(ownBusinessStateStep) >= 0;

			if (urlId === PROFILE_ROUTE_ID.COUNTRY)
				_route = `${QUOTER_PATH.QUOTER_PERSON_TYPE_PATH}${search}`
			else if (urlId === PROFILE_ROUTE_ID.NATIONALITY)
				_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.COUNTRY}${search}`
			else if (urlId === PROFILE_ROUTE_ID.STATE)
				_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.NATIONALITY}${search}`
			else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES) {
				if (isCountryStateSkipped) {
					_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.NATIONALITY}${search}`
				} else {
					_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.STATE}${search}`
				}
			} else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS)
				_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES}${search}`
			else if (urlId === PROFILE_ROUTE_ID.RFC) {
				if (isOwnBusinessStateSkipped) {
					_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES}${search}`
				} else {
					_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS}${search}`
				}
			}

			return _route
		};

		/**
		 * 
		 */
		const setRFC = (data: IQuoterProfileDataForm) => {
			let _person = fillPersonData();

			if (_person.profile)
				_person.profile.rfc = data.rfc

			dispatch(QuoterPersonAction.setStoredPerson(_person))
		}

		/**
		 * 
		 * @param rfc 
		 * @returns 
		 */
		const validatePersonRfc = async (rfc: string): Promise<boolean | undefined> => {
			try {
				if (Util.PATTERN.RFC.test(rfc.toUpperCase())) {
					if (validatedRFC !== rfc) {
						setIsValidatingRFC(true);
						setValidatedRFC(rfc);
						const validation = await QuoterPersonFeature.validateRfcFeature(rfc.toUpperCase());
						setPreviousValidation(validation)
						return validation;
					} else {
						return previousValidation as boolean;
					}

				}
			} catch (e) {

			} finally {
				setIsValidatingRFC(false);
			}
		}

		/**
		 * 
		 */
		useEffect(() => {
			let step;

			if (urlId === PROFILE_ROUTE_ID.COUNTRY && PROFILE_STEPS.COUNTRY !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.COUNTRY)
			else if (urlId === PROFILE_ROUTE_ID.NATIONALITY && PROFILE_STEPS.NATIONALITY !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.NATIONALITY)
			else if (urlId === PROFILE_ROUTE_ID.STATE && PROFILE_STEPS.STATE !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.STATE)
			else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES && PROFILE_STEPS.ECONOMIC_ACTIVITIES !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.ECONOMIC_ACTIVITIES)
			else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS && PROFILE_STEPS.ECONOMIC_OWN_BUSINESS !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.ECONOMIC_OWN_BUSINESS)
			else if (urlId === PROFILE_ROUTE_ID.RFC && PROFILE_STEPS.RFC !== STEPS_ORDER[stepNumber])
				step = STEPS_ORDER.indexOf(PROFILE_STEPS.RFC)

			if (step !== undefined)
				setStepNumber(step);
		}, [urlId, stepNumber, setStepNumber]);

		return {
			urlId,
			stepNumber,
			isNextDisabled,
			isValidatingRFC,
			setIsNextDisabled,
			validatePersonRfc,
			nextPage,
			onBackRoute,
			stepHandler,
			setRFC
		}
	}

	/**
	 * 
	 */
	export const useQuoterProfileEconomicActivities = () => {

		/**
		 * 
		 */
		const { storedPerson } = QuoterFacade.useQuoterActors();
		const { fillPersonData } = QuoterPersonFacade.useFillPerson();
		const [economicActivities, setEconomicActivities] = useState<IEconomicActivity[]>([]);
		const [economicOwnBusiness, setEconomicOwnBusiness] = useState<IEconomicActivity[]>([]);
		const [chosenEconomicActivity, setChosenEconomicActivity] = useState<IEconomicActivity>();
		const [chosenOwnBusiness, setChosenOwnBusiness] = useState<IEconomicActivity>();
		const [countries, setCountries] = useState<ICountry[]>([]);
		const [chosenCountry, setChosenCountry] = useState<ICountry>();
		const [chosenNationality, setChosenNationality] = useState<ICountry>();
		const [countryStates, setCountryStates] = useState<ICountryState[]>([]);
		const [chosenCountryState, setChosenCountryState] = useState<ICountryState>();
		const [skipSteps, setSkipSteps] = useState<number[]>([]);
		const { id: urlId } = useParams<string>();
		const dispatch = useDispatch();

		/**
		 * 
		 * @param id 
		 */
		const onSelectData = (id: number | undefined): void => {
			let person = fillPersonData();

			if (!person.profile)
				return

			if (id === undefined) {
				if (urlId === PROFILE_ROUTE_ID.COUNTRY) {
					setChosenCountry(undefined);
					person.profile.originCountry = -1;
				} else if (urlId === PROFILE_ROUTE_ID.NATIONALITY) {
					setChosenNationality(undefined);
					person.profile.nationalityCode = -1;
				} else if (urlId === PROFILE_ROUTE_ID.STATE) {
					setChosenCountryState(undefined);
					person.profile.originCountryState = -1;
				} else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES) {
					setChosenEconomicActivity(undefined);
					person.profile.economicActivity = -1;
				} else if (urlId === PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS) {
					setChosenOwnBusiness(undefined);
					person.profile.economicActivity = -1;
				}

				dispatch(QuoterPersonAction.setStoredPerson(person));
				return
			}

			if (urlId === PROFILE_ROUTE_ID.COUNTRY || urlId === PROFILE_ROUTE_ID.NATIONALITY) {
				for (const c of countries) {
					if (c.id === id) {
						if (urlId === PROFILE_ROUTE_ID.COUNTRY) {

							setChosenCountry(c);
							person.profile.originCountry = c.id;

						} else if (urlId === PROFILE_ROUTE_ID.NATIONALITY) {
							if (!id) {
								setChosenNationality(undefined);
								break;
							}

							setChosenNationality(c);

							person.profile.nationalityCode = c.id;
						}

						break;
					}
				}
			}

			if (urlId === PROFILE_ROUTE_ID.STATE && countryStates) {
				for (const c of countryStates) {
					if (c.id === id) {
						setChosenCountryState(c);
						person.profile.originCountryState = c.id;
						break;
					}
				}
			}

			if (urlId === PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES && economicActivities) {
				for (const a of economicActivities) {
					if (a.id === id) {
						setChosenEconomicActivity(a);
						person.profile.economicActivity = a.id;
						break
					}
				}
			}

			if (urlId === PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS && economicOwnBusiness) {
				for (const o of economicOwnBusiness) {
					if (o.id === id) {
						setChosenOwnBusiness(o);
						person.profile.economicActivity = o.id;
						break
					}
				}
			}

			dispatch(QuoterPersonAction.setStoredPerson(person));
		};
		/**
		 * 
		 * @param step 
		 */
		const tracking = (step: number): void => {
			if (chosenCountry && STEPS_ORDER[step - 1] === PROFILE_STEPS.COUNTRY) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.country.name,
					[TrackingFactory.EVENTS.country.property]: chosenCountry.name
				});
			} else if (chosenCountryState && STEPS_ORDER[step - 1] === PROFILE_STEPS.STATE) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.countryState.name,
					[TrackingFactory.EVENTS.countryState.property]: chosenCountryState.name
				});
			} else if (chosenNationality && STEPS_ORDER[step - 1] === PROFILE_STEPS.NATIONALITY) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.nationality.name,
					[TrackingFactory.EVENTS.nationality.property]: chosenNationality.name
				});
			} else if (chosenEconomicActivity && STEPS_ORDER[step - 1] === PROFILE_STEPS.ECONOMIC_ACTIVITIES) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.economicActivity.name,
					[TrackingFactory.EVENTS.economicActivity.property]: chosenEconomicActivity.value
				});
			}
		}

		/** Use effect for retrieving the economic activities data. */
		useEffect(() => {
			const fetchEconomicActivities = async () => {
				const economicActivitiesData = await QuoterPersonFeature.getEconomicActivitiesFeature();
				const economicOwnBusinessData = await QuoterPersonFeature.getOwnBusinessActivitiesFeature();

				setEconomicActivities(economicActivitiesData);
				setEconomicOwnBusiness(economicOwnBusinessData);

				if (storedPerson && storedPerson.profile && storedPerson.profile.economicActivity >= 0) {
					let foundAnActivity = false;
					for (const e of economicActivitiesData) {
						if (e.id === storedPerson.profile.economicActivity) {
							foundAnActivity = true;
							setChosenEconomicActivity(e);
							break;
						}
					}

					if (!foundAnActivity && storedPerson.profile.economicActivity >= 0) {
						setChosenEconomicActivity(economicActivitiesData[0]);
					}
				}
			};

			fetchEconomicActivities()
				.catch(e => {
					console.error(e);
				});

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		/**  */
		useEffect(() => {
			const fetchCountries = async () => {
				const _countries = await QuoterPersonFeature.getCountriesFeature();
				setCountries(_countries);

				if (storedPerson && storedPerson.profile) {
					//If there is a originCountry found on storedPerson, then search for it on countries and set it.
					if (storedPerson.profile.originCountry >= 0 && !chosenCountry) {
						for (const c of _countries) {
							if (c.id === storedPerson.profile.originCountry) {
								setChosenCountry(c);
								break;
							}
						}
					}

					//If there is a nationalityCode found on storedPerson, then search for it on countries and set it.
					if (storedPerson.profile.nationalityCode >= 0 && !chosenNationality) {
						for (const c of _countries) {
							if (c.id === storedPerson.profile.nationalityCode) {
								setChosenNationality(c);
								break;
							}
						}
					}
				}


			}

			if (countries.length === 0)
				fetchCountries()
					.catch(e => {
						console.error(e);
						AlertFactory.errorAlert('Ha ocurrido un error');
					})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		/** */
		useEffect(
			() => {
				if (chosenCountry) {
					const fetchCountryStates = async () => {
						const countryStateStep = STEPS_ORDER.indexOf(PROFILE_STEPS.STATE);
						const states = await QuoterPersonFeature.getCountryStatesFeature(chosenCountry.id);
						setCountryStates(states);
						let person = fillPersonData();
						if (!person.profile)
							return

						//If countryState found on storedPerson and not found on chosenCountryState
						if (chosenCountry.id === Util.CONSTANT.MEXICO_ID && storedPerson && storedPerson.profile && storedPerson.profile.originCountryState && !chosenCountryState) {
							for (const state of states) {
								if (state.id === storedPerson.profile.originCountryState) {
									setChosenCountryState(state);
									return;
								}
							}
						} else if (chosenCountry.id !== Util.CONSTANT.MEXICO_ID) {
							for (const state of states) {
								if (state.name === Util.STATUS_CODE.CITIZENSHIP.FOREIGNER) {
									setChosenCountryState(state);
									person.profile.originCountryState = state.id
									break;
								}
							}

							//If chosenNationality is different from Mexico, then add countryStateStep to the skipSteps array.
							setSkipSteps((currentSteps) => {
								if (currentSteps && currentSteps.indexOf(countryStateStep) < 0)
									currentSteps.push(countryStateStep);
								return currentSteps;
							})
						} else if (skipSteps.indexOf(countryStateStep) >= 0) {
							//If countryStateStep is on skipStep array and selected country is Mexico, then remove it.
							setChosenCountryState(undefined);
							person.profile.originCountryState = -1;
							let _stepIndex = skipSteps.indexOf(countryStateStep);
							setSkipSteps((currentSteps) => {
								currentSteps.splice(_stepIndex, 1);
								return currentSteps;
							})
						}

						dispatch(QuoterPersonAction.setStoredPerson(person));
					}

					fetchCountryStates()
						.catch(e => {
							console.error(e);
							AlertFactory.errorAlert('Ha ocurrido un error');
						})
				}
			}, [chosenCountry, skipSteps, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
		);

		/** Use effect for validating if economic activities has a valid value. */
		useEffect(() => {
			const _ownBusinessID: number = 0;

			if (chosenEconomicActivity !== undefined) {
				const ownBusinessStep = STEPS_ORDER.indexOf(PROFILE_STEPS.ECONOMIC_OWN_BUSINESS);

				if (chosenEconomicActivity.id === _ownBusinessID && storedPerson && storedPerson.profile && storedPerson.profile.economicActivity > 0) {
					for (const e of economicOwnBusiness) {
						if (e.id === storedPerson.profile.economicActivity) {
							setChosenOwnBusiness(e);
							break;
						}
					}
				} else if (chosenEconomicActivity.id === _ownBusinessID) {
					setChosenOwnBusiness(undefined);
					//If chosenEconomicActitivy is equal to _ownBusinessID and ownBusinessStep is on skipSteps array, then remove it.
					if (skipSteps.indexOf(ownBusinessStep) >= 0) {
						setSkipSteps((currentSteps) => {
							let ownBusinessIndex = currentSteps.indexOf(ownBusinessStep);
							currentSteps.splice(ownBusinessIndex, 1);
							return currentSteps;
						})
					}
				} else {
					//If chosenEconomicActivity is different from _ownBusinessID, then add ownBusinessStep to the skipSteps array.
					setSkipSteps((currentSteps) => {
						if (currentSteps && currentSteps.indexOf(ownBusinessStep) < 0)
							currentSteps.push(ownBusinessStep);
						return currentSteps;
					})
				}
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [chosenEconomicActivity, economicOwnBusiness, setChosenOwnBusiness, setSkipSteps]);


		return {
			economicActivities,
			economicOwnBusiness,
			chosenEconomicActivity,
			chosenOwnBusiness,
			countries,
			chosenCountry,
			chosenNationality,
			countryStates,
			chosenCountryState,
			skipSteps,
			onSelectData,
			tracking
		}
	}
}