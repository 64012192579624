/**
 * 
 */
export class AuthToken {

  /**
   * 
   */
  private _access: string = '';
  get access(): string { return this._access; }
  set access(_access: string) { this._access = _access; }

  /**
   * 
   */
  private _refresh: string = '';
  get refresh(): string { return this._refresh; }
  set refresh(_refresh: string) { this._refresh = _refresh; }


  constructor(_accessToken: string, _refreshToken: string) {
    this._access = _accessToken;
    this._refresh = _refreshToken;
  }
}