import { Text } from "src/components";
import { IconFactory } from "src/factories/icon.factory";
import { PlanCouponFacade } from "src/modules/quoter/controller/facades/plan-coupon.facade";
import { Util } from "src/utils/Util";
import './PlanCoupon.scss';

/**
 *
 */
interface IPlanCoupon {

}

/**
 *
 */
const PlanCoupon = (props: IPlanCoupon): JSX.Element => {
  const { register, formState, COUPON_ERRORS, currentCoupon, onApplyCoupon } = PlanCouponFacade.usePlanCouponForm();
  return (
    <form className="planCoupon" autoComplete="off"
      disabled-form={currentCoupon ? '' : null}
      onSubmit={(e) => { e.preventDefault(); onApplyCoupon() }}
    >
      {IconFactory.couponIcon()}
      <div className="planCoupon__field">
        <Text type="label" block className="planCoupon__field__label">
          ¿Tienes un cupón de descuento?
        </Text>
        <div className="planCoupon__field__input flex">
          <input {...register('coupon', { pattern: Util.PATTERN.PLAN_COUPON })} placeholder="Ingresa el código" maxLength={20} />
          {
            !currentCoupon ?
              <button type="submit" className="planCoupon__field__input__button">Aplicar</button>
              :
              <button type="submit" className="planCoupon__field__input__button planCoupon__field__input__button--remove">Eliminar</button>
          }
        </div>
      </div>
      <div className="planCoupon__error">
        {
          formState.errors.coupon?.type === 'pattern' &&
          <div className="planCoupon__error__wrapper">
            {IconFactory.warningIcon()}
            <Text type="label" block color="red">{COUPON_ERRORS[0].message}</Text>
          </div>
        }
      </div>
    </form>
  );
};

export default PlanCoupon;