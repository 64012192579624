import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { QUOTER_PATH } from "src/routes/paths/quoter.paths";
import { PlanSaga } from "../sagas/plan.saga";
import { QuoterPersonSaga } from "../sagas/quoter-person.saga";
import { QuoterVehicleSaga } from "../sagas/quoter-vehicle.saga";
import { QuoterSaga } from "../sagas/quoter.saga";
import { PlanSelector } from "../selectors/plan.selector";
import { QuoterPersonSelector } from "../selectors/quoter-person.selector";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { PlanSlice } from "../slices/plan.slice";
import { QuoterPersonSlice } from "../slices/quoter-person.slice";
import { QuoterVehicleSlice } from "../slices/quoter-vehicle.slice";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterSlice } from "../slices/quoter.slice";
import { QuoterSelector } from "../selectors/quoter.selector";
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { QuoterFeature } from "../features/quoter.feature";
import { AlertFactory } from "src/factories/alert.factory";
import { QuoterAction } from "../actions/quoter.action";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 * 
 */
const QUOTER_PATHS = [
	QUOTER_PATH.QUOTER_VEHICLE_PATH,
	QUOTER_PATH.QUOTER_PERSON_PATH,
	QUOTER_PATH.QUOTER_EMAIL_PATH,
	QUOTER_PATH.QUOTER_LOCATION_PATH,
	QUOTER_PATH.QUOTER_PLAN_PATH,
	QUOTER_PATH.QUOTER_VEHICLE_ID_PATH,
	QUOTER_PATH.QUOTER_PERSON_TYPE_PATH,
	QUOTER_PATH.QUOTER_PROFILE_PATH,
	QUOTER_PATH.QUOTER_PLAN_REVIEW,
	QUOTER_PATH.QUOTER_PERSON_ACCOUNT_PATH,
	QUOTER_PATH.QUOTER_PAYMENT_PATH,
	QUOTER_PATH.QUOTER_COMPLETION_PATH
];

/**
 *
 */
export namespace QuoterFacade {

	/**
	 * 
	 */
	export const useQuoterActors = () => {
		const storedPerson = useSelector(QuoterPersonSelector.storedPerson);
		const storedVehicle = useSelector(QuoterVehicleSelector.storedVehicle);
		const storedPlan = useSelector(PlanSelector.currentPlan);
		const storedPolicy = useSelector(QuoterSelector.policy);

		return { storedPerson, storedVehicle, storedPlan, storedPolicy };
	}

	/**
	 * 
	 */
	export const useQuoterFacade = () => {
		useInjectSaga({ key: 'quoter', saga: QuoterSaga.quoterSaga });
		useInjectReducer({ key: 'quoter', reducer: QuoterSlice.reducer });

		useInjectSaga({ key: 'quoterVehicle', saga: QuoterVehicleSaga.vehicleDataSaga });
		useInjectReducer({ key: 'quoterVehicle', reducer: QuoterVehicleSlice.reducer });

		useInjectSaga({ key: 'quoterPerson', saga: QuoterPersonSaga.personDataSaga });
		useInjectReducer({ key: 'quoterPerson', reducer: QuoterPersonSlice.reducer });

		useInjectReducer({ key: 'plan', reducer: PlanSlice.reducer });
		useInjectSaga({ key: 'plan', saga: PlanSaga.planManagementSaga });

		QuoterParamsFacade.useInitParams();
		const { storedPerson } = useQuoterActors();
		const { search } = QuoterParamsFacade.useQuoterParams();
		const [currentStep, setCurrentStep] = useState<number>();
		const location = useLocation();
		const { pathname } = location;
		const navigate = useNavigate();
		const dispatch = useDispatch();

		/**
		 * 
		 */
		const sessionLogin = useCallback(
			async () => {
				try {
					const deviceId = uuidv4();
					const { os, browser } = new UAParser().getResult();
					if (os.name && os.version && browser.name && browser.version) {
						await QuoterFeature.temporalLoginFeature(os.name, os.version, browser.name, browser.version, deviceId);
						dispatch(QuoterAction.temporalAuth(true));
					}
				} catch (e) {
					AlertFactory.errorAlert((e as Error).message);
				}
			}, [dispatch]
		);

		/**
		 * 
		 */
		useEffect(
			() => {
				sessionLogin();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []
		)

		/**
		 * 
		 */
		useEffect(() => {
			const afterPlanRoute = pathname.includes(QUOTER_PATH.QUOTER_PERSON_TYPE_PATH)
				|| pathname.includes(QUOTER_PATH.QUOTER_VEHICLE_ID_PATH)
				|| pathname.includes(QUOTER_PATH.QUOTER_PERSON_GENDER_PATH)
				|| pathname.includes(QUOTER_PATH.QUOTER_PROFILE_PATH)
				|| pathname.includes(QUOTER_PATH.QUOTER_PLAN_REVIEW)
				|| pathname.includes(QUOTER_PATH.QUOTER_PERSON_ACCOUNT_PATH)
				|| pathname.includes(QUOTER_PATH.QUOTER_PAYMENT_PATH);

			if (afterPlanRoute && !storedPerson) {
				navigate(`${QUOTER_PATH.QUOTER_PLAN_PATH}${search}`);
			}

			if (afterPlanRoute)
				window.onbeforeunload = (e) => { return true }
			else
				window.onbeforeunload = null

			return () => {
				window.onbeforeunload = null
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pathname])

		/**
		 * 
		 */
		useEffect(
			() => {
				if (!pathname.includes(QUOTER_PATH.QUOTER_COMPLETION_PATH)) {
					window.onunload = () => {
						if (storedPerson?.profile?.phone) {
							TrackingFactory.Tracker.track({
								event: TrackingFactory.EVENTS.flowUnload.name,
								[TrackingFactory.EVENTS.flowUnload.property]: storedPerson.profile.getPhoneNumber(),
							})
						}
						return true;
					}
				} else {
					window.onunload = null;
				}
			}, 
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[pathname, storedPerson]
		)

		/**
		 * 
		 */
		useEffect(() => {
			if (!pathname)
				return

			for (const path in QUOTER_PATHS) {
				if (pathname.includes(QUOTER_PATHS[path])) {
					setCurrentStep(parseInt(path));
				}
			}
		}, [pathname])

		return { currentStep, totalPaths: QUOTER_PATHS.length, pathname }
	}
}