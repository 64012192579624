import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factories/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IQuotationData } from "../interfaces/IQuotationData";
import { IQuotationRequest } from "../interfaces/IQuotationRequest";

/**
 *
 */
export namespace PlanService {

  
  /**
   * 
   * @param data 
   * @returns 
   */
  export const quotationService = (data: IQuotationRequest): AxiosPromise<IQuotationData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.QUOTATION, data);
  };
}