import { useCallback, useEffect, useState } from "react"
import { Loader } from "@googlemaps/js-api-loader";
import { Suggestion } from "src/components";
import { DebounceFactory } from "./debounce.factory";
import { Address } from "src/classes/person/Address";
import { EnvironmentFactory } from './EnvironmentFactory/environment.factory';


export namespace MapFactory {

  /**
   * 
   */
  interface IMap {

    label: string;

    onSelect?: (address?: Address) => void;

    defaultCoords?: { lat: number, lng: number };
  }

  /**
   * 
   */
  // @ts-ignore google.maps.plugins
  const MAP_LOADER = new Loader({
    apiKey: EnvironmentFactory.ENV.REACT_APP_GOOGLE_MAPS,
    version: "weekly",
    libraries: ['places']
  });

  /**
   * 
   */
  //const DEFAULT_COORDS = { lat: 19.42847, lng: -99.12766 };

  /**
   * 
   */
  const useMap = () => {
    const [mapPromise, setMapPromise] = useState<Promise<typeof google>>();
    const [selectedAddress, setSelectedAddress] = useState<Address>();
    // const [currentMap, setCurrentMap] = useState<google.maps.Map>();
    // const [currentMarker, setCurrentMarker] = useState<google.maps.Marker>();
    const [currentPredictionName, setCurrentPredictionName] = useState<string>();
    const [currentPredictions, setCurrentPredictions] = useState<google.maps.places.QueryAutocompletePrediction[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    const debouncedSearchText = DebounceFactory.useDebounce<string>(searchText, 500);

    /**
     * 
     * @param text 
     */
    const onSearchTextChange = (text: string): void => {
      if (text === '') {
        setCurrentPredictions([]);

        if (selectedAddress) {
          setSelectedAddress(undefined);
        }
      }

      if (currentPredictionName) {
        setCurrentPredictionName(undefined);
      }

      setSearchText(text);
    }

    /**
     * 
     */
    const getPredictions = useCallback(
      async (text: string) => {
        try {
          const predictionsPromise = new Promise<google.maps.places.QueryAutocompletePrediction[] | null>(
            (resolve, reject) => {
              let displaySuggestions = function (predictions: google.maps.places.QueryAutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) {
                try {
                  if (predictions !== null) {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                      reject(status);
                    }
                    else {
                      resolve(predictions);
                    }
                  }
                } catch (e) {
                  console.error('Error ', e);
                  console.error('Input text', text);
                }
              };
              var service = new google.maps.places.AutocompleteService();
              service.getQueryPredictions({ input: text }, displaySuggestions);
            }
          );
          const preditions = await predictionsPromise;
          if (preditions) {
            setCurrentPredictions(preditions);
          }
        } catch (e) {
          console.error(e);
        }
      }, []
    );

    /**
     * 
     * @param prediction 
     */
    const reverseGeocode = (prediction: google.maps.places.QueryAutocompletePrediction): void => {
      const geocoder = new google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({ address: prediction.description }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results && results[0] !== null) {
              forwardGeocode(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            }
          }
        })
      }
    }

    /**
     * 
     * @param _lat 
     * @param _lng 
     * @param centerMap 
     */
    const forwardGeocode = useCallback(
      (lat: number, lng: number) => {
				if (mapPromise) {
					mapPromise.then(
						(google) => {
							const request: google.maps.GeocoderRequest = { location: new google.maps.LatLng(lat, lng) };
							const geocoder = new google.maps.Geocoder();
							if (geocoder) {
								geocoder.geocode(request, (results, status) => {
									if (status === google.maps.GeocoderStatus.OK) {
										if (results && results[0] !== null) {
											setCurrentPredictionName(results[0].formatted_address);
											const address = setFromGoogleMaps(results[0]);
											setSelectedAddress(address);
											// if (centerMap && currentMarker && currentMap) {
											//   currentMarker.setPosition(new google.maps.LatLng(results[0].geometry.location));
											//   currentMap.setCenter(results[0].geometry.location);
											//   currentMap.panTo(results[0].geometry.location);
											//   currentMap.setZoom(15);
											// }
										}
									}
								})
							}
						}
					)
				}
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mapPromise]
    );

    /**
     * 
     * @param result 
     * @returns 
     */
    const setFromGoogleMaps = (result: google.maps.GeocoderResult): Address | undefined => {
      let street: string = '';
      let externalNumber: string = '';
      let neighborhood: string = '';
      let postalCode: string = '';

      for (let item of result.address_components) {
        if (item.types.indexOf('political') > -1 && item.types.indexOf('sublocality') > -1 && item.types.indexOf('sublocality_level_1') > -1) {
          neighborhood = item.long_name;
        }

        if (item.types.indexOf('street_number') > -1) {
          externalNumber = item.long_name;
        }

        if (item.types.indexOf('route') > -1) {
          street = item.long_name;
        }

        if (item.types.indexOf('postal_code') > -1) {
          postalCode = item.long_name;
        }
      }


      if (street !== '' || neighborhood !== '' || externalNumber !== '' || postalCode !== '') {
        let newAddress: Address = new Address(postalCode);
        newAddress.neighborhood = neighborhood;
        newAddress.street = street;
        newAddress.externalReference = externalNumber;
        newAddress.latitude = result.geometry.location.lat();
        newAddress.longitude = result.geometry.location.lng();

        return newAddress;
      }
    }

    /**
     * 
     */
    useEffect(
      () => {
        if (debouncedSearchText !== '') {
          getPredictions(debouncedSearchText);
        }
      }, [debouncedSearchText, getPredictions]
    )

    /**
     * 
     */
    useEffect(
      () => {
        setMapPromise(new Promise<typeof google>(
          (resolve) => {
            MAP_LOADER.load().then(
              (google) => {
                /*
                // SET MAP
                const mapProperties = {
                  center: new google.maps.LatLng(DEFAULT_COORDS),
                  zoom: 8,
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                  disableDefaultUI: true
                };
                const map = new google.maps.Map(document.getElementById("map") as HTMLElement, mapProperties);
                setCurrentMap(map);
  
                // SET MARKER
                const marker = new google.maps.Marker({
                  map: map,
                  draggable: true
                })
                marker.setMap(map);
                marker.setPosition(DEFAULT_COORDS);
                setCurrentMarker(marker);
                */
                resolve(google);
              }
            )
          }
        ))

      }, []
    );

    // useEffect(
    //   () => {
    //     if (currentMarker) {
    //       currentMarker.addListener('dragend',
    //         (data: google.maps.MapMouseEvent) => {
    //           if (data && data.latLng) {
    //             forwardGeocode(data.latLng.lat(), data.latLng.lng(), true);
    //           }
    //         }
    //       );
    //     }
    //   }, [currentMarker]
    // )

    return { onSearchTextChange, reverseGeocode, currentPredictions, currentPredictionName, selectedAddress, forwardGeocode }
  }

  /**
   * 
   */
  export const Map = (props: IMap): JSX.Element => {
    const { onSearchTextChange, reverseGeocode, currentPredictions, currentPredictionName, selectedAddress, forwardGeocode } = useMap();

    useEffect(
      () => {
        if (props.defaultCoords) {
          forwardGeocode(props.defaultCoords.lat, props.defaultCoords.lng);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.defaultCoords]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (props.onSelect) {
          props.onSelect(selectedAddress);
        }
			// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedAddress]
    )

    return (

      <div className="mapWrapper">

        {/* <div id="map"
          style={{
            width: 'stretch',
            minHeight: '200px',
            marginTop: '10px',
            marginBottom: '30px',
            borderRadius: '15px'
          }}
        >

        </div> */}
        <Suggestion
          label={props.label}
          inputValue={currentPredictionName}
          onTextChange={(text) => onSearchTextChange(text)}
          suggestions={currentPredictions.map(p => p.description)}
          onSuggestionSelect={(index) => reverseGeocode(currentPredictions[index])}
        />
      </div>

    )
  }
}