import { Util } from 'src/utils/Util';
import './QuoterHeader.scss';
import { Logo } from "src/components";
import { Text } from "src/components"
import { TrackingFactory } from "src/factories/tracking.factory";
/**
 *
 */
interface IQuoterHeader {

  currentStep?: number;

  totalSteps?: number;

}

/**
 *
 */
const QuoterHeader = (props: IQuoterHeader): JSX.Element => {
  
  /**
   * 
   */
  const onClickHelpButton = (): void => {
    TrackingFactory.Tracker.track({
      event: TrackingFactory.EVENTS.helpButton.name,
      [TrackingFactory.EVENTS.helpButton.property]: `${window.location.origin}${window.location.pathname}`
    })
  }

  return (
    <header className="quoterHeader">

      <div className="quoterHeader__content">
        <div className="quoterHeader__content__logo">
          <Logo />
        </div>

        <button className="quoterHeader__content__helpButton" onClick={() => onClickHelpButton()}>
						<a className="quoterHeader__content__helpButton__link" href={Util.LINKS.WHATSAPP_SUPPORT} target="_blank" rel="noreferrer"  >
							<div className="quoterHeader__content__helpButton__link__text">
                <Text type="label" bold color='main'>¿Necesitas ayuda?</Text>
                <div className="quoterHeader__content__helpButton__link__text--small">Escríbeme ahora</div>          
              </div>
              <div>🙋‍♀️</div>
						</a>
					</button>

        {
          (props.totalSteps !== undefined && props.currentStep !== undefined) &&
          <div className="quoterHeader__content__progress">
            <div className="quoterHeader__content__progress__bar" style={{
              width: `${((props.currentStep + 1) * 100) / (props.totalSteps)}%`
            }}
            ></div>
          </div>
        }
      </div>

      <div className="quoterHeader__helper">
        <img alt="Helper" src={Util.ASSET.HELPER} />
      </div>
    </header>
  );
};

export default QuoterHeader;