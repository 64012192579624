import { useEffect, useState, useId } from 'react';
import { Text } from 'src/components/atoms';

import './RadioGroup.scss';

/**
 *
 */
interface IRadioGroup {

	/**
	 * 
	 */
	items: { id: number, value: string }[];
	/**
	 * 
	 */
	defaultValue?: number;
	/**
	 * 
	 */
	label?: boolean;
  /**
   * 
   */
  onSelectItem: (item: { id: number, value: string }) => void;
}

/**
 *
 */
export const RadioGroup = (props: IRadioGroup):JSX.Element => {

	/**
	 * 
	 */
	const { items, defaultValue, label, onSelectItem } = props;
	const [itemSelected, setItemSelected] = useState<{ id: number, value: string }>();
	const radioId = useId();

	/**
	 * 
	 */
	useEffect(() => {
		if(itemSelected) {
			onSelectItem(itemSelected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemSelected]);

	/**
	 * 
	 */
	useEffect(() => {
		if (defaultValue !== undefined) {
			for (const i of items)
				if (i.id === defaultValue) {
					setItemSelected(i);
					break;
				}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	return (
		<div className="radioWrapper">
			{
				items.map((item, index) => {
					return (
						<div className="radioWrapper__radioItem" key={`${radioId}-${index}`} onClick={(e) => {e.preventDefault(); setItemSelected(item)}}>
							<div className={`radioWrapper__radioItem__radio ${ itemSelected && itemSelected.id === item.id && 'radioWrapper__radioItem__radio--active' }`}></div>
							
							<Text type={ label ? "label" : "paragraph"} className="radioWrapper__radioItem__text" align="center" bold>{item.value}</Text>
						</div>
					)
				})
			}
		</div>
	);
};