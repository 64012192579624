import classNames from 'classnames';
import { Spinner, Text } from "src/components";
import { createRef, useCallback, useId } from 'react';
import { useEffect, useState } from 'react';
import { IconFactory } from 'src/factories/icon.factory';
import { Input } from '../Input/Input';
import { Util } from 'src/utils/Util';

import './Dropdown.scss';

type ItemType = {
	id: number;

	value: string;

	info?: string;
}

/**
 *
 */
interface IDropdown {

	items: ItemType[];

	onChange: (item?: ItemType) => void;

	placeholder?: string;

	required?: boolean;

	defaultValue?: ItemType;

	isLoading?: boolean;

	notFoundText?: string;

	hasDeleteButton?: boolean;

	number?: boolean;

	dropdownPlace?: 'top' | 'bottom';

	allowEmpty?: boolean;

	disabled?: boolean;

	/**
	 * 
	 */
	cancelSearch?: boolean;
}

/**
 *
 */
export const Dropdown = (props: IDropdown): JSX.Element => {

	const {
		items,
		placeholder,
		onChange,
		number,
		dropdownPlace = 'bottom',
		notFoundText,
		hasDeleteButton,
		cancelSearch
	} = props;

	const [isDefaultValueAvailable, setIsDefaultValueAvailable] = useState(true);
	const [isDropdownTouched, setIsDropdownTouched] = useState(false);
	const [isInputSelected, setIsInputSelected] = useState(false);
	const [isFilled, setIsFilled] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const [searchNotFound, setSearchNotFound] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [itemSelected, setItemSelected] = useState<ItemType>({ id: -1, value: '' });
	const uid = useId();

	/**
	 * 
	 */
	const dropdownClasses = classNames({
		'dropdownWrapper--disabled': (props.items.length === 0 && !props.allowEmpty) || props.disabled,
		'dropdownWrapper--no-search': props.cancelSearch
	});
	const arrowIconClasses = classNames({
		'dropdownWrapper__arrowIcon--rotated': isInputSelected
	});
	const itemListClasses = classNames({
		'dropdownWrapper__itemsList--bottom': dropdownPlace === 'bottom',
		'dropdownWrapper__itemsList--top': dropdownPlace === 'top'
	});

	/**
	 * 
	 * @param item 
	 */
	const selectItem = useCallback((item: ItemType): void => {
		setItemSelected(item);
		setInputValue(item.value);
		setIsMouseOver(false);
		if (props.onChange) {
			props.onChange(item);
		}
	}, [props])

	/**
	 * 
	 * @param item 
	 */
	const handleItemClick = (item: ItemType): void => {
		setIsDropdownTouched(true);
		selectItem(item);
		setIsInputSelected(false);
	}

	/**
	 * 
	 */
	const setDefaultSelectedValue = useCallback(
		() => {
			if (props.defaultValue && isDefaultValueAvailable) {
				setIsDefaultValueAvailable(false);
				selectItem(props.defaultValue);
				setIsDropdownTouched(true);
			}
		},
		[props.defaultValue, isDefaultValueAvailable, selectItem],
	);

	/**
	 * 
	 */
	const deteleSelection = () => {
		setItemSelected({ id: -1, value: '' });
		setInputValue('');
	};

	/**
	 * 
	 */
	useEffect(
		() => {
			if (props.items.length === 0 && isFilled && !props.allowEmpty) {
				setItemSelected({ id: -1, value: '' });
				setInputValue('');
			}

			if (props.items.length > 0 && !isFilled && isDropdownTouched) {
				setIsFilled(true);
			}

		}, [props.items, isDropdownTouched, isFilled, props.allowEmpty]
	)

	/**
	 * 
	 */
	const dropdownRef = createRef<HTMLDivElement>();
	useEffect(() => {
		const closeDropdown = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsInputSelected(false);

				if (itemSelected.id === -1 && inputValue.length > 0) {
					let valueFound = false;
					for (let item of items) {
						const normalizedItemValue = Util.TRANSFORM.TEXT.normalize(item.value.toLocaleUpperCase());
						const normalizedInputValue = Util.TRANSFORM.TEXT.normalize(inputValue.toLocaleUpperCase());
						if (normalizedItemValue === normalizedInputValue) {
							valueFound = true;
							setItemSelected(item);
							if (props.onChange)
								props.onChange(item);
							break;
						}

						if (!valueFound) {
							onChange(undefined);
							//trigger errors
						}
					}
				}
			}
		}

		document.addEventListener("mousedown", closeDropdown);
		return () => {
			document.removeEventListener("mousedown", closeDropdown);
		};
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropdownRef]);

	/**
	 * 
	 */
	useEffect(
		() => {
			setDefaultSelectedValue();
		}, [setDefaultSelectedValue]
	)

	/**
	 * 
	 */
	useEffect(() => {
		if (props.notFoundText && inputValue.length > 0) {
			const items = props.items;
			let notFound = true;
			for (const i of items) {
				const normalizedItemValue = Util.TRANSFORM.TEXT.normalize(i.value.toLocaleLowerCase());
				const normalizedInputValue = Util.TRANSFORM.TEXT.normalize(inputValue.toLocaleLowerCase());
				if (normalizedItemValue.includes(normalizedInputValue)) {
					notFound = false;
					break;
				}
			}
			setSearchNotFound(notFound);
		}
	}, [inputValue, setSearchNotFound, props.items, props.notFoundText]);

	/**
	 * 
	 */
	useEffect(() => {
		if (!inputValue && (!props.defaultValue || !isDefaultValueAvailable)) {
			onChange(undefined);
		} else if (inputValue) {
			let found = false;
			for (const i of items) {
				const normalizedItemValue = Util.TRANSFORM.TEXT.normalize(i.value.toLocaleLowerCase());
				const normalizedInputValue = Util.TRANSFORM.TEXT.normalize(inputValue.toLocaleLowerCase());
				if (normalizedItemValue === normalizedInputValue) {
					onChange(i)
					found = true;
					break
				}
			}

			if (!found) {
				onChange(undefined);
			}
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue])

	return (
		<div className={`dropdownWrapper ${dropdownClasses}`} ref={dropdownRef}>

			<Input
				className={`dropdownWrapper__input ${hasDeleteButton && 'dropdownWrapper__input--shorter'}`}
				didFocus={() => setIsInputSelected(true)}
				number={number}
				label={placeholder!}
				value={inputValue}
				readOnly={cancelSearch}
				noAnimation={cancelSearch}
				autoComplete="off"
				onChange={e => { setInputValue(e.target.value); setItemSelected({ id: -1, value: '' }) }}
			/>

			{
				hasDeleteButton &&
				<span className="dropdownWrapper__deleteIcon" onClick={deteleSelection}>
					{IconFactory.closeIcon()}
				</span>
			}

			<span className={`dropdownWrapper__arrowIcon ${arrowIconClasses}`}>
				{IconFactory.downArrowIcon()}
			</span>
			{
				(isInputSelected || isMouseOver) && (props.items.length > 0 || searchNotFound) &&
				<div className={`dropdownWrapper__itemsList ${itemListClasses}`} onMouseEnter={() => { setIsMouseOver(true) }} onMouseLeave={() => setIsMouseOver(false)}>
					{
						items.map((item, index) => {
							const normalizedItemValue = Util.TRANSFORM.TEXT.normalize(item.value.toLocaleLowerCase())
							const normalizedInputValue = Util.TRANSFORM.TEXT.normalize(inputValue.toLocaleLowerCase())
							if (inputValue === '' || cancelSearch) {
								return (
									<div key={`dropdownItem-${uid}-${index}`}
										className={`dropdownWrapper__itemsList__item${item.value === inputValue ? ' dropdownWrapper__itemsList__item--current' : ''}`}
										onClick={() => { handleItemClick({ id: item.id, value: item.value }) }}
									>
										<Text type="label" className="dropdownWrapper__itemsList__item__value" block>{item.value}</Text>
										{
											item.info &&
											<Text type="label" className="dropdownWrapper__itemsList__item__value" block bold>{item.info}</Text>
										}
									</div>
								)
							} else if ((normalizedItemValue.includes(normalizedInputValue))) {
								return (
									<div key={`dropdownItem-${uid}-${index}`}
										className="dropdownWrapper__itemsList__item"
										onClick={() => { handleItemClick({ id: item.id, value: item.value }) }}
									>
										<Text type="label" className="dropdownWrapper__itemsList__item__value" block>{item.value}</Text>
									</div>
								)
							} else {
								return undefined;
							}
						})
					}

					{
						notFoundText && searchNotFound &&
						<div className="dropdownWrapper__itemsList__notFound">
							<Text align="center" type="label">
								{
									notFoundText ||
									<>
										No se encontró lo que buscas
									</>
								}
							</Text>
							<br />
						</div>
					}
				</div>
			}

			<div className="dropdownWrapper__selection relative">
				{
					props.isLoading &&
					<span className={`dropdownWrapper__selection__loader ${hasDeleteButton && 'dropdownWrapper__selection__loader--left'} absolute`}>
						<Spinner />
					</span>
				}
			</div>
		</div>
	);
};