import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { IconFactory } from 'src/factories/icon.factory';
import './BackButton.scss';

/**
 *
 */
interface IBackButton {
	/**
	 * 
	 */
  text?: boolean;
	/**
	 * 
	 */
  overlay?: boolean;
	/**
	 * 
	 */
  button?: boolean;
	/**
	 * 
	 */
	hideText?: boolean;
	/**
	 * 
	 */
  onClick?: () => void;
}

/**
 *
 */
export const BackButton = (props: IBackButton & LinkProps): JSX.Element => {

	const { text, overlay, to, button, hideText, onClick } = props;
	const linkClasses = classNames({
		'backButton--hideTextOnMobile': hideText,
		'backButton--justIcon': !button && !text
	});
	const buttonClasses = classNames({
		'hideTextOnMobile': hideText
	});

  return (
    <>
      {
        !button &&
        <Link to={to} replace className={`backButton ${linkClasses}`} link-overlay={overlay ? '' : null} onClick={() => onClick ? onClick() : undefined}>
          {IconFactory.backArrowIcon()}
          {
            text &&
            <span>
              Regresar
            </span>
          }
        </Link>
      }

      {
        button && onClick &&
        <button type="button" className={`backButton ${buttonClasses}`} link-overlay={overlay ? '' : null}
          onClick={(e) => { e.preventDefault(); onClick(); }}>
          {IconFactory.backArrowIcon()}
          {
            text &&
            <span>
              Regresar
            </span>
          }
        </button>
      }
    </>
  );
};