/**
 * 
 */
export const apiPath = {
	VEHICLE_BRAND: 'v1/catalog/vehicle/brand',
  VEHICLE_MODEL: (brand: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type`,
  VEHICLE_YEAR: (brand: string, model: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type/${model}/model`,
  VEHICLE_VERSION: (brand: string, model: string, year: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type/${model}/model/${year}/version`,
  VERIFY_VEHICLE_VIN: (vin: string) => `v1/policy/vehicle/verify/${vin}`,
  VERIFY_POSTAL_CODE: (postalCode: string) => `v1/catalog/pld/postalEntities?code=${postalCode}`,
  VERIFY_EMAIL: (email: string) => `v1/policy/person/email/${email}/isAvailable`,
  VERIFY_PERSON_RFC: (rfc: string) => `v1/policy/person/rfc/${rfc}/isAvailable`,
  QUOTATION: 'v1/quotation',
	GET_COUNTRIES: '/v1/catalog/pld/countries',
	GET_COUNTRY_STATES: (countryId: number) => `v1/catalog/pld/countries/${countryId}/states`,
	GET_ECONOMIC_ACTIVITIES: 'v1/catalog/pld/economicActivities',
	GET_OWN_BUSINESS_ACTIVITIES: 'v1/catalog/pld/economicActivities/complex',
	POLICY_MONTHLY_PAYMENT: 'v1/policy/pay',
  POLICY_PAYMENT: 'v1/policy/paymentorder',
	POLICY_AUTOMATIC_RENEWAL: '/v2/policy/automaticrenewal',
  REGISTER_USER: '/v1/users/register',
  REGISTER_VEHICLE: 'v1/policy/vehicle',
  POLICY_REQUEST: 'v1/policy/request',
  USER_LOGIN: 'v1/users/login',
}
