import { PaymentCost } from "./PaymentCost";
import { PlanMonthly } from "./PlanMonthly";
import { PlanSubscription } from "./PlanSubscription";

export class PlanDiscount {
  /**
   *
   */
  private _description: string;
  set description(_description: string) { this._description = _description };
  get description(): string { return this._description };
  /**
   *
   */
  private _cost: PaymentCost;
  set cost(_cost: PaymentCost) { this._cost = _cost };
  get cost(): PaymentCost { return this._cost };
  /**
   *
   */
  private _subscription:  PlanSubscription;
  set subscription(_subscription:  PlanSubscription) { this._subscription = _subscription };
  get subscription():  PlanSubscription { return this._subscription };
	/**
	 * 
	 */
	private _monthly: PlanMonthly;
	set monthly(_monthly: PlanMonthly) { this._monthly = _monthly };
	get monthly(): PlanMonthly { return this._monthly };

  constructor(_description?: string, _cost?: PaymentCost) {
    this._description = _description ? _description : '';
    this._cost = _cost? _cost : new PaymentCost();
    this._subscription = new PlanSubscription();
		this._monthly = new PlanMonthly();
  }
}