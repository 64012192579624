import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Address } from "src/classes/person/Address";
import { User } from "src/classes/person/User";

/**
 *
 */
export namespace QuoterPersonAction {
	
	/**
   * 
   */
	export const getStoredPerson = createAction('GET_STORED_PERSON');
	/**
	 * 
	 */
	export const setStoredPerson: ActionCreatorWithPayload<User> = createAction('SET_STORED_PERSON');
	/**
	 * 
	 */
	export const setPersonAddress: ActionCreatorWithPayload<Address> = createAction('SET_PERSON_ADDRESS');
	/**
   * 
   */
	export const clear = createAction('CLEAR_QUOTER_PERSON');
}