import { Util } from "src/utils/Util";

import "./Logo.scss";

/**
 *
 */
interface ILogo {

  type?: 'short' | 'full';

  className?: string;
}

/**
 *
 */
export const Logo = (props: ILogo): JSX.Element => {
  const {
    type = !props.type ? 'short' : props.type,
  } = props;

  return (
    <>
      {
        type === 'short' &&
        <a className={`logoWrapper${props.className ? ` ${props.className}` : ''}`} href="https://www.crabi.com/" target="_self" title="Crabi">
          <img className="logoWrapper__image" alt="Logo" src={Util.ASSET.LOGO}></img>
        </a>
      }
      {
        type === 'full' &&
        <a className={`logoWrapper${props.className ? ` ${props.className}` : ''}`} href="https://www.crabi.com/" title="Crabi">
          <img className="logoWrapper__image logoWrapper__image--full" alt="Logo" src={Util.ASSET.LOGO_FULL}></img>
        </a>
      }

    </>

  );
};
