export class Phone {
  /**
   *
   */
  private _number: string;
  set number(_number: string) { this._number = _number };
  get number(): string { return this._number };
  /**
   *
   */
  private _countryCode: TCountryCode | undefined;
  set countryCode(_countryCode: TCountryCode | undefined) { this._countryCode = _countryCode };
  get countryCode(): TCountryCode | undefined { return this._countryCode };

  constructor(_number?: string) {
    this._number = _number? _number : '';
  }
}