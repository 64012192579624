import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { IVehicleBrandData } from "../interfaces/IVehicleBrandData";
import { IVehicleModelData } from "../interfaces/IVehicleModelData";
import { IVehicleVersionData } from "../interfaces/IVehicleVersionData";
import { IVehicleYearData } from "../interfaces/IVehicleYearData";

/**
 * 
 */
export namespace QuoterVehicleAdapter {

  /**
   * 
   * @param data 
   */
  export const brandsDataAdapter = (data: IVehicleBrandData): VehicleBrand[] => {
    const list: VehicleBrand[] = [];
    for (let dataObject of data.brands) {
      const name: string = dataObject.name.charAt(0).toUpperCase() + dataObject.name.slice(1).toLowerCase();
      list.push(new VehicleBrand(dataObject.id, name));
    }

    return list;
  }

  /**
   * 
   * @param data 
   */
  export const modelsDataAdapter = (data: IVehicleModelData): VehicleModel[] => {
    const list: VehicleModel[] = [];
    for (let dataObject of data.types) {
      const name: string = dataObject.name.charAt(0).toUpperCase() + dataObject.name.slice(1).toLowerCase();
      list.push(new VehicleModel(dataObject.id, dataObject.brand_id, name));
    }

    return list;
  }

  /**
   * 
   * @param data 
   */
  export const vehicleYearsDataAdapter = (data: IVehicleYearData): VehicleYear[] => {
    const list: VehicleYear[] = [];
    for (let dataObject of data.models) {
      list.push(new VehicleYear(dataObject.brand_id, dataObject.type_id, dataObject.model));
    }

    return list;
  }

  /**
   * 
   * @param data 
   */
  export const vehicleVersionsDataAdapter = (data: IVehicleVersionData): VehicleVersion[] => {
    const list: VehicleVersion[] = [];
    for (let dataObject of data.versions) {
      const description = dataObject.parts.charAt(0).toUpperCase() + dataObject.parts.slice(1).toLowerCase();
      list.push(new VehicleVersion(dataObject.id, description));
    }

    return list;
  }
}