import { Controller } from 'react-hook-form';
import { Button, Dropdown, FormError } from 'src/components';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { Input } from 'src/components/molecules/Input/Input';
import { QuoterPersonFormFacade } from 'src/modules/quoter/controller/facades/quoter-person-form.facade.';
import { QuoterPersonFacade } from 'src/modules/quoter/controller/facades/quoter-person.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { QUOTER_PATH, VEHICLE_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterPerson.scss';

/**
 *
 */
const QuoterPersonPage = (): JSX.Element => {

	const { storedPerson } = QuoterFacade.useQuoterActors();
	const { onNavigate, setPersonValues, search, validateNames } = QuoterPersonFacade.useQuoterPerson();
	const { formData, FORM_DATA_ERRORS, GENDERS } = QuoterPersonFormFacade.useQuoterPersonForm(storedPerson);

	return (
		<div className="quoterPerson">
			<QuoterContent
				backButton={<BackButton to={`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${VEHICLE_ROUTE_ID.VERSION}${search}`} />}
				title="¡Muchas gracias!"
				subtitle="Ahora, ayúdame con tus datos personales 🙏🏻"
			>
				<form className="quoterPerson__form">

					<div className="quoterPerson__form__inputWrapper">
						<Controller
							name="name"
							control={formData.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.NAMES,
								minLength: 2,
								validate: { invalid: (name) => validateNames(name) }
							}}
							render={({ field }) => (
								<Input
									label={'PRIMER NOMBRE'}
									required
									defaultValue={storedPerson ? storedPerson.name : ''}
									autoComplete="off"
									type="text"
									maxLength={200}
									errors={!!formData.formState.errors.name}
									onChange={(e) => field.onChange(e.target.value)}
								/>
							)}
						/>
						<FormError schema={FORM_DATA_ERRORS.NAME_ERRORS} type={formData.formState.errors.name?.type} />
					</div>

					<div className="quoterPerson__form__inputWrapper">
						<Controller
							name="firstLastname"
							control={formData.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.NAMES,
								minLength: 2,
								validate: { invalid: (firstLastname) => validateNames(firstLastname) }
							}}
							render={({ field }) => (
								<Input
									label={'APELLIDO PATERNO'}
									required
									defaultValue={storedPerson ? storedPerson.lastName : ''}
									autoComplete="off"
									type="text"
									maxLength={100}
									errors={!!formData.formState.errors.firstLastname}
									onChange={(e) => field.onChange(e.target.value)}
								/>
							)}
						/>
						<FormError schema={FORM_DATA_ERRORS.LASTNAME_ERRORS} type={formData.formState.errors.firstLastname?.type} />
					</div>

					<div className="quoterPerson__form__inputWrapper">
						<Controller
							name="secondLastname"
							control={formData.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.NAMES,
								minLength: 2,
								validate: { invalid: (secondLastname) => validateNames(secondLastname) }
							}}
							render={({ field }) => (
								<Input
									label={'APELLIDO MATERNO'}
									required
									defaultValue={storedPerson ? storedPerson.secondLastName : ''}
									autoComplete="off"
									type="text"
									maxLength={100}
									errors={!!formData.formState.errors.secondLastname}
									onChange={(e) => field.onChange(e.target.value)}
								/>
							)}
						/>
						<FormError schema={FORM_DATA_ERRORS.LASTNAME_ERRORS} type={formData.formState.errors.secondLastname?.type} />
					</div>

					<div className="quoterPerson__form__inputWrapper quoterPerson__form__inputWrapper--space-bottom">
						<Controller
							name="gender"
							control={formData.control}
							rules={{
								required: true,
							}}
							render={({ field }) => (
								<Dropdown
									cancelSearch
									placeholder="SEXO AL NACER"
									defaultValue={formData.getValues('gender') ? GENDERS.find(g => g.id === formData.getValues('gender')) : undefined}
									items={
										GENDERS.map(
											(g) => { return { id: g.id, value: g.value } }
										)
									}
									onChange={(item) => { field.onChange(item?.id) }}
								/>
							)}
						/>
					</div>
				</form>

				<Button className="quoterPerson__button"
					disabled={!formData.formState.isValid}
					onClick={() => setPersonValues(formData.getValues()).then(() => onNavigate(formData.getValues()))}
				>
					Siguiente
				</Button>
			</QuoterContent>



		</div>
	);
};

export default QuoterPersonPage;