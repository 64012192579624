export class VehicleModel {
	/**
	 * 
	 */
  private _id: number;
  get id(): number { return this._id; }
  set id(_id: number) { this._id = _id; }
	/**
	 * 
	 */
  private _brand: number;
  get brand(): number { return this._brand; }
  set brand(_brand: number) { this._brand = _brand; }
	/**
	 * 
	 */
  private _name: string;
  get name(): string { return this._name; }
  set name(_name: string) { this._name = _name; }

  constructor(_id?: number, _brand?: number, _name?: string) {
    this._id = _id? _id : 0;
    this._brand = _brand? _brand : 0;
    this._name = _name? _name : '--';
  }
}