import { PaymentCost } from "src/classes/plan/PaymentCost";
import { Coverage } from "src/classes/plan/Coverage";
import { CoverageOption } from "src/classes/plan/CoverageOption";
import { Plan } from "src/classes/plan/Plan";
import { PlanDiscount } from "src/classes/plan/PlanDiscount";
import { PlanMonthly } from "src/classes/plan/PlanMonthly";
import { PlanPayment } from "src/classes/plan/PlanPayment";
import { PlanSubscription } from "src/classes/plan/PlanSubscription";
import { User } from "src/classes/person/User";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { IQuotationCoverageRequest } from "../interfaces/IQuotationCoverageRequest";
import { IQuotationData } from "../interfaces/IQuotationData";
import { IQuotationRequest } from "../interfaces/IQuotationRequest";
import { ISubscriptionData } from "../interfaces/ISubscriptionData";
import { ICoverageData } from "../interfaces/ICoverageData";

export namespace PlanAdapter {
  const QUOTATION_COVERAGES: string = JSON.stringify(Util.CONSTANT.QUOTATION.QUOTATION_COVERAGES);
  const QUOTATION_COVERAGES_CONFIG: any[] = Util.CONSTANT.QUOTATION.QUOTATION_CONFIG;
  /** */
  const POLICY_TYPES = {
    COMERCIAL_ANUAL: 'COMERCIAL_ANUAL',
    BILL_ANUAL: 'FACTURA_ANUAL',
    RCO: 'RCO'
  };

  /**
   * 
   */
  const coverageData = (coverages: ICoverageData[]): Coverage[] => {
    let coveragesList: Coverage[] = [];
    const rcpData = coverages.find(c => c.coverage_type.id === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCP.id);
    const rcbData = coverages.find(c => c.coverage_type.id === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCB.id);
    if (rcpData && rcbData) {
      let newCoverage: Coverage = new Coverage();
      newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type;
      newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.name;
      newCoverage.id = rcpData.id;
      newCoverage.isEnabled = rcpData.enabled;
      newCoverage.assured = rcpData.sum_assured + rcbData.sum_assured;
      newCoverage.deductible = rcpData.deductible;
      newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.description;
      newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.include;
      newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.exclude;
      newCoverage.image = Util.ASSET.COVERAGE_DT;
      coveragesList.push(newCoverage);
    }

    for (const coverageData of coverages) {
      if (coverageData.coverage_type.id !== Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCP.id &&
        coverageData.coverage_type.id !== Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCB.id) {
        let newCoverage: Coverage = new Coverage();
        newCoverage.id = coverageData.id;
        newCoverage.isEnabled = coverageData.enabled !== undefined ? true : false;
        newCoverage.assured = coverageData.sum_assured;
        newCoverage.deductible = coverageData.deductible;
        // Switch between known coverages ids in Utils and push it to the list
        switch (coverageData.coverage_type.id) {
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_GM;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_AL;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_AV;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.exclude;
            newCoverage.isDeductible = true;
            newCoverage.image = Util.ASSET.COVERAGE_DM;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.exclude;
            newCoverage.isDeductible = true;
            newCoverage.image = Util.ASSET.COVERAGE_RT;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
        }
      }
    }
    // end Set plan coverages

    // Set coverages options
    for (const coverage of coveragesList) {
      switch (coverage.type) {
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type:
          coverage.customitationOptions.push(new CoverageOption(undefined, 2000000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 3000000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 4000000));
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type:
          coverage.customitationOptions.push(new CoverageOption(undefined, 200000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 300000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 400000));
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type:
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type:
          coverage.customitationOptions.push(new CoverageOption(0.03, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.05, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.1, undefined));
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type:
          coverage.customitationOptions.push(new CoverageOption(0.05, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.1, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.15, undefined));
          break;
      }
    }

    return coveragesList;
  }

  /**
   * 
   * @param subscriptionData 
   * @returns 
   */
  const getSubscriptionData = (subscriptionData: ISubscriptionData): PlanSubscription => {
    const subsPayments = subscriptionData.payments;
    let subscriptionFirstCost: PaymentCost = new PaymentCost(
      (subsPayments[0].subtotal / 1000),
      (subsPayments[0].taxes / 1000),
      (subsPayments[0].total / 1000)
    );

    subscriptionFirstCost.monthsFee = (subsPayments[0].policies[0].fee / 1000) + (subsPayments[0].policies[1].fee / 1000);
    subscriptionFirstCost.monthsFeePercent = 20;

    subsPayments[0].policies.forEach(item => {
      if (item.policy_type.name === POLICY_TYPES.RCO) {
        subscriptionFirstCost.civilTax = item.net_premium / 1000;
      } else {
        subscriptionFirstCost.netAmount = item.net_premium / 1000;
      }
    })

    let subscriptionCost: PaymentCost = new PaymentCost(
      (subsPayments[1].subtotal / 1000),
      (subsPayments[1].taxes / 1000),
      (subsPayments[1].total / 1000)
    );

    subscriptionCost.monthsFee = (subsPayments[1].policies[0].fee / 1000);
    subscriptionCost.monthsFeePercent = 20;
    subscriptionFirstCost.isSubscription = true;
    subscriptionCost.isSubscription = true;

    subsPayments[1].policies.forEach(item => {
      if (item.policy_type.name === POLICY_TYPES.RCO) {
        subscriptionCost.civilTax = item.net_premium / 1000;
      } else {
        subscriptionCost.netAmount = item.net_premium / 1000;
      }
    });

    return new PlanSubscription(subscriptionFirstCost, subscriptionCost);
  }

  /**
   * 
   * @param user 
   * @param vehicle 
   * @param coupon 
   * @returns 
   */
  export const quotationRequestAdapter = (vehicle: Vehicle, user: User, coverages?: Coverage[], coupon?: string, isRenewal?: boolean, planType?:number): IQuotationRequest => {
    const data: IQuotationRequest = {
      vehicle: { version: { code: vehicle.version?.id as number } },
      coupon: coupon ? coupon.replaceAll(' ', '') : '',
      region: { postal_code: user.profile?.address?.postalCode!, state: { code: user.profile?.address?.stateCode! } },
      person: {
        civil_status_code: user.profile?.civilStatus!,
        gender_code: user.profile?.gender!,
        birthdate: Util.TRANSFORM.DATE.formatServerDate(user.profile?.birthdate!),
        email: user.email,
        first_last_name: user.lastName,
        second_last_name: user.secondLastName,
        first_name: user.name,
        phone: user.profile?.getPhoneNumber() || ''
      },
      plans: JSON.parse(QUOTATION_COVERAGES) as IQuotationCoverageRequest[]
    }

    if (isRenewal) {
      data.vehicle['vin'] = vehicle.vin;
      data['plans'] = [data.plans[0]];
    }

    if (coverages) {
      let customPlan = data.plans.find(p => p.name === Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.serverName);
      if (customPlan) {

        for (const coverage of coverages) {
          let coverageData = customPlan.policies[0].coverages.find(c => c.coverage_type.name === coverage.type);
          if (coverageData) {
            switch (coverage.type) {
              case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type:
                coverageData.sum_assured = coverage.assured ? coverage.assured : coverageData.sum_assured;
                break;
              case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type:
                coverageData.enabled = coverage.isEnabled;
                break;
              case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type:
                coverageData.deductible = coverage.deductible ? coverage.deductible : coverageData.deductible;
                coverageData.enabled = coverage.isEnabled;
                break;
              case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type:
                coverageData.deductible = coverage.deductible ? coverage.deductible : coverageData.deductible;
                coverageData.enabled = coverage.isEnabled;
                break;
            }
          } else if (coverage.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type) {
            let rcpData = customPlan.policies[0].coverages.find(c => c.coverage_type.name === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCP.type);
            let rcbData = customPlan.policies[0].coverages.find(c => c.coverage_type.name === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCB.type);
            if (rcpData && rcbData) {
              rcpData.sum_assured = coverage.assured / 2;
              rcbData.sum_assured = coverage.assured / 2;
            }
          }
        }
      }
    }

    if (planType) {
      let customPlan = data.plans.find(p => p.name === Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.serverName);
      if(customPlan) {
        customPlan.policies = QUOTATION_COVERAGES_CONFIG[planType-1].policies;
      }
    }

    return data;
  }

  /**
   * 
   * @param data 
   */
  export const quotationPlanDataAdapter = (data: IQuotationData): Plan[] => {
    let plans: Plan[] = [];
    for (const planData of data.plans) {
      let newPlan: Plan = new Plan();
      newPlan.id = planData.id;
      newPlan.coupon = data.coupon;
      newPlan.quotation = data.id;
      newPlan.cost = new PaymentCost((planData.subtotal / 1000), (planData.taxes / 1000), (planData.total / 1000), (planData.net_premium / 1000));
      newPlan.cost.expeditionRights = planData.expedition_rights && planData.expedition_rights > 0 ? planData.expedition_rights / 1000 : 0;
      // Set plan costs

      // Costs without discount
      let paymentsDataSubs = planData.payment_provider_options[0].card_issuer_options[0].payment_periodicity_options;
      paymentsDataSubs = paymentsDataSubs.filter(paymentPlan => {
        const isNineInstallments = paymentPlan.product_payment_type.name === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && paymentPlan.number_of_payments === 9;
        return !paymentPlan.msi && !isNineInstallments;
      });
      
      const subsPayments = paymentsDataSubs.find(p => p.subscription);

      // Setting payment cost and net amount for plan
      for (const paymentData of paymentsDataSubs) {
        if (!paymentData.subscription && paymentData.number_of_payments === 1) {
          paymentData.policies.forEach(item => {
            if (item.policy_type.name === POLICY_TYPES.RCO) {
              newPlan.cost.civilTax = item.net_premium / 1000;
            } else {
              newPlan.cost.netAmount = item.net_premium / 1000;
            }
          });
        }
      }

      if (subsPayments && subsPayments.subscription && subsPayments.product_payment_type.id === Util.STATUS_CODE.PLAN_TYPE.SUBSCRIPTION) {
        newPlan.subscription = getSubscriptionData(subsPayments.subscription);
        newPlan.subscription.firstCost.expeditionRights = subsPayments.expedition_rights && subsPayments.expedition_rights > 0 ? subsPayments.expedition_rights / 1000 : 0;
        newPlan.subscription.cost.expeditionRights = subsPayments.expedition_rights && subsPayments.expedition_rights > 0 ? subsPayments.expedition_rights / 1000 : 0;
      }
      // end Costs without discount

      //Set plan monthly
      let _monthly = new PlanMonthly();
      for (const paymentData of paymentsDataSubs) {
        if (paymentData.product_payment_type.id === Util.STATUS_CODE.PLAN_TYPE.MONTHLY && paymentData.number_of_payments < 12 && !paymentData.flat_fee) {
          let cost = new PaymentCost(
            paymentData.subtotal / 1000,
            paymentData.taxes / 1000,
            paymentData.total / 1000,
          );

          cost.expeditionRights = paymentData.expedition_rights && paymentData.expedition_rights > 0 ? paymentData.expedition_rights / 1000 : 0;
          cost.monthsQuantity = paymentData.number_of_payments;
          cost.monthsFee = paymentData.fee / 1000;
          cost.monthsFeePercent = parseFloat((paymentData.fee_rate * 100).toFixed(2));

          paymentData.policies.forEach(item => {
            if (item.policy_type.name === POLICY_TYPES.RCO) {
              cost.civilTax = item.net_premium / 1000;
            } else {
              cost.netAmount = item.net_premium / 1000;
            }
          });

          _monthly.costs.push(cost);
        }
      }

      if (_monthly.costs.length > 0)
        newPlan.monthly = _monthly;
      //end Set plan monthly

      // Costs with discount
      if (planData.discount) {
        const discountCost: PaymentCost = new PaymentCost((planData.discount.subtotal / 1000), (planData.discount.taxes / 1000), (planData.discount.total / 1000), (planData.discount.net_premium / 1000));
        discountCost.expeditionRights = planData.discount.expedition_rights && planData.discount.expedition_rights > 0 ? planData.discount.expedition_rights / 1000 : 0;  
        newPlan.discount = new PlanDiscount(planData.discount.marketing_text.default, discountCost);

        let paymentsData = planData.discount.payment_provider_options[0].card_issuer_options[0].payment_periodicity_options;
        paymentsData = paymentsData.filter(paymentPlan => {
          const isNineInstallments = paymentPlan.product_payment_type.name === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && paymentPlan.number_of_payments === 9;
          return !paymentPlan.msi && !isNineInstallments;
        });
        const paymentData = paymentsData.find(p => p.subscription);

        // Setting payment cost and net amount for discounted plan
        for (const discountedPaymentData of paymentsData) {
          if (!discountedPaymentData.subscription && discountedPaymentData.number_of_payments === 1) {
            discountedPaymentData.policies.forEach(item => {
              if (item.policy_type.name === POLICY_TYPES.RCO) {
                newPlan.cost.civilTax = item.net_premium / 1000;
              } else {
                newPlan.cost.netAmount = item.net_premium / 1000;
              }
            });
          }
        }

        if (paymentData && paymentData.subscription && paymentData.product_payment_type.id === Util.STATUS_CODE.PLAN_TYPE.SUBSCRIPTION) {
          newPlan.discount.subscription = getSubscriptionData(paymentData.subscription);
          newPlan.discount.subscription.firstCost.expeditionRights = paymentData.expedition_rights && paymentData.expedition_rights > 0 ? paymentData.expedition_rights / 1000 : 0;
          newPlan.discount.subscription.cost.expeditionRights = paymentData.expedition_rights && paymentData.expedition_rights > 0 ? paymentData.expedition_rights / 1000 : 0;
        }

        let _discountedMonthly = new PlanMonthly();
        for (const discountedPaymentData of paymentsData) {
          if (discountedPaymentData.product_payment_type.id === Util.STATUS_CODE.PLAN_TYPE.MONTHLY && discountedPaymentData.number_of_payments < 12 && !discountedPaymentData.flat_fee) {
            let cost = new PaymentCost(
              discountedPaymentData.subtotal / 1000,
              discountedPaymentData.taxes / 1000,
              discountedPaymentData.total / 1000,
            );

            cost.expeditionRights = discountedPaymentData.expedition_rights && discountedPaymentData.expedition_rights > 0 ? discountedPaymentData.expedition_rights / 1000 : 0;
            cost.monthsQuantity = discountedPaymentData.number_of_payments;
            cost.monthsFee = discountedPaymentData.fee / 1000;
            cost.monthsFeePercent = parseFloat((discountedPaymentData.fee_rate * 100).toFixed(2));

            discountedPaymentData.policies.forEach(item => {
              if (item.policy_type.name === POLICY_TYPES.RCO) {
                cost.civilTax = item.net_premium / 1000;
              } else {
                cost.netAmount = item.net_premium / 1000;
              }
              
            });

            _discountedMonthly.costs.push(cost);
          }
        }

        newPlan.discount.monthly = _discountedMonthly;
      }
      // end Costs with discount
      // end Set plan costs

      switch (planData.name) {
        case Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.serverName:
          newPlan.name = Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.clientName;
          newPlan.allowEdit = Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.edit;
          newPlan.description = Util.CONSTANT.QUOTATION.PLAN_NAMES.CUSTOM_PLAN.description;
          break;
        case Util.CONSTANT.QUOTATION.PLAN_NAMES.EXTENDED_PLAN.serverName:
          newPlan.name = Util.CONSTANT.QUOTATION.PLAN_NAMES.EXTENDED_PLAN.clientName;
          newPlan.allowEdit = Util.CONSTANT.QUOTATION.PLAN_NAMES.EXTENDED_PLAN.edit;
          newPlan.description = Util.CONSTANT.QUOTATION.PLAN_NAMES.EXTENDED_PLAN.description;
          break;
        case Util.CONSTANT.QUOTATION.PLAN_NAMES.BASIC_PLAN.serverName:
          newPlan.name = Util.CONSTANT.QUOTATION.PLAN_NAMES.BASIC_PLAN.clientName;
          newPlan.allowEdit = Util.CONSTANT.QUOTATION.PLAN_NAMES.BASIC_PLAN.edit;
          newPlan.description = Util.CONSTANT.QUOTATION.PLAN_NAMES.BASIC_PLAN.description;
          break;
      }

      // Set plan payments
      if (!planData.discount) {
        // Payments without discount
        const paymentsData = planData.payment_provider_options[0].card_issuer_options[0].payment_periodicity_options;
        for (let paymentData of paymentsData) {
          if (paymentData.product_payment_type.name === 'ANNUAL' && paymentData.number_of_payments === 1) {
            let newPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            newPayment.paymentsCount = paymentData.number_of_payments;
            newPlan.payments.push(newPayment);
          } else if (paymentData.subscription) {
            let newPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            newPayment.isSubscription = true;
            newPayment.paymentsCount = paymentData.number_of_payments;
            newPlan.payments.push(newPayment);
          } else if (paymentData.number_of_payments > 1 && paymentData.number_of_payments !== 12 && !paymentData.subscription && !paymentData.flat_fee) {
            let newPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            newPayment.paymentsCount = paymentData.number_of_payments;
            newPlan.payments.push(newPayment);
          }
        }
        // end Payments without discount
      } else {
        // Payments with discount
        const paymentsData = planData.discount.payment_provider_options[0].card_issuer_options[0].payment_periodicity_options;
        for (let paymentData of paymentsData) {
          if (paymentData.product_payment_type.name === 'ANNUAL' && paymentData.number_of_payments === 1) {
            let newPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            newPayment.paymentsCount = paymentData.number_of_payments;
            newPlan.payments.push(newPayment);
          } else if (paymentData.subscription) {
            let discountPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            discountPayment.paymentsCount = paymentData.number_of_payments;
            discountPayment.isSubscription = true;
            newPlan.payments.push(discountPayment);
          } else if (paymentData.number_of_payments > 1 && paymentData.number_of_payments !== 12 && !paymentData.subscription && !paymentData.flat_fee) {
            let discountPayment: PlanPayment = new PlanPayment(paymentData.id, paymentData.product_payment_type.name);
            discountPayment.paymentsCount = paymentData.number_of_payments;
            newPlan.payments.push(discountPayment);
          }
        }

        // Set quotation subscription payment (not renewal)
        // if (!isRenewal) {
        //   const quotationPaymentsData = planData.payment_provider_options[0].card_issuer_options[0].payment_periodicity_options;
        //   const quotationPaymentSubsData = quotationPaymentsData.find(q => q.subscription);
        //   if (quotationPaymentSubsData && quotationPaymentSubsData.subscription) {
        //     const subsPayments = quotationPaymentSubsData.subscription.payments;
        //     const subscriptionFirstCost: PaymentCost = new PaymentCost((subsPayments[0].subtotal / 1000), (subsPayments[0].taxes / 1000), (subsPayments[0].total / 1000), (subsPayments[1].net_premium / 1000));
        //     const subscriptionCost: PaymentCost = new PaymentCost((subsPayments[1].subtotal / 1000), (subsPayments[1].taxes / 1000), (subsPayments[1].total / 1000));
        //     subscriptionFirstCost.isSubscription = true;
        //     subscriptionCost.isSubscription = true;

        //     let quotationPayment: PlanPayment = new PlanPayment(quotationPaymentSubsData.id, quotationPaymentSubsData.product_payment_type.name);
        //     quotationPayment.paymentsCount = quotationPaymentSubsData.number_of_payments;
        //     quotationPayment.subscription = new PlanSubscription(subscriptionFirstCost, subscriptionCost);
        //     quotationPayment.subscription.firstCost.aditionalInfo = { name: 'RCO (Responsabilidad Civil Obligatoria)', value: quotationPaymentSubsData.policies[1].subtotal / 1000 }
        //     newPlan.payments.push(quotationPayment);
        //   }
        // }
        // End Set quotation subscription payment (not renewal)
      }
      // end Set plan payments

      // Set plan coverages
      newPlan.coveragesList = coverageData(planData.policies[0].coverages);
      plans.push(newPlan);
    }

    return plans;
  }
}