import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconFactory } from "src/factories/icon.factory";
import { Util } from "src/utils/Util";
import { Input } from "../Input/Input";
import './InputPhone.scss';

type PhoneCountryCode = {

	iso: string;

	value: string;
}

/**
 *
 */
interface IInputPhone extends React.InputHTMLAttributes<HTMLInputElement> {

	errors?: boolean;

	required?: boolean;

	onCountryCodeSelected?: (code: string) => void;
}

/**
 *
 */
export const InputPhone = (props: IInputPhone): JSX.Element => {

	/**
	 * 
	 */
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [currentCountryCode, setCurrentCountryCode] = useState<PhoneCountryCode>({ iso: 'MX', value: '+52' });
	const [isCountryCodeListOpen, setIsCountryCodeListOpen] = useState<boolean>(false);
	const [isInputSelected, setIsInputSelected] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');

	/**
	 * 
	 * @param item 
	 */
	const handleItemClick = (item: { id: number, value: string }): void => {
		const selectedCountry = Util.COUNTRIES.find(c => c.dialCode === item.value);
		if (selectedCountry) {
			setCurrentCountryCode({ iso: selectedCountry.id, value: selectedCountry.dialCode });
			setIsCountryCodeListOpen(false);
			setSearchText('');
		}
	}

	const searchVerification = (baseItemName: string) => {
		return baseItemName.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(searchText.toLocaleLowerCase());
	}

	// Deconstruct our props
	const {
		errors = false,
		onChange,
		defaultValue,
		onCountryCodeSelected
	} = props;

	const inputWrapperClassNames = classNames({
		'phoneWrapper--invalid': errors ? true : false,
		'phoneWrapper--focused': isInputSelected || isCountryCodeListOpen
	})

	/**
	 * 
	 * @param event 
	 */
	const handleClickOutside = useCallback(
		(event: any) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				if (isCountryCodeListOpen) {
					setIsCountryCodeListOpen(false);
					setSearchText('');
				}
			}
		}, [isCountryCodeListOpen]
	);


	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, handleClickOutside]);


	useEffect(() => {
		if (onCountryCodeSelected) {
			onCountryCodeSelected(currentCountryCode.value);
		}
	}, [currentCountryCode, onCountryCodeSelected])

	return (
		<div className={`phoneWrapper ${inputWrapperClassNames}`} ref={wrapperRef}>
			<div className="phoneWrapper__inputWrapper">
				<div className="phoneWrapper__inputWrapper__country" onClick={() => setIsCountryCodeListOpen(!isCountryCodeListOpen)}>
					<span className="phoneWrapper__inputWrapper__country__countryFlag">
						<img alt="mx" src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${currentCountryCode.iso}.svg`} />
					</span>

					<span className="phoneWrapper__inputWrapper__country__countryCode">
						{currentCountryCode.value}
					</span>

					{IconFactory.downArrowIcon()}
				</div>
				<Input
					label="TELEFONO"
					type="tel"
					autoComplete="off"
					maxLength={10}
					noBorder
					defaultValue={defaultValue}
					didFocus={() => setIsInputSelected(true)}
					didBlur={() => setIsInputSelected(false)}
					onChange={onChange}
					onKeyPress={(e) => { !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.preventDefault() }}
				/>
			</div>

			{
				(isCountryCodeListOpen) &&
				<div className="phoneWrapper__countries">
					<div className="phoneWrapper__countries__searchCountry">
						{IconFactory.forwardArrowIcon()}
						<input autoFocus type="text" placeholder="Buscar país" onChange={(e) => setSearchText(e.target.value)} />
					</div>

					{
						Util.COUNTRIES.map(
							(country, index) => {
								if (searchText !== '' && (searchVerification(country.id) || searchVerification(country.label))) {
									return (
										<div className="phoneWrapper__countries__countryItem" key={country.id} onClick={() => handleItemClick({ id: index, value: country.dialCode })}>
											<span>
												<img className="phoneWrapper__countries__countryItem__countryFlag" alt={country.id} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.id}.svg`} />
											</span>

											<span className="inline-block vertical-align-middle">
												{country.label}
											</span>
										</div>
									)
								} else if (searchText === '') {
									return (
										<div className="phoneWrapper__countries__countryItem" key={country.id} onClick={() => handleItemClick({ id: index, value: country.dialCode })}>
											<span>
												<img className="phoneWrapper__countries__countryItem__countryFlag" alt={country.id} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.id}.svg`} />
											</span>

											<span>
												{country.label}
											</span>
										</div>
									)
								} else {
									return undefined
								}
							}
						)
					}
				</div>
			}
		</div>
	);
};