import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QUOTER_PATH } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterFacade } from "./quoter.facade";
import { GooglesheetsFactory } from "src/factories/googlesheets.factory";


export namespace QuoterGooglesheetsFacade {

	const BUCKET_VALUES = {
		firstBucket: 'Bucket 1',
		secondBucket: 'Bucket 2',
		thirdBucket: 'Bucket 3',
		fourthBucket: 'Bucket 4'
	};

  /** */
  export const useGooglesheetFacade = () => {
		const { storedPerson, storedVehicle } = QuoterFacade.useQuoterActors();
		const [currentRow, setCurrentRow] = useState<number>(-1);
		const {pathname} = useLocation();

		/**
		 * 
		 */
		useEffect(() => {
			const setVehicleData = async() => {
				const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
					date: Util.TRANSFORM.DATE.dateToStringWithTimezoneOffset(new Date()),
					brand: storedVehicle.brand?.name,
					model: storedVehicle.model?.name,
					year: storedVehicle?.year?.value.toString(),
					version: storedVehicle.version?.description
				};

				const row = await GooglesheetsFactory.insertVehicleData(spreadsheetData);
				setCurrentRow(row);
			}

			if(pathname.includes(QUOTER_PATH.QUOTER_PERSON_PATH) && storedVehicle)
				setVehicleData();

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pathname]);

		/**
		 * 
		 */
		useEffect(() => {
			const updatePersonNames = async() => {
				if (!storedPerson)
					return

				const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
					first_name: storedPerson.name,
					last_name: storedPerson.lastName,
					maiden_name: storedPerson.secondLastName
				};

				if (currentRow >= 0)
					await GooglesheetsFactory.insertPersonNames(spreadsheetData, currentRow);
				//SpreadsheetFactory.updateRow(currentRow);
			};

			const updateEmailInfo = async() => {
				if (!storedPerson || !storedPerson.profile)
					return

				const antiquity = new Date().getFullYear() - storedVehicle?.year!.value;
				let range: string = '';

				if (antiquity >= 10) {
					range = BUCKET_VALUES.firstBucket;
				} else if (antiquity >= 6 && antiquity <= 9) {
					range = BUCKET_VALUES.secondBucket;
				} else if (antiquity >= 0 && antiquity <= 5) {
					range = BUCKET_VALUES.thirdBucket;
				}

				const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
					email: storedPerson.email,
					phone: storedPerson.profile.getPhoneNumber(),
					birthdate: Util.TRANSFORM.DATE.formatDate(storedPerson.profile!.birthdate!),
					antiquity: antiquity,
					range: range
				};
				
				if (currentRow >= 0)
					await GooglesheetsFactory.insertProfile(spreadsheetData, currentRow);
			};

			if(storedPerson) {
				if(pathname.includes(QUOTER_PATH.QUOTER_EMAIL_PATH)){					
					updatePersonNames();
				} else if (pathname.includes(QUOTER_PATH.QUOTER_LOCATION_PATH)) {
					updateEmailInfo();
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pathname, currentRow]);

		/**
		 * 
		 */
		useEffect(() => {
			const updateAddressInfo = async() => {
				if (!storedPerson || !storedPerson.profile || !storedPerson.profile.address)
					return

				const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
					postalcode: storedPerson.profile.address.postalCode,
					township: storedPerson.profile.address.neighborhood,
					street: storedPerson.profile.address.street
				};			

				if (currentRow >= 0)
					await GooglesheetsFactory.insertAddress(spreadsheetData, currentRow);
			}

			const updateChannelNameInfo = async() => {
				const channelName = sessionStorage.getItem(Util.KEY.SELECT_CHANNEL);

				if (channelName) {

					const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
						channel: channelName
					};

					if (currentRow >= 0)
						await GooglesheetsFactory.insertChannel(spreadsheetData, currentRow)
				}
			};

			if(pathname.includes(QUOTER_PATH.QUOTER_PLAN_PATH)) {
				updateAddressInfo();
				updateChannelNameInfo();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pathname, currentRow]);

		/**
		 * 
		 */
		useEffect(() => {
			const updateContractInfo = async() => {
				const spreadsheetData: GooglesheetsFactory.ISpreadsheet = {
					range: BUCKET_VALUES.fourthBucket
				};			

				if (currentRow >= 0)
					await GooglesheetsFactory.completeFlow(spreadsheetData, currentRow);
			}

			if(pathname.includes(QUOTER_PATH.QUOTER_COMPLETION_PATH)){
				updateContractInfo();
			}
			// eslint-disable-next-line
		}, [pathname, currentRow])

  }
}