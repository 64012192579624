import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IQuoterPaymentDataForm } from "../interfaces/IQuoterPaymentDataForm";
import { IQuoterPaymentMethodDataForm } from "../interfaces/IQuoterPaymentMethodDataForm";
import { useParams } from "react-router-dom";
import { PAYMENT_ROUTE_ID } from "src/routes/paths/quoter.paths";

/**
 *
 */
export namespace QuoterPaymentFormFacade {

  /**
   * 
   */
  export const useQuoterPaymentForm = () => {

		const { id: urlId } = useParams<string>();

		const { formState: { isValid, isDirty }, control, getValues, setValue, trigger } = useForm<IQuoterPaymentDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

		useEffect(( ) => {
			if (urlId === PAYMENT_ROUTE_ID.DATA) {
				setValue('acceptResourcesOwner', false);
				setValue('acceptDigitalCover', false);
				trigger();
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [urlId])

    return { paymentForm: { formState: { isValid, isDirty }, control, getValues } }
  }

	/**
   * 
   */
	export const useQuoterPaymentMethodForm = () => {

		const { id: urlId } = useParams<string>();

		const { formState: { isValid, isDirty }, control, setValue, getValues, trigger } = useForm<IQuoterPaymentMethodDataForm>(
			{
				mode: 'onChange',
				reValidateMode: 'onChange',
			}
		);

		useEffect(( ) => {
			if (urlId === PAYMENT_ROUTE_ID.METHOD) {
				setValue('acceptPaymentMethod', false);
				trigger('acceptPaymentMethod');
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [urlId])

		return { methodForm: { formState: { isValid, isDirty }, control, getValues } }
	}

}