export const asset = {
  LOGO: 'https://crabi-assets.s3.us-east-2.amazonaws.com/logo.svg',
  LOGO_FULL:'https://crabi-assets.s3.us-east-2.amazonaws.com/logo-sl.png',
  
  HELPER: 'https://crabi-assets.s3.us-east-2.amazonaws.com/helper.png',
	INFO_IMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/utils/info.png',
	WARNING_IMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/utils/warning.png',

  PAYMENT_CARD_VISA_WHITE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/visa-logo-white.svg',
  PAYMENT_CARD_MASTERCARD: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/mastercard-logo.svg',

  PLAN_BG: 'https://crabi-assets.s3.us-east-2.amazonaws.com/plan-bg.svg',
  PLAN_QUOTER_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/quoter-car.png',
  COVERAGE_DEFAULT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-default-star.png',
  COVERAGE_AV: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-truck.png',
  COVERAGE_GM: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-ambulance.png',
  COVERAGE_DT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-damage.png',
  COVERAGE_AL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-law.png',
  COVERAGE_RT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-robber.png',
  COVERAGE_DM: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-accident.png',

  POLICY_SUMMARY_DATA: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/summary-data.png',
  POLICY_SUMMARY_CONTACT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/summary-contact.png',
  POLICY_VEHICLE_DATA: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/vehicle-default-picture.png',
	POLICY_VEHICLE_COVERAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/policy-car-coverages.png',
	POLICY_COMPLETION_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/policy-contracting-car.png',

	VIN_LOCATION_GUIDE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/vin-location.jpg',
  COMERCIAL_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/comercial-car-warning.png',
	POLICY_CAR_PICTURES: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/policy-car-pictures.svg'
}