import { Address } from "src/classes/person/Address"
import { AuthToken } from "src/classes/person/AuthToken"
import { Profile } from "src/classes/person/Profile"
import { User } from "src/classes/person/User"
import { Plan } from "src/classes/plan/Plan"
import { Policy } from "src/classes/policy/Policy"
import { Vehicle } from "src/classes/vehicle/Vehicle"
import { Util } from "src/utils/Util"
import { IAuthTokenData } from "../interfaces/IAuthTokenData"
import { ILoginRequest } from "../interfaces/ILoginRequest"
import { ILoginTemporaryRequest } from "../interfaces/ILoginTemporaryRequest"
import { IPolicyCreationVehicleRequest } from "../interfaces/IPolicyCreationVehicleRequest"
import { IPolicyRequest } from "../interfaces/IPolicyRequest"
import { IPolicyRequestData } from "../interfaces/IPolicyRequestData"
import { IRegisterData } from "../interfaces/IRegisterData"
import { IRegisterRequest } from "../interfaces/IRegisterRequest"
import { IUtmParams } from "../interfaces/IUtmParams"

export namespace QuoterAdapter {

  /**
   * 
   * @param data 
   * @param referral 
   * @param utms 
   * @returns 
   */
  export const registerUserRequestAdapter = (person: User, referral?: string, utms?: IUtmParams): IRegisterRequest => {
    let returnData: IRegisterRequest = {
      user: {
        email: person.email.toLocaleLowerCase(),
        password: person.password
      },
      role: 'POLICY',
      person: {
        first_name: person.name.trim(),
        first_last_name: person.lastName.trim(),
        second_last_name: person.secondLastName.trim(),
        email: person.email.toLocaleLowerCase(),
        phone: `${person.profile!.phone?.countryCode?.code}${person.profile!.phone?.number}`.replace('+', ''),
        gender_code: person.profile!.gender,
        civil_status_code: 0, //DEFAULT VALUE
        birthdate: Util.TRANSFORM.DATE.formatServerDate(person.profile!.birthdate!, '-'),
        rfc: person.profile!.rfc.toLocaleUpperCase(),
        address: {
          street: person.profile!.address!.street!,
          external_number: person.profile!.address!.externalReference,
          internal_number: person.profile!.address!.internalReference ? person.profile!.address!.internalReference : '',
          neighborhood: person.profile!.address!.neighborhood,
          postal_code: person.profile!.address!.postalCode,
          municipality_code: person.profile!.address!.municipalityCode,
          state_code: person.profile!.address!.stateCode,
          country_code: person.profile!.address!.countryCode,
          // lat: person.profile?.address?.latitude,
          // lng: person.profile?.address?.longitude
        },
        pld_data: {
          nationality_code: person.profile!.nationalityCode!,
          country_of_origin_code: person.profile!.originCountry!,
          state_of_origin_code: person.profile!.originCountryState!,
          economic_activity_code: person.profile!.economicActivity
        },
        referral_from: person.profile?.referral?.code || '',
        utm_campaign: utms && utms.utm_campaign ? utms.utm_campaign : '',
        utm_medium: utms && utms.utm_medium ? utms.utm_medium : '',
        utm_source: utms && utms.utm_source ? utms.utm_source : '',
        utm_term: utms && utms.utm_term ? utms.utm_term : '',
        utm_content: utms && utms.utm_content ? utms.utm_content : ''
      },
    }
    return returnData
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const registerUserDataAdapter = (data: IRegisterData): User => {
    let response = data.response;
    let user = new User(response.email);
    user.profile = new Profile();
    user.profile.address = new Address();

    user.name = response.first_name;
    user.lastName = response.first_last_name;
    user.secondLastName = response.second_last_name;
    user.id = response.id;
    user.profile.gender = response.gender_code;
    user.profile.rfc = response.rfc;
    user.profile.address.postalCode = response.address.postal_code;
    user.profile.address.street = response.address.street;
    user.profile.address.internalReference = response.address.internal_number;
    user.profile.address.externalReference = response.address.external_number;
    user.profile.address.neighborhood = response.address.neighborhood;
    user.profile.address.countryCode = +response.address.country_code;

    return user;
  }


  /**
   * 
   * @param user 
   * @returns 
   */
  export const loginRequestAdapter = (user: User): ILoginRequest => {
    return {
      email: user.email,
      password: user.password
    }
  }

  /**
   * 
   * @param os 
   * @param osVersion 
   * @param browser 
   * @param browserVersion 
   * @param deviceUUID 
   * @returns 
   */
  export const temporalLoginRequestAdapter = (os: string, osVersion: string, browser: string, browserVersion: string, deviceUUID: string): ILoginTemporaryRequest => {
    return {
      device: {
        brand: os,
        client_version: `${browser}-${browserVersion}`,
        device_id: deviceUUID,
        model: osVersion,
        os: os
      }
    }
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const authTokenDataAdapter = (data: IAuthTokenData): AuthToken => {
    let authToken: AuthToken = new AuthToken(data.access_token.token, data.refresh_token.token);

    return authToken;
  }

  /**
   * 
   * @param vehicle 
   * @returns 
   */
  export const vehicleCreationRequestAdapter = (vehicle: Vehicle): IPolicyCreationVehicleRequest => {
    const data: IPolicyCreationVehicleRequest = {
      vin: vehicle.vin.toLocaleUpperCase(),
      version_id: vehicle.version?.id as number,
      brand_id: vehicle.brand?.id as number,
      type_id: vehicle.model?.id as number,
      model: vehicle.year?.value as number,
      plates: vehicle.plate.toLocaleUpperCase(),
      color: vehicle.color
    }

    return data;
  }

  /**
   * 
   * @param vehicle 
   * @param plan 
   * @param driver 
   * @returns 
   */
  export const policyRequestAdapter = (vehicle: Vehicle, plan: Plan, driver?: User): IPolicyRequest => {
    let data: IPolicyRequest = {
      coupon: plan.discount ? plan.coupon : '',
      plan_id: plan.id,
      quotation_id: plan.quotation,
      vehicle: { vin: vehicle.vin.toLocaleUpperCase() }
    }

    if (driver) {
      data.driver = { id: driver.id }
    }

    return data;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const policyRequestDataAdapter = (data: IPolicyRequestData): Policy => {
    let _policy = new Policy('');

    for (const policy of data.policies) {
      if (policy.number.includes('AE')) {
        _policy.id = policy.id;
        _policy.policyNumber = policy.number;
      }
    };

    return _policy;
  }
}