/**
 * 
 */
 export const transform = {

  DATE: {
    formatDate: (date: Date): string => {
      let _value: string = '';
      if (date) {
        _value = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}/${date.getFullYear()}`;
      } else if (date === undefined || date === null) {
        _value = '--';
      }

      return _value;
    },
		formatYYYYMMDD: (date: Date, splitter: string): string => {
      let _value: string = '';

      if (date && date instanceof Date) {
        _value = `${date.getFullYear()}${splitter}`;
        _value = `${_value}${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}${splitter}`;
        _value = `${_value}${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
      } else {
        _value = '-N/A-';
      }

      return _value;
    },
    formatUTCDate: (date: Date): string => {
      let _value: string = '';
      if (date) {
        _value = `${date.getUTCDate() < 10 ? '0' : ''}${date.getUTCDate()}/${(date.getUTCMonth() + 1) < 10 ? '0' : ''}${(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`;
      } else if (date === null) {
        _value = '--';
      }

      return _value;
    },
		formatServerDate: (date: Date, splitter: string = '-'): string => {
			let _value: string = '';
			if (date) {
				_value = `${date.getFullYear()}${splitter}${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}${splitter}${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
			} else {
				_value = '--';
			}

			return _value;
		},
		getDateFromString: (date: string, splitter: string = '/'): Date => {
			const splitedDate = date.split(splitter);

			const year = parseInt(splitedDate[2]);
			const month = parseInt(splitedDate[1]);
			const day = parseInt(splitedDate[0]);

			return new Date(year, month - 1, day);
		},
		dateToStringWithTimezoneOffset: (date: Date): string => {
			const formattedDate = transform.DATE.formatYYYYMMDD(date, '-');
			const hours = `${date.getHours() < 10 ? '0' : ''}${date.getHours().toString()}`;
			const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes().toString()}`;
			const seconds = `${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds().toString()}`;
			let offsetNumber = date.getTimezoneOffset();

			const pad = (num: number, length: number) => {
				let str = '' + num;
				while (str.length < length)
					str = '0' + str;
				return str;
			}

			let offset = (offsetNumber < 0 ? '+' : '-') + pad(Math.abs(offsetNumber/60), 2) + pad(Math.abs(offsetNumber % 60), 2);

			const dateWithTimezone = `${formattedDate} ${hours}:${minutes}:${seconds} ${offset}`;
			return dateWithTimezone;
		},
    dateToElapsedYears: (date: string | Date, currentDate: Date = new Date()): number => {
			let elapsedYears = 0;
			if(date) {
				const diffTime = currentDate.getTime() - new Date(date).getTime();
				const millisecondsInYear = 365.25 * 24 * 60 * 60 * 1000;
				elapsedYears = diffTime / millisecondsInYear;
			}
			return elapsedYears;
		}
  },

  NUMBER: {
    formatNumberToString: (number: number): string => {
      let _value: string = '';

      if (number === 1000000) {
        _value = `${number.toString()[0]} millón`;
      } else if (number > 1000000) {
        _value = `${number.toString()[0]}${number.toString()[1] !== '0' ? `.${number.toString()[1]}` : ''} millones`;
      } else if (number < 1000000 && number > 1000) {
        _value = `${number / 1000} mil`;
      }
      return _value;
    },
  },
  CURRENCY: {
    MXN: (value: number): string => {
      const currencyFormater = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      return `${currencyFormater.format(value)}`;
    }
  },
  TEXT: {
    firstLetterUpperCase: (value: string): string => {
      const splitedValue: string[] = value.split(' ');
      let upperCaseValue: string = '';
      let index: number = 0;
      for (let part of splitedValue) {
        upperCaseValue = `${upperCaseValue}${part.charAt(0).toLocaleUpperCase()}${part.slice(1).toLowerCase()}${index < (splitedValue.length - 1) ? ' ' : ''}`;
        index++;
      }
      return upperCaseValue;
    },
		normalize: (value: string): string => {
			const normalizeForm = 'NFD';
			const marksRegex = /[\u0300-\u036f]/g;
			const normalizedValue = value.normalize(normalizeForm).replace(marksRegex, '');
			return normalizedValue;
		}
  },
  TIMER: {
    coutndownBySeconds: (seconds: number): string => {
      let countdown: string = '';
      if (seconds >= 0) {
        countdown = `${seconds / 60 < 10 ? '0' : ''}${Math.floor(seconds / 60)}:${(seconds % 60) < 10 ? '0' : ''}${seconds % 60}`;
      }
      return countdown;
    }
  }
}