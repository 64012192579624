import { RootState } from "src/store/store";

/**
 *
 */
export namespace QuoterSelector {

  export const policy = (state: RootState) => state.quoter?.policy;

  export const temporalAuth = (state: RootState) => state.quoter?.temporalAuth;
}