import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { IQuoterVehicleStateReducer } from "src/store/states/modules-state/IQuoterVehicleStateReducer";

/**
 *
 */
export namespace QuoterVehicleSlice {
	/**
	 * 
	 */
	const initialState: IQuoterVehicleStateReducer = {
		vehicle: undefined,
		brands: [],
		models: [],
		years: [],
		version: [],
		isLoadingBrands: false,
		isLoadingModels: false,
		isLoadingYears: false,
		isLoadingVersions: false
	};

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const clear: CaseReducer<IQuoterVehicleStateReducer> = (state) => {
		state.vehicle = undefined;
		state.brands = [];
		state.models = [];
		state.version = [];
		state.years = [];
		state.isLoadingBrands = false;
		state.isLoadingModels = false;
		state.isLoadingYears = false;
		state.isLoadingVersions = false
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setStoredVehicle: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<Vehicle>> = (state, action) => {
		state.vehicle = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const vehicleBrandsList: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<VehicleBrand[]>> = (state, action) => {
		state.brands = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const vehicleModelsList: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<VehicleModel[]>> = (state, action) => {
		state.models = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const vehicleYearsList: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<VehicleYear[]>> = (state, action) => {
		state.years = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const vehicleVersionsList: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<VehicleVersion[]>> = (state, action) => {
		state.version = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingBrands: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingBrands = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingModels: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingModels = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingYears: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingYears = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingVersion: CaseReducer<IQuoterVehicleStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingVersions = action.payload;
	}

	const reducers = {
		clear,
		setStoredVehicle,
		vehicleBrandsList,
		vehicleModelsList,
		vehicleYearsList,
		vehicleVersionsList,
		setLoadingBrands,
		setLoadingModels,
		setLoadingYears,
		setLoadingVersion
	};

	/**
	 * 
	 */
	const slice = createSlice({
		name: 'quoterVehicle',
		initialState,
		reducers: reducers,
	});

	export const { reducer, actions } = slice;
}