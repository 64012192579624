import { Util } from "src/utils/Util";
import { Profile } from "./Profile";

/**
 * 
 */
export class User {
  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
	/**
	 * 
	 */
  private _email: string;
  get email(): string { return this._email; }
  set email(_email: string) { this._email = _email; }
	/**
	 * 
	 */
  private _password: string;
  get password(): string { return this._password; }
  set password(_password: string) { this._password = _password; }
	/**
	 * 
	 */
  private _name: string;
  get name(): string { return this._name; }
  set name(_name: string) { this._name = _name; }
	/**
	 * 
	 */
  private _lastName: string;
  get lastName(): string { return this._lastName; }
  set lastName(_lastName: string) { this._lastName = _lastName; }
	/**
	 * 
	 */
  private _secondLastName: string;
  get secondLastName(): string { return this._secondLastName; }
  set secondLastName(_secondLastName: string) { this._secondLastName = _secondLastName; }
	/**
	 * 
	 */
  private _profile: Profile | null;
  get profile(): Profile | null { return this._profile; }
  set profile(_profile: Profile | null) { this._profile = _profile; }

  constructor (_email: string, _name?: string, _lastName?: string, _secondLastName?: string) {
    this._id = '';
    this._email = _email;
    this._name = _name? Util.TRANSFORM.TEXT.firstLetterUpperCase(_name) : '';
    this._lastName = _lastName? Util.TRANSFORM.TEXT.firstLetterUpperCase(_lastName) : '';
    this._secondLastName = _secondLastName? Util.TRANSFORM.TEXT.firstLetterUpperCase(_secondLastName) : '';
    this._password = '';
    this._profile = null;
  }
}