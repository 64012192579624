import toast from "react-hot-toast";

const ALERT_COLOR = {
  ERROR: {
    PRIMARY: '#F55B50',
    BACKGROUND: '#f9eded'
  },
  SUCCESS: {
    PRIMARY: '#1d9715',
    BACKGROUND: '#f5fdf7'
  },
  INFO: {
    PRIMARY: '#0D4EC9',
    BACKGROUND: '#f3f5ff'
  },
}

const DURATION_TIME_IN_MILISECONDS = 5000;

export namespace AlertFactory {

  /**
   * 
   * @param message 
   */
  export const errorAlert = (message: string) => {
    toast.error(message,
      {
        duration: DURATION_TIME_IN_MILISECONDS,
        position: 'top-right',
        iconTheme: {
          primary: ALERT_COLOR.ERROR.PRIMARY,
          secondary: '#fff'
        },
        style: {
          background: ALERT_COLOR.ERROR.BACKGROUND,
          color: ALERT_COLOR.ERROR.PRIMARY,
          border: `1px solid ${ALERT_COLOR.ERROR.PRIMARY}`,
          fontSize: '1rem',
          padding: '0.8rem'
        }
      }
    );
  }
  
  /**
   * 
   * @param message 
   */
  export const successAlert = (message: string) => {
    toast.success(message,
      {
        duration: DURATION_TIME_IN_MILISECONDS,
        position: 'top-right',
        iconTheme: {
          primary: ALERT_COLOR.SUCCESS.PRIMARY,
          secondary: '#fff'
        },
        style: {
          background: ALERT_COLOR.SUCCESS.BACKGROUND,
          color: ALERT_COLOR.SUCCESS.PRIMARY,
          border: `1px solid ${ALERT_COLOR.SUCCESS.PRIMARY}`,
          fontSize: '1rem',
          padding: '0.8rem'
        }
      }
    );
  }

  /**
   * 
   * @param message 
   */
  export const infoAlert = (message: string) => {
    toast.success(message,
      {
        duration: DURATION_TIME_IN_MILISECONDS,
        position: 'top-center',
        iconTheme: {
          primary: ALERT_COLOR.INFO.PRIMARY,
          secondary: '#fff',
        },
        style: {
          background: ALERT_COLOR.INFO.BACKGROUND,
          color: ALERT_COLOR.INFO.PRIMARY,
          border: `1px solid ${ALERT_COLOR.INFO.PRIMARY}`,
          fontSize: '1rem',
          padding: '0.8rem'
        }
      }
    );
  }
}