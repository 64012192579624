import { useState } from "react"
import { PaymentCost } from "src/classes/plan/PaymentCost"
import { Plan } from "src/classes/plan/Plan";

export namespace PlanCostFacade {

  /**
   * 
   */
  export const PLAN_COST = {
    ANNUAL: { id: 1, name: 'Pago de contado' },
    SUSCRIPTION: { id: 2, name: 'Pago mes a mes' },
  }


  export const usePlanCost = (currentPlan?: Plan) => {
    const [selectedPlanCost, setSelectedPlanCost] = useState<PaymentCost>();
    const [selectedDiscountPlanCost, setSelectedDiscountPlanCost] = useState<PaymentCost>();
    const [additionalCost, setAdditionalCost] = useState<PaymentCost>();
    const [currentCostId, setCurrentCostId] = useState<number>(PLAN_COST.ANNUAL.id);

    /**
     * 
     * @param id 
     */
    const selectCost = (id?: number): void => {
      if (currentPlan) {
        const costId = id !== undefined? id : currentCostId;

        if (id !== undefined) {
          setCurrentCostId(id);
        }
        
        if (costId === PLAN_COST.ANNUAL.id) {
          setSelectedPlanCost(currentPlan.cost);
          setAdditionalCost(undefined);
          if (currentPlan.discount) {
            setSelectedDiscountPlanCost(currentPlan.discount.cost);
          }

        } else if (costId === PLAN_COST.SUSCRIPTION.id) {
          setSelectedPlanCost(currentPlan.subscription.firstCost);

          if (!currentPlan.discount) {
            setAdditionalCost(currentPlan.subscription.cost);
          } else {
            setSelectedDiscountPlanCost(currentPlan.discount.subscription.firstCost);
            setAdditionalCost(currentPlan.discount.subscription.cost);
          }

        } else if (costId > PLAN_COST.SUSCRIPTION.id) {
          // id value === (index + 1) + PLAN COST ID
          setSelectedPlanCost(currentPlan.monthly.costs[(costId - 1) - PLAN_COST.SUSCRIPTION.id]);
          setAdditionalCost(undefined);

          if (currentPlan.discount) {
            setSelectedDiscountPlanCost(currentPlan.discount.monthly.costs[(costId - 1) - PLAN_COST.SUSCRIPTION.id]);
          }
        }

        // Clear discount cost
        if (!currentPlan.discount && selectedDiscountPlanCost) {
          setSelectedDiscountPlanCost(undefined);
        }
      }
    }

    return { selectedPlanCost, additionalCost, selectedDiscountPlanCost, selectCost }
  }
}