import { useSelector } from 'react-redux';
import { Button, Text } from 'src/components';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { QuoterSelector } from 'src/modules/quoter/controller/selectors/quoter.selector';
import { Util } from 'src/utils/Util';
import QuoterContent from '../../components/QuoterContent/QuoterContent';
import './QuoterCompletion.scss';
import { useEffect } from "react";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
const QuoterCompletionPage = (): JSX.Element => {

	/**
	 * 
	 */
	const { storedPerson } = QuoterFacade.useQuoterActors();
	const storedPolicy = useSelector(QuoterSelector.policy);

	/**
	 * 
	 */
	const redirectToCrabi = (): void => {
		window.location.href = Util.CONSTANT.CRABI_URL;
	}

	useEffect(
		() => {
			TrackingFactory.Tracker.track({
				event: TrackingFactory.EVENTS.susccessfullPayment
			});
		}, []
	)

  return (
    <div className="quoterCompletion">
			<QuoterContent
				title="Gracias Crabeler 💙"
			>
				<section className="quoterCompletion__textSection">
					<Text align="center" type="title">¡Tu pago ha sido realizado con éxito!</Text>
				</section>

				<section className="quoterCompletion__contentSection">
					<div className="quoterCompletion__contentSection__picture" style={{ backgroundImage: `url(${Util.ASSET.POLICY_COMPLETION_CAR})`}}></div>
					<Text align="center" type="title" color="main">N. Póliza: {storedPolicy?.policyNumber}</Text>
					<Text align="center" type="paragraph">En un momento recibirás en tu correo <b>{storedPerson?.email}</b> el detalle de tu póliza.</Text>
					<Text align="center" type="label" className="quoterCompletion__contentSection__limitTimeText">Recuerda que tu póliza estará activa a partir del día de mañana.</Text>
					<Button className="quoterCompletion__contentSection__button" onClick={redirectToCrabi}>Consultar póliza</Button>
				</section>
			</QuoterContent>

			<section className="quoterCompletion__mobileContentSection">
				<div className="quoterCompletion__mobileContentSection__picture" style={{ backgroundImage: `url(${Util.ASSET.POLICY_COMPLETION_CAR})`}}></div>
				<Text align="center" type="title" color="main">N. Póliza: {storedPolicy?.policyNumber}</Text>
				<Text align="center" type="paragraph">En un momento recibirás en tu correo <b>{storedPerson?.email}</b> el detalle de tu póliza.</Text>
				<Text align="center" type="label" className="quoterCompletion__mobileContentSection__limitTimeText">Recuerda que tu póliza estará activa a partir del día de mañana.</Text>
				<Button className="quoterCompletion__mobileContentSection__button" onClick={redirectToCrabi}>Consultar póliza</Button>
			</section>
    </div>
  );
};

export default QuoterCompletionPage;