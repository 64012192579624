import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PROFILE_ROUTE_ID, QUOTER_PATH, QUOTER_QUERY_PARAMS } from "src/routes/paths/quoter.paths";
import { QuoterVehicleAction } from "../actions/quoter-vehicle.action";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterVehicleFacade } from "./quoter-vehicle.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

export namespace QuoterPersonTypeFacade {

	type PersonType = {
		PERSONAL: {
			id: number,
			name: string,
		},
		THIRD_PARTY: {
			id: number,
			name: string,
		},
	};

	type PersonTypeSteps = {
		VEHICLE_USE_STEP: string,
		SALVAGE_STEP: string
	};

	export const TYPES: PersonType = {
		PERSONAL: {
			id: 0,
			name: 'personal'
		},
		THIRD_PARTY: {
			id: 1,
			name: 'third_party'
		}
	};

	export const STEPS: PersonTypeSteps = {
		VEHICLE_USE_STEP: 'vehicle_usage',
		SALVAGE_STEP: 'salvage'
	};

	/**
	 * 
	 */
	export const usePersonType = () => {

		const vehicle = useSelector(QuoterVehicleSelector.storedVehicle);
		const { fillVehicleData } = QuoterVehicleFacade.useFillVehicle();
		const [type, setType] = useState<string>();
		const [invalidType, setInvalidType] = useState<boolean>(false);
		const [currentStep, setCurrentStep] = useState<string>(STEPS.VEHICLE_USE_STEP);
		const [isSalvage, setIsSalvage] = useState<boolean>();
		const [showSalvageModal, setShowSalvageModal] = useState<boolean>(false);
		const { search } = QuoterParamsFacade.useQuoterParams();
		const navigate = useNavigate();
		const dispatch = useDispatch();
		const [searchParams] = useSearchParams();

		/**
		 * 
		 */
		const editVehicle = (): void => {
			navigate(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}`)
		}

		/**
		 * 
		 */
		const validateType = (): void => {

			if (type === TYPES.THIRD_PARTY.name) {

				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.personType.name,
					[TrackingFactory.EVENTS.personType.property]: 'true'
				});

				setInvalidType(true);
			} else {
				let newVehicle = fillVehicleData();
				newVehicle.isThirdParty = type === TYPES.THIRD_PARTY.name;

				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.personType.name,
					[TrackingFactory.EVENTS.personType.property]: 'false'
				});

				dispatch(QuoterVehicleAction.setStoredVehicle(newVehicle));
				if (searchParams.has(QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP))
					searchParams.delete(QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP);
				navigate(`${QUOTER_PATH.QUOTER_PERSON_TYPE_PATH}?${searchParams.toString()}&${QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP}=${STEPS.SALVAGE_STEP}`);
				//setCurrentStep(STEPS.SALVAGE_STEP);
				//navigate(`${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.COUNTRY}${search}`);
			}
		}

		/**
		 * 
		 */
		const validateSalvageValue = (): void => {
			if (isSalvage !== undefined) {
				if (isSalvage) {
					setShowSalvageModal(true);

					TrackingFactory.Tracker.track({
            event: TrackingFactory.EVENTS.vehicleRescue.name,
            [TrackingFactory.EVENTS.vehicleRescue.property]: 'true'
          });

				} else {

					TrackingFactory.Tracker.track({
            event: TrackingFactory.EVENTS.vehicleRescue.name,
            [TrackingFactory.EVENTS.vehicleRescue.property]: 'false'
          });

					nextPage();
				}
			}
		};

		/**
		 * 
		 */
		const salvageConfirmation = (): void => {
      TrackingFactory.Tracker.track({
        event: TrackingFactory.EVENTS.vehicleEnsurance
      });
		}

		/**
		 * 
		 */
		const onBackRoute = (): string => {
			if (searchParams.has(QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP))
				searchParams.delete(QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP);

			const _route = `${QUOTER_PATH.QUOTER_PERSON_TYPE_PATH}?${searchParams.toString()}&${QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP}=${STEPS.VEHICLE_USE_STEP}`;

			return _route
		}

		/**
		 * 
		 */
		const nextPage = (): void => {
			navigate(`${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.COUNTRY}${search}`);
		}

		/**
		 * 
		 */
		useEffect(() => {
			if (vehicle && vehicle.isThirdParty !== undefined && !type)
				setType(vehicle.isThirdParty === true ? TYPES.THIRD_PARTY.name : TYPES.PERSONAL.name);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [vehicle])

		/**
		 * 
		 */
		useEffect(() => {
			if (!searchParams)
				return;

			if (searchParams.get(QUOTER_QUERY_PARAMS.PERSON_TYPE_STEP) === STEPS.SALVAGE_STEP)
				setCurrentStep(STEPS.SALVAGE_STEP)
			else
				setCurrentStep(STEPS.VEHICLE_USE_STEP);
		}, [searchParams])

		return { TYPES, type, search, currentStep, isSalvage, showSalvageModal, setType, setCurrentStep, setIsSalvage, salvageConfirmation, nextPage, invalidType, vehicle, validateType, onBackRoute, validateSalvageValue, editVehicle, setShowSalvageModal }
	}
}