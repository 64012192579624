import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, StripeError } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { Policy } from "src/classes/policy/Policy";
import { Util } from "src/utils/Util";
import { QuoterPolicyAdapter } from "../adapters/quoter-policy.adapter";
import { QuoterPolicyService } from "../services/quoter-policy.service";

/**
 *
 */
export namespace QuoterPolicyFeature {

	/**
   * 
   */
	export const policyPaymentFeature = async (checkout: PlanCheckout, lib: { stripe: Stripe, elements: StripeElements }): Promise<Policy | undefined> => {
		try {
			if (checkout.isMonthly) {
				const stripeData = await lib.stripe.createPaymentMethod({type: 'card', card: lib.elements.getElement(CardElement)!, billing_details: { name: checkout.ownerName } });
				const requestData = QuoterPolicyAdapter.policyPaymentRequestDataAdapter(checkout, stripeData?.paymentMethod?.id!);
				const { data } = await QuoterPolicyService.policyMonthlyPaymentService(requestData);
				let newPolicy = new Policy(data.policy_number);
				return newPolicy;
			} else {
				const requestData = QuoterPolicyAdapter.policyPaymentRequestDataAdapter(checkout);
				const { data } = await QuoterPolicyService.policyPaymentService(requestData);
				const confirmation = await lib.stripe.confirmCardPayment(data.secret, { payment_method: { card: lib.elements.getElement(CardElement)!, billing_details: { name: checkout.ownerName } }, setup_future_usage: 'off_session' })
				if (confirmation.error) {
					throw confirmation.error;
				}
				let newPolicy = new Policy(data.policy_number);
				return newPolicy;
			}
		} catch (e) {
			console.error(e);
			let error = new Error();

			if ((e as StripeError).type) {
				const stripeError = e as StripeError;

				if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.CODE) {

					if (stripeError.decline_code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.DECLINE_CODE.INSUFFICIENT_FUNDS) {
						error.message = Util.CONSTANT.CARD_ERROR.FUNDS;
					} else {
						error.message = Util.CONSTANT.CARD_ERROR.DECLINE;
					}

				} else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.EXPIRED) {
					error.message = Util.CONSTANT.CARD_ERROR.EXPIRED;

				} else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.INCORRECT_CVC) {
					error.message = Util.CONSTANT.CARD_ERROR.CVC;

				} else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.PROCESSING) {
					error.message = Util.CONSTANT.CARD_ERROR.PROCESSING;

				} else {
					error.message = `(${stripeError.code}) Ha ocurrido un error con el pago.`
				}

			} else {
				const axiosError = e as AxiosError;

				error.message = `(${axiosError.response?.status}) Ha ocurrido un error.`;

				if(axiosError.response?.data.statusCode === 400){

					if(axiosError.response?.data.code === Util.STATUS_CODE.PAYMENT_ERROR_CODE.INVALID_CARD.CODE && axiosError.response?.data.decline_code === Util.STATUS_CODE.PAYMENT_ERROR_CODE.INVALID_CARD.DECLINE_CODE){
						error.message = Util.CONSTANT.CARD_ERROR.INVALID;
					}
					
				}else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.CODE) {

					if (axiosError.response?.data.decline_code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.DECLINE_CODE.INSUFFICIENT_FUNDS) {
						error.message = Util.CONSTANT.CARD_ERROR.FUNDS;
					} else {
						error.message = Util.CONSTANT.CARD_ERROR.DECLINE;
					}

				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.EXPIRED) {
					error.message = Util.CONSTANT.CARD_ERROR.EXPIRED;

				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.INCORRECT_CVC) {
					error.message = Util.CONSTANT.CARD_ERROR.CVC;

				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.PROCESSING) {
					error.message = Util.CONSTANT.CARD_ERROR.PROCESSING;

				} else {
					error.message = `(${axiosError.response?.data.code}) Ha ocurrido un error con el pago.`
				}
			}

			throw error;
		}
	}

	/**
	 * 
	 * @param policy 
	 * @param accept 
	 */
	export const automaticRenewalFeature = async (policy: Policy, accept: boolean): Promise<void> => {
		try {
			const requestData = QuoterPolicyAdapter.policyAutomaticRenewalRequestAdapter(policy, accept);
			await QuoterPolicyService.automaticRenewalService(requestData);
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}