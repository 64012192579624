import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { Address } from "src/classes/person/Address";
import { User } from 'src/classes/person/User';
import { QuoterPersonAction } from '../actions/quoter-person.action';
import { QuoterPersonSelector } from "../selectors/quoter-person.selector";
import { QuoterPersonSlice } from '../slices/quoter-person.slice';

/**
 *
 */
export namespace QuoterPersonSaga {

  /**
   * 
   */
  function* clearPerson() {
    yield put(QuoterPersonSlice.actions.clear());
  }

	/**
   * 
   */
	function* setPerson(action: PayloadAction<User>) {
		yield put(QuoterPersonSlice.actions.setUser(action.payload));
	}

	/**
   * 
   */
	function* setPersonAddress(action: PayloadAction<Address>) {
    const person: User = yield select(QuoterPersonSelector.storedPerson);
    if (person && person.profile && person.profile.address) {
      person.profile.address.externalReference = action.payload.externalReference;
      person.profile.address.internalReference = action.payload.internalReference;
      person.profile.address.street = action.payload.street;
      person.profile.address.neighborhood = action.payload.neighborhood;
      person.profile.address.latitude = action.payload.latitude;
      person.profile.address.longitude = action.payload.longitude;
      yield put(QuoterPersonSlice.actions.setUser(person));
    }
	}


	/**
	 * 
	 */
  export function* personDataSaga() {
    yield takeLatest(QuoterPersonAction.clear.type, clearPerson);
		yield takeLatest(QuoterPersonAction.setStoredPerson.type, setPerson);
		yield takeLatest(QuoterPersonAction.setPersonAddress.type, setPersonAddress);
  }
}