export class VehicleVersion {
	/**
	 * 
	 */
  private _id: number;
  get id(): number { return this._id; }
  set id(_id: number) { this._id = _id; }
	/**
	 * 
	 */
  private _description: string;
  get description(): string { return this._description; }
  set description(_description: string) { this._description = _description; }

  constructor(_id?: number, _description?: string) {
    this._id = _id? _id : 0;
    this._description = _description? _description : '';
  }
}