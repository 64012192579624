import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Util } from "src/utils/Util";

export namespace EnvironmentFactory {

  export const ENV_DOMAIN_NAMES = {
    JOURNEY: 'journey',
    PUBLIC_API: 'publicapi',
    DEFENDER_API: 'defender'
  }

  interface ProcessEnv extends NodeJS.ProcessEnv {
    REACT_APP_API_ENV: 'dev' | 'qa' | 'prod';
    REACT_APP_JOURNEY_API: string;
    REACT_APP_PUBLIC_API: string;
    REACT_APP_STRIPE_PUBLIC_KEY: string;
    REACT_APP_VALIDATE_EMAIL: string;
    REACT_APP_RELEASE_PICTURES_DATE: string;
  
    REACT_APP_OPENPAY_API_URL: string;
    REACT_APP_OPENPAY_API_KEY: string;
  
    REACT_APP_AMPLITUDE_KEY: string;
  
    REACT_APP_GOOGLE_MAPS: string;
    REACT_APP_GOOGLE_SPREADSHEET_CRABI_FORMS: string;
    REACT_APP_GOOGLE_QUOTER_SHEET_ID: string;
    REACT_APP_GOOGLE_CLIENT_EMAIL: string;
    REACT_APP_GOOGLE_SERVICE_KEY: string;
    REACT_APP_GOOGLE_SHEETS_API_KEY: string;
    REACT_APP_GOOGLE_SCRIPTS: string;
  
    REACT_APP_GTM: string;
  }

  /**
   * Singleton class that encapsulates the http request configuration.
   * Initialize secure and public http clients.
   * Base url references obtained from the .env file.
   */
  class HttpClient {

    /**
     * Current instance
     */
    private static _currentInstance: HttpClient | null;

    /**
     * Secure http client instance.
     */
    private _secureHttp: AxiosInstance;

    /**
     * Public http client instance.
     */
    private _publicHttp: AxiosInstance;
    /**
     * Public http client instance.
     */
    private _externalHttp: AxiosInstance;

    /**
     * Base url references (.env file).
     */
    private readonly ENV_DOMAIN = {
      JOURNEY: (process.env as ProcessEnv).REACT_APP_JOURNEY_API,
      PUBLIC_API: (process.env as ProcessEnv).REACT_APP_PUBLIC_API,
      DEFENDER_API: (process.env as ProcessEnv).REACT_APP_DEFENDER_API,
    }

    private constructor() {
      this._secureHttp = this.createClient();
      this._publicHttp = this.createClient();
      this._externalHttp = this.createClient();

    }

    /**
     * 
     * @returns 
     */
    public static instance(): HttpClient {
      if (this._currentInstance == null) {
        this._currentInstance = new HttpClient();
      }

      return this._currentInstance;
    }

    /**
     * 
     * @param domainName 
     */
    public SecureHttp(domainName?: string): AxiosInstance {
      this._secureHttp.defaults.baseURL = this.getClientDomain(domainName);
      return this._secureHttp;
    }

    /**
     * 
     * @param domainName 
     */
    public PublicHttp(domainName?: string): AxiosInstance {
      this._publicHttp.defaults.baseURL = this.getClientDomain(domainName);
      return this._publicHttp;
    }

    /**
  
     */
    public ExternalHttp(url: string): AxiosInstance {
      this._publicHttp.defaults.baseURL = url;
      return this._publicHttp;
    }

    /**
     * Returns the url of the domain based on domain name. Default url JOURNEY. 
     * @param domainName Domain name.
     * @returns Domains base url.
     */
    private getClientDomain(domainName?: string): string {
      let domainUrl: string = '';
      if (domainName === EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API) {
        domainUrl = this.ENV_DOMAIN.PUBLIC_API!;
      } else if (domainName === EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API) {
        domainUrl = this.ENV_DOMAIN.DEFENDER_API!;
      } else {
        domainUrl = this.ENV_DOMAIN.JOURNEY!;
      }

      return domainUrl;
    }

    /**
     * 
     * @returns 
     */
    private createClient(): AxiosInstance {
      return axios.create({
        timeout: 180000,
        headers: { 'Content-Type': 'application/json' }
      });
    }

  }

  /**
   * 
   * @param bearer 
   * @returns 
   */
   const getToken = (bearer?: boolean): string => {
    const token = document.cookie.match('(^|;)\\s*' + Util.KEY.TOKEN_STORAGE + '\\s*=\\s*([^;]+)')?.pop() || '';
    return token ? `${bearer? 'Bearer ' : ''}${token}` : '';
  }

  /**
   * 
   */
  export const initHttpClient = (): void => {
    const instance = HttpClient.instance();

    instance.SecureHttp().interceptors.request.use((config: AxiosRequestConfig<any>) => {
      if (config && config.headers) {
        const token = getToken(true);
        config.headers.Authorization = token;
      }

      return config;
    });

    instance.SecureHttp().interceptors.response.use(
      (response: AxiosResponse<any, any>) => response,
      (error: AxiosError) => {
        // if (error.response?.status === 401) {
        //   window.location.replace(SIGN_OUT_PATH.SIGN_OUT);
        //   AlertFactory.errorAlert('Tu sesión ha caducado.')
        // } 
        throw error;
      }
    );
  }

  export const Http = HttpClient.instance();
  export const ENV = process.env as ProcessEnv;
}