import { RootState } from "src/store/store";

/**
 *
 */
export namespace PlanSelector {

  export const policyPlans = (state: RootState) => state.plan?.plansList!;

  export const coverages = (state: RootState) => state.plan?.coverages!;

  export const isUpdatingPlan = (state: RootState) => state.plan?.isUpdating!;

  export const isCustomizingPlan = (state: RootState) => state.plan?.isCustomizing!;

  export const isLoadingPlan = (state: RootState) => state.plan?.isLoading!;

  export const currentCoupon = (state: RootState) => state.plan?.coupon!;

  export const currentUser = (state: RootState) => state.plan?.user!;

  export const currentVehicle = (state: RootState) => state.plan?.vehicle!;

  export const isRenewal = (state: RootState) => state.plan?.isRenewal;

  export const currentPlan = (state: RootState) => state.plan?.currentPlan!;

  export const currentRecomendedPlan = (state: RootState) => state.plan?.currentRecomendedPlan!;

  export const currentPlanConfig = (state: RootState) => state.plan?.planConfig!;  
}