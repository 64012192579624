import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Policy } from "src/classes/policy/Policy";
import { IQuoterStateReducer } from "src/store/states/modules-state/IQuoterStateReducer";

/**
 *
 */
export namespace QuoterSlice {
	  /**
   * 
   */
    const initialState: IQuoterStateReducer = {
      policy: undefined,
      temporalAuth: false,
    };
  
    /**
     * 
     * @param state 
     * @param action 
     */
    const clear: CaseReducer<IQuoterStateReducer> = (state) => {
      state.policy = undefined;
      state.temporalAuth = false;
    }
  
    /**
     * 
     * @param state 
     * @param action 
     */
    const policy: CaseReducer<IQuoterStateReducer, PayloadAction<Policy>> = (state, action) => {
      state.policy = action.payload;
    }
  
    /**
     * 
     * @param state 
     * @param action 
     */
    const temporalAuth: CaseReducer<IQuoterStateReducer, PayloadAction<boolean>> = (state, action) => {
      state.temporalAuth = action.payload;
    }
  
    const reducers = {
      clear,
      policy,
      temporalAuth
    };
  
    /**
     * 
     */
    const slice = createSlice({
      name: 'quoter',
      initialState,
      reducers: reducers,
    });
  
    export const { reducer, actions } = slice;
}