import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Dropdown, FormError, InputPhone, Modal, Text } from 'src/components';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { Input } from 'src/components/molecules/Input/Input';
import { InputDate } from 'src/components/molecules/InputDate/InputDate';
import { DebounceFactory } from 'src/factories/debounce.factory';
import { QuoterEmailFormFacade } from 'src/modules/quoter/controller/facades/quoter-email-form.facade';
import { QuoterEmailFacade } from 'src/modules/quoter/controller/facades/quoter-email.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { QUOTER_PATH } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterEmail.scss';

/**
 *
 */
const QuoterEmailPage = (): JSX.Element => {
	const { storedPerson } = QuoterFacade.useQuoterActors();
	const { formData, EMAIL_ERRORS, PHONE_ERRORS, CIVIL_STATUS, DATE_ERRORS } = QuoterEmailFormFacade.useQuoterEmailForm(storedPerson);
	const { isValidatingEmail, search, showExistingEmailModal, previousValidation, setShowExistingEmailModal, setFormValues, validateEmail, redirectToCrabi, onNavigate, validateDate } = QuoterEmailFacade.useQuoterEmail();
	const [emailValue, setEmailValue] = useState<string>();
	const debouncedEmail = DebounceFactory.useDebounce<string | undefined>(emailValue ? emailValue : undefined, 200);

	/**
	 * 
	 */
	const checkEmail = () => {
		const errors = formData.formState.errors;
		if (errors.email?.type === 'invalidEmail')
			setShowExistingEmailModal(true);
	}

	/**
	 * 
	 * @param value 
	 */
	useEffect(() => {
		if (debouncedEmail !== undefined) {
			formData.setValue('email', debouncedEmail, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
			formData.trigger('email');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedEmail])

	/**
	 * Use effect for fixing issues with debounce and useForm, triggering it for recognizing errors.
	 */
	useEffect(() => {
		if (previousValidation !== null)
			formData.trigger('email');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previousValidation])

	return (
		<div className="quoterEmail">
			<QuoterContent
				backButton={<BackButton to={`${QUOTER_PATH.QUOTER_PERSON_PATH}${search}`} />}
				title="Regístrate para que puedas regresar"
				subtitle="a tu cotización cuando quieras 😉"
			>
				<form className="quoterEmail__form">

					<div className="quoterEmail__form__inputWrapper">
						<Controller
							name="email"
							control={formData.control}
							rules={{
								required: true,
								pattern: Util.PATTERN.EMAIL,
								validate: { invalidEmail: async (email) => await validateEmail(email) }
							}}
							render={() => (
								<Input
									label={'CORREO ELECTRÓNICO'}
									required
									defaultValue={storedPerson ? storedPerson.email : ''}
									autoComplete="off"
									type="text"
									loading={isValidatingEmail}
									maxLength={100}
									errors={!!formData.formState.errors.email}
									didBlur={checkEmail}
									onChange={(e) => {
										setEmailValue(e.target.value);
									}}
								/>
							)}
						/>

						<FormError schema={EMAIL_ERRORS} type={formData.formState.errors.email?.type} />
					</div>

					<div className="quoterEmail__form__inputWrapper">
						<Controller
							name="phone"
							control={formData.control}
							rules={{ required: true, pattern: Util.PATTERN.NUMBERS, minLength: 10 }}
							render={({ field }) => (
								<InputPhone
									required
									defaultValue={storedPerson && storedPerson.profile && storedPerson.profile.phone ? storedPerson.profile.phone.number : ''}
									autoComplete="off"
									errors={!!formData.formState.errors.phone}
									onCountryCodeSelected={(code: string) => formData.setValue('countryCode', code)}
									onChange={(e) => { field.onChange(e.target.value) }}
								/>
							)}
						/>

						<FormError schema={PHONE_ERRORS} type={formData.formState.errors.phone?.type} />
					</div>

					<div className="quoterEmail__form__inputWrapper quoterEmail__form__inputWrapper--space-bottom">
						<Controller
							name="civilStatus"
							control={formData.control}
							rules={{ required: true }}
							render={({ field }) => (
								<Dropdown
									cancelSearch
									placeholder="ESTADO CIVIL"
									defaultValue={formData.getValues('civilStatus') ? CIVIL_STATUS.find(c => c.id === formData.getValues('civilStatus')) : undefined}
									items={
										CIVIL_STATUS.map(
											(c) => { return { id: c.id, value: c.value } }
										)
									}
									onChange={(item) => { field.onChange(item?.id) }}
								/>
							)}
						/>
					</div>

					<div className="quoterEmail__form__inputWrapper">
						<Controller
							name="birthdate"
							control={formData.control}
							rules={{ 
								required: true , 
								validate: { invalidDate: (date) => validateDate(date) }
							}}
							render={({ field}) => (
								<InputDate
									errors= {!!formData.formState.errors.birthdate?.type}
									position="top"
									validateConsent={true}
									defaultValue={formData.getValues('birthdate')}
									autoComplete="off"
									onSelectDate={(d) => field.onChange(d)}
								>
									FECHA DE NACIMIENTO
								</InputDate>
							)}
						/>
						<FormError schema={ DATE_ERRORS } type={formData.formState.errors.birthdate?.type} />
					</div>
				</form>
				<Button className="quoterEmail__button"
					disabled={!formData.formState.isValid}
					onClick={() => setFormValues(formData.getValues()).then(() => onNavigate(formData.getValues()))}
				>
					Siguiente
				</Button>

			</QuoterContent>

			<Modal show={showExistingEmailModal} position="center" backgroundDismiss onClose={() => setShowExistingEmailModal(false)}>
				<div className="quoterEmail__existingEmailModal">
					<div className="quoterEmail__existingEmailModal__textWrapper">
						<Text type="title" align="center">😥</Text>

						<Text className="quoterEmail__existingEmailModal__textWrapper__title" type="title" align="center">
							¡Houston tenemos <br />un problema 👨‍🚀!
						</Text>

						<Text type="paragraph" align="center" className="quoterEmail__existingEmailModal__textWrapper__paragraph" bold>Este correo ya está registrado</Text>
						<Text type="paragraph" align="center">¿Deseas iniciar sesión o utilizar otro correo?</Text>
					</div>

					<div className="quoterEmail__existingEmailModal__buttonWrapper">
						<Button onClick={() => redirectToCrabi()}>Ir a inicio de sesión</Button>
						<button
							className="quoterEmail__existingEmailModal__buttonWrapper__closeButton"
							type="button"
							onClick={() => { setShowExistingEmailModal(false) }}
						>
							<Text type="label" color="main" bold block>
								Intentar con otro correo
							</Text>
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default QuoterEmailPage;