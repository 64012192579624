import { PayloadAction } from '@reduxjs/toolkit';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { VehicleBrand } from 'src/classes/vehicle/VehicleBrand';
import { VehicleModel } from 'src/classes/vehicle/VehicleModel';
import { VehicleVersion } from 'src/classes/vehicle/VehicleVersion';
import { VehicleYear } from 'src/classes/vehicle/VehicleYear';
import { QuoterVehicleAction } from '../actions/quoter-vehicle.action';
import { QuoterVehicleFeature } from '../features/quoter-vehicle.feature';
import { QuoterVehicleSlice } from '../slices/quoter-vehicle.slice';

/**
 *
 */
export namespace QuoterVehicleSaga {
	
	const TRIES = 10;
	const DELAY = 1000;
  type Brands = SagaReturnType<typeof QuoterVehicleFeature.vehicleBrandsFeature>;
  type Models = SagaReturnType<typeof QuoterVehicleFeature.vehicleModelsFeature>;
  type Years = SagaReturnType<typeof QuoterVehicleFeature.vehicleYearsFeature>;
  type Versions = SagaReturnType<typeof QuoterVehicleFeature.vehicleVersionsFeature>;

	/**
	 * 
	 */
	function* setStoredVehicle(action: PayloadAction<Vehicle>) {
		yield put(QuoterVehicleSlice.actions.setStoredVehicle(action.payload));
	}

  /**
   * 
   */
  function* getVehicleBrands() {
    try {
			yield put(QuoterVehicleSlice.actions.setLoadingBrands(true));
      const brands: Brands = yield retry(TRIES, DELAY, QuoterVehicleFeature.vehicleBrandsFeature);
      yield put(QuoterVehicleSlice.actions.vehicleBrandsList(brands as VehicleBrand[]));
			yield put(QuoterVehicleSlice.actions.setLoadingBrands(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleModels(action: PayloadAction<VehicleBrand>): any {
    try {
			yield put(QuoterVehicleSlice.actions.setLoadingModels(true));
      const models: Models = yield retry(TRIES, DELAY, QuoterVehicleFeature.vehicleModelsFeature, action.payload);
      yield put(QuoterVehicleSlice.actions.vehicleModelsList(models as VehicleModel[]));
			yield put(QuoterVehicleSlice.actions.setLoadingModels(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleYears(action: PayloadAction<VehicleModel>) {
    try {
			yield put(QuoterVehicleSlice.actions.setLoadingYears(true));
      const years: Years = yield retry(TRIES, DELAY, QuoterVehicleFeature.vehicleYearsFeature, action.payload);
      yield put(QuoterVehicleSlice.actions.vehicleYearsList(years as VehicleYear[]));
			yield put(QuoterVehicleSlice.actions.setLoadingYears(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleVersions(action: PayloadAction<VehicleYear>): any {
    try {
			yield put(QuoterVehicleSlice.actions.setLoadingVersion(true));
      const versions: Versions = yield retry(TRIES, DELAY, QuoterVehicleFeature.vehicleVersionsFeature, action.payload);
      yield put(QuoterVehicleSlice.actions.vehicleVersionsList(versions as VehicleVersion[]));
			yield put(QuoterVehicleSlice.actions.setLoadingVersion(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* onItemSelected(action: PayloadAction<string>) {
    if (action.payload === 'brand') {
      yield put(QuoterVehicleSlice.actions.vehicleVersionsList([]));
      yield put(QuoterVehicleSlice.actions.vehicleModelsList([]));
      yield put(QuoterVehicleSlice.actions.vehicleYearsList([]));
    } else if (action.payload === 'model') {
      yield put(QuoterVehicleSlice.actions.vehicleYearsList([]));
      yield put(QuoterVehicleSlice.actions.vehicleVersionsList([]));
    } else if (action.payload === 'year') {
      yield put(QuoterVehicleSlice.actions.vehicleVersionsList([]));
    }
  }

  /**
   * 
   */
  function* clearManagement() {
    yield put(QuoterVehicleSlice.actions.clear());
  }

  export function* vehicleDataSaga() {
		yield takeLatest(QuoterVehicleAction.setStoredVehicle.type, setStoredVehicle);
    yield takeLatest(QuoterVehicleAction.getBrands.type, getVehicleBrands);
    yield takeLatest(QuoterVehicleAction.getModels.type, getVehicleModels);
    yield takeLatest(QuoterVehicleAction.getYears.type, getVehicleYears);
    yield takeLatest(QuoterVehicleAction.getVersion.type, getVehicleVersions);
    yield takeLatest(QuoterVehicleAction.onItemSelectedFormList.type, onItemSelected);
    yield takeLatest(QuoterVehicleAction.clear.type, clearManagement);
  }
}