//import { privateRoutes } from './private-routes';
import { publicRoutes } from './public-routes';

export namespace AppRoutes {

  /**
   * 
   * @param isValidAuth 
   * @returns 
   */
  export const routes = () => [

    ...publicRoutes()
  ];

}

