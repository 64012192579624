import { Spinner } from "src/components";
import './Button.scss';

/**
 *
 */
interface IButton {
  /**
   * 
   */
  fill?: 'outline' | 'none';
  /**
   * 
   */
  loading?: boolean;
  /**
   * 
   */
  icon?: JSX.Element;
  /**
   * 
   */
  iconposition?: 'left' | 'right';
  /**
   * 
   */
  color?: 'red' | 'green';
}

/**
 *
 */
export const Button = (props: IButton & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  const {
    className = props.className ? ` ${props.className}` : '',
    fill = props.fill? props.fill : 'filled',
		icon,
    iconposition = 'right',
    loading,
    color = props.color ? props.color : 'main',
    ...rest
  } = props;

  return (
    <button {...rest}
      className={`btn btn--fill-${fill}--color-${color}${loading? ' btn--loading' : ''}${loading ? ' btn--hide' : ''} ${className}`}
    >
      <span className={`btn__content`}>
        {
          icon && iconposition === 'left' &&
          <span className="btn__content__icon btn__content__icon--left">
            {icon}
          </span>
        }

        {props.children}

        {
          icon && iconposition === 'right' &&
          <span className="btn__content__icon btn__content__icon--right">
            {icon}
          </span>
        }
      </span>
      {
        loading && <Spinner />
      }
    </button>
  );
};