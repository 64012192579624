import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { QuoterVehicleAdapter } from "../adapters/quoter-vehicle.adapter";
import { QuoterVehicleService } from "../services/quoter-vehicle.service";

/**
 *
 */
export namespace QuoterVehicleFeature {

  /**
   * 
   */
  export const vehicleBrandsFeature = async (): Promise<VehicleBrand[]  | undefined> => {
    try {
      const { data } = await QuoterVehicleService.vehicleBrandsService();
      const brands: VehicleBrand[] = QuoterVehicleAdapter.brandsDataAdapter(data);
    	return brands;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
  export const vehicleModelsFeature = async (brand: VehicleBrand): Promise<VehicleModel[] | undefined> => {
    try {
      const { data } = await QuoterVehicleService.vehicleModelsService(brand.id.toString());
      const models: VehicleModel[] = QuoterVehicleAdapter.modelsDataAdapter(data);
      return models;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
   export const vehicleYearsFeature = async (model: VehicleModel): Promise<VehicleYear[] | undefined> => {
    try {
      const { data } = await QuoterVehicleService.vehicleYearsService({brand: model.brand.toString(), model: model.id.toString()});
      const vehicleYears: VehicleYear[] = QuoterVehicleAdapter.vehicleYearsDataAdapter(data);
      return vehicleYears;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
   export const vehicleVersionsFeature = async (year: VehicleYear): Promise<VehicleVersion[] | undefined> => {
    try {
      const { data } = await QuoterVehicleService.vehicleVersionsService({brand: year.brand.toString(), model: year.model.toString(), year: year.value.toString()});
      const vehicleVersions: VehicleVersion[] = QuoterVehicleAdapter.vehicleVersionsDataAdapter(data);
      return vehicleVersions;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

	/**
   * 
   * @param vin 
   * @returns 
   */
	export const validateVinFeature = async (vin: string): Promise<boolean> => {
		try {
			const { data } = await QuoterVehicleService.validateVinService(vin);

			return !data.exists ? true : false;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}