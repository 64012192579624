import { EnvironmentFactory } from './EnvironmentFactory/environment.factory';

/**
 * 
 */
export namespace GooglesheetsFactory {

	export interface ISpreadsheet {
		date?: string,
		email?: string,
		first_name?: string,
		last_name?: string,
		maiden_name?: string,
		birthdate?: string,
		phone?: string,
		postalcode?: string,
		township?: string,
		street?: string,
		brand?: string,
		model?: string,
		year?: string,
		version?: string,
		antiquity?: number,
		range?: string,
		channel?: string
	}

	const SCRIPT_MODES = {
		VEHICLE: 'vehicle',
		PERSON: 'person',
		PROFILE: 'profile',
		ADDRESS: 'address',
		CHANNEL: 'channel',
		COMPLETION: 'completion'
	}
	const spreadsheetID: string = EnvironmentFactory.ENV.REACT_APP_GOOGLE_SPREADSHEET_CRABI_FORMS!;
	const sheetsApiKey: string = EnvironmentFactory.ENV.REACT_APP_GOOGLE_SHEETS_API_KEY!;
	const sheetsScriptID: string = EnvironmentFactory.ENV.REACT_APP_GOOGLE_SCRIPTS!;
	const sheetName: string = 'Cotizador%20(Ingeniería)';

	/**
	 * 
	 * @returns 
	 */
	export const getLastRow = async(): Promise<number> => {
		const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetID}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE&key=${sheetsApiKey}`;
		const row = await fetch(url)
			.then(data => data.json())
			.then(data => data.values.length)
		return row;
	};

	/**
	 * 
	 */
	export const appScriptsCall = async(body: string) => {
		const url = `https://script.google.com/macros/s/${sheetsScriptID}/exec`;

		await fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			body: body,
			headers: {
        'Content-Type': 'application/json',
      },
		});
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 * @returns 
	 */
	export const insertVehicleData = async(spreadsheetData: ISpreadsheet, currentRow?: number): Promise<number> => {
		const row = await getLastRow() + 1;
		const body = JSON.stringify({
			row: row,
			mode: SCRIPT_MODES.VEHICLE,
			...spreadsheetData
		});

		await appScriptsCall(body);

		return row;
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 */
	export const insertPersonNames = async(spreadsheetData: ISpreadsheet, currentRow: number): Promise<any> => {
		const body = JSON.stringify({
			row: currentRow,
			mode: SCRIPT_MODES.PERSON,
			...spreadsheetData
		});
		
		await appScriptsCall(body);
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 */
	export const insertProfile = async(spreadsheetData: ISpreadsheet, currentRow: number): Promise<any> => {
		const body = JSON.stringify({
			row: currentRow,
			mode: SCRIPT_MODES.PROFILE,
			...spreadsheetData
		});

		await appScriptsCall(body);
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 */
	export const insertAddress = async(spreadsheetData: ISpreadsheet, currentRow: number): Promise<any> => {
		const body = JSON.stringify({
			row: currentRow,
			mode: SCRIPT_MODES.ADDRESS,
			...spreadsheetData
		});

		await appScriptsCall(body);
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 */
	export const insertChannel = async(spreadsheetData: ISpreadsheet, currentRow: number): Promise<any> => {
		const body = JSON.stringify({
			row: currentRow,
			mode: SCRIPT_MODES.CHANNEL,
			...spreadsheetData
		})

		await appScriptsCall(body);
	}

	/**
	 * 
	 * @param spreadsheetData 
	 * @param currentRow 
	 */
	export const completeFlow = async(spreadsheetData: ISpreadsheet, currentRow: number): Promise<any> => {
		const body = JSON.stringify({
			row: currentRow,
			mode: SCRIPT_MODES.COMPLETION,
			...spreadsheetData
		});

		await appScriptsCall(body);
	}

}