import { createRef, useEffect, useState } from 'react';
import { Coverage } from 'src/classes/plan/Coverage';
import { CoverageOption } from 'src/classes/plan/CoverageOption';
import { Text } from "src/components";
import { IconFactory } from "src/factories/icon.factory";
import { PlanFacade } from "src/modules/quoter/controller/facades/plan.facade";
import './PlanCoverageOption.scss';

/**
 *
 */
interface IPlanCoverageOption {
  /**
   * 
   */
  coverage: Coverage;
  /**
   * 
   */
  selectedOption?: CoverageOption;
  /**
   * 
   */
  onSelect: (option: CoverageOption) => void;
}

/**
 *
 */
const PlanCoverageOption = (props: IPlanCoverageOption): JSX.Element => {
  const { isUpdating } = PlanFacade.usePlanSync();
  const [showList, setShowList] = useState<boolean>(false);
  const [currentValueText, setCurrentValueText] = useState<string>('');
  const wrapperRef = createRef<HTMLDivElement>();

  /**
   * 
   * @param option 
   */
  const onSelectOption = (option: CoverageOption): void => {
    if (option !== props.selectedOption) {
      props.onSelect(option);
    }
    setShowList(false);
  }

  /**
   * 
   */
  useEffect(() => {
    const closeList = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowList(false);
      }
    }

    document.addEventListener("mousedown", closeList);
    return () => {
      document.removeEventListener("mousedown", closeList);
    };
  }, [wrapperRef]);

  /**
   * 
   */
  useEffect(
    () => {
      if (props.selectedOption)
        setCurrentValueText(props.coverage.isDeductible ? ` Deducible de ${props.selectedOption.getDeductibleValue()}` : `Suma asegurada de ${props.selectedOption.getAssuredValue()}`);
    }, [props.selectedOption, props.coverage]
  )

  return (
    <>

      <div className="coverageOption" ref={wrapperRef}>
        <div className={`coverageOption__selected ${showList ? 'coverageOption__selected--open' : ''}`} disabled-options={isUpdating || !props.coverage.isEnabled ? '' : undefined}
          onClick={(e) => { e.preventDefault(); setShowList(!showList) }}
        >
          <Text type="label" className="coverageOption__selected__value">
            {
              props.coverage.isEnabled ?
                <>
                  {
                    props.coverage.isDeductible ?
                      <>
                        Deducible de {props.selectedOption?.getDeductibleValue()}
                      </>
                      :
                      <>
                        Suma asegurada de {props.selectedOption?.getAssuredValue()}
                      </>
                  }

                </>
                :
                <>
                  {currentValueText}
                </>
              }
          </Text>
          {IconFactory.editIcon()}
        </div>

        {
          showList &&
          <ul className="coverageOption__list">
            {
              props.coverage.customitationOptions.map(
                (option, index) =>
                  <li className="coverageOption__list__item" key={`option-${index}`} onClick={(e) => { e.preventDefault(); onSelectOption(option) }}>
                    <Text type="label" className="coverageOption__list__item__value">
                      {
                        props.coverage.isDeductible ?
                          <>
                            Deducible de <b>{option.getDeductibleValue()}</b>
                          </>
                          :
                          <>
                            Suma asegurada de <b>{option.getAssuredValue()}</b>
                          </>
                      }
                    </Text>
                  </li>
              )
            }
          </ul>
        }
      </div>

    </>
  );
};

export default PlanCoverageOption;