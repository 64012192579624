import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factories/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IAutomaticRenewalRequest } from "../interfaces/IAutomaticRenewalRequest";
import { IPolicyPaymentData } from "../interfaces/IPolicyPaymentData";
import { IPolicyPaymentRequest } from "../interfaces/IPolicyPaymentRequest";

/**
 *
 */
export namespace QuoterPolicyService {

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const policyMonthlyPaymentService = (data: IPolicyPaymentRequest): AxiosPromise<IPolicyPaymentData> => {
		return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_MONTHLY_PAYMENT, data);
	}

	/**
   * 
   * @param data 
   * @returns 
   */
	export const policyPaymentService = (data: IPolicyPaymentRequest): AxiosPromise<IPolicyPaymentData> => {
		return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_PAYMENT, data);
	}

	/**
   * 
   * @returns 
   */
	export const automaticRenewalService = (data: IAutomaticRenewalRequest): AxiosPromise<void> => {
		return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).post(Util.API_PATH.POLICY_AUTOMATIC_RENEWAL, data);
	}
}