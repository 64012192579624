const BASE_URL: string = '';


export const QUOTER_QUERY_PARAMS = {

	VEHICLE_BRAND: 'marca',

	VEHICLE_MODEL: 'modelo',

	VEHICLE_YEAR: 'anualidad',

	VEHICLE_VERSION: 'version',

	USER_EMAIL: 'correo',

	USER_PHONE_CODE: 'codigo',

	USER_PHONE_NUMBER: 'telefono',

	USER_BIRTHDATE: 'fechaNacimiento',

	USER_NAME: 'nombre',

	USER_LASTNAME: 'apellidoPaterno',

	USER_GENDER: 'genero',

	USER_CIVIL_STATUS: 'estadoCivil',

	USER_SECOND_LASTNAME: 'apellidoMaterno',

	USER_POSTAL_CODE: 'cp',

	USER_STATE: 'estado',

	USER_LATITUDE: 'lat',

	USER_LONGITUDE: 'lng',
	
	USER_NEIGHBORHOOD: 'colonia',

	USER_STREET: 'calle',

	USER_EXTERNAL_REFERENCE: 'numeroExterior',

	REFERRAL: 'referral',

	MEDIATOR_SRC: 'src',

	PLAN_TYPE: 'planType',

	PERSON_TYPE_STEP: 'personTypeStep'
}

/**
 * 
 */
export const VEHICLE_ROUTE_ID = {

	BRAND: 'marca',

	MODEL: 'modelo',

	YEAR: 'anualidad',

	VERSION: 'version',

	PLATE: 'placas',

	VIN: 'vin'
}

/**
 * 
 */
export const PROFILE_ROUTE_ID = {

	COUNTRY: 'pais-nacimiento',

	NATIONALITY: 'nacionalidad',

	STATE: 'estado',

	ECONOMIC_ACTIVITIES: 'actividad-economica',

	ECONOMIC_OWN_BUSINESS: 'sector-economico',

	RFC: 'rfc'
}

/**
 * 
 */
export const PAYMENT_ROUTE_ID = {

	METHOD: 'metodo',

	DATA: 'datos'
}

/**
 * 
 */
export const QUOTER_PATH = {

	QUOTER: BASE_URL,

	QUOTER_PLAN_PATH: `${BASE_URL}/plan`,

	QUOTER_VEHICLE_PATH: `${BASE_URL}/auto`,

	QUOTER_PERSON_PATH: `${BASE_URL}/nombres`,

	QUOTER_EMAIL_PATH: `${BASE_URL}/correo`,

	QUOTER_LOCATION_PATH: `${BASE_URL}/ubicacion`,

	QUOTER_VEHICLE_ID_PATH: `${BASE_URL}/identificacion-auto`,

	QUOTER_PERSON_PROFILE_PATH: `${BASE_URL}/perfil`,

	QUOTER_PERSON_ACCOUNT_PATH: `${BASE_URL}/credenciales`,

	QUOTER_PERSON_GENDER_PATH: `${BASE_URL}/genero`,

	QUOTER_PROFILE_PATH: `${BASE_URL}/perfil`,

	QUOTER_PLAN_REVIEW: `${BASE_URL}/revision`,

	QUOTER_PAYMENT_PATH: `${BASE_URL}/pago`,

	QUOTER_PERSON_TYPE_PATH: `${BASE_URL}/uso-del-auto`,

	QUOTER_COMPLETION_PATH: `${BASE_URL}/finalizado`,
}