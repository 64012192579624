/**
 * 
 */
export const key = {

	TOKEN_STORAGE: 'auth_token',

	REGISTER_UTMS: {
		UTM_SOURCE: 'utm_source',
		UTM_MEDIUM: 'utm_medium',
		UTM_CAMPAIGN: 'utm_campaign',
		UTM_TERM: 'utm_term',
		UTM_CONTENT: 'utm_content',
	},

	SELECT_CHANNEL: 'channel_selected',

	EXTERNAL_SRC: {
		RTR: 'RTR',
	}
}