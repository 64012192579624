import classNames from "classnames";
import React, { useState, useId, useEffect } from "react";
import { Spinner, Text } from "src/components";
import InputMask from 'react-input-mask';

import './Input.scss';
import { IconFactory } from "src/factories/icon.factory";

/**
 *
 */
interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {

	label: string;

  errors?: boolean;

	value?: string;

  required?: boolean;

  icon?: JSX.Element;

  number?: boolean;

  loading?: boolean;
	
  mask?: string;

	uppercase?: boolean;

	noBorder?: boolean;

	noAnimation?: boolean;

	didFocus?: () => void;

	didBlur?: () => void;
}

/**
 *
 */
export const Input = (props: IInput): JSX.Element => {

	// Deconstruct our props
	const {
		label,
		errors = false,
		className,
		children,
		type,
		number,
		loading,
		mask,
		uppercase,
		noBorder,
		noAnimation,
		defaultValue,
		onChange,
		didFocus,
		didBlur,
		...rest
	} = props;
	const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
	const [onFocus, setOnFocus] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const inputId = useId();

	/**
	 * 
	 */
	const onTogleVisibility = () => {
		setPasswordVisibility(!passwordVisibility);
	}

	/** 
	 * 
	 */
	const checkAllowedNumberChars = (e: React.KeyboardEvent<HTMLInputElement>): boolean => {
		const allowedNumberChars = !/[0-9]/.test(e.key) 
			&& e.code !== 'Backspace' 
			&& e.code !== 'ArrowLeft' 
			&& e.code !== 'ArrowRight' 
			&& e.code !== 'Delete'
		
		return allowedNumberChars
	}

	useEffect(() => {
		if (props.value !== undefined) {
			setInputValue(props.value)
		}
	}, [props.value]);

	useEffect(() => {
		if (defaultValue !== undefined && defaultValue !== '') {
			setInputValue(defaultValue.toString());
		}
	}, [defaultValue]);
	
	/**
	 * 
	 */
	const labelClasses = classNames({
		'inputWrapper__label--active': (inputValue.length > 0 || (props.value && props.value.length > 0)) || (onFocus === true && !noAnimation) || (defaultValue),
	});
	const inputClasses = classNames({
		'inputWrapper__input--password': type === 'password' || props.icon,
		'inputWrapper__input--uppercase': uppercase && uppercase === true
	});

	return (
		<div className={`inputWrapper ${noBorder ? 'inputWrapper--noBorder' : ''} ${errors ? 'inputWrapper--error' : ''}`} onClick={(e) => {e.preventDefault();}} >
			{/* {!!props.icon &&
				<div className="icon-wrapper inline-flex justify-center align-center absolute">
					{props.icon}
				</div>
			} */}

			<Text className={`inputWrapper__label ${labelClasses}`} type={'label'}>{label}</Text>

			{
				type !== 'password' &&
				<>
					{
						!mask ?
							<input className={`inputWrapper__input ${inputClasses} ${className ? className : ''}`}
								{...rest} 
								id={inputId} 
								placeholder="" 
								type={type}
								//defaultValue={defaultValue}
								value={inputValue}
								onKeyDown={(e) => {
									if (number) {
										checkAllowedNumberChars(e)
										e.preventDefault()
									}
								}}
								onFocus={() => {setOnFocus(true); didFocus && didFocus()}}
								onBlur={() => {setOnFocus(false); didBlur && didBlur()}}
								onChange={(e) => {setInputValue(e.target.value); onChange && onChange(e) }}
							/>
						:
							<InputMask 
								mask={mask} 
								alwaysShowMask={true} 
								maskPlaceholder={null} 
								className={`${inputClasses} ${className ? className : ''}`}
								{...rest} 
								type={type}
								onKeyDown={(e) => number && checkAllowedNumberChars(e) && e.preventDefault()}
							>
							</InputMask>
					}

					{
						loading &&
						<span className="inputWrapper__loader">
							<Spinner />
						</span>
					}
				</>
			}

			{
				type === 'password' &&
				<input 
					className={`inputWrapper__input ${inputClasses} ${className ? className : ''}`} 
					type={passwordVisibility === true ? 'text' : 'password'}
					{...rest}
					id={inputId} 
					placeholder=""
					onKeyDown={(e) => number && checkAllowedNumberChars(e) && e.preventDefault()}
					onFocus={() => {setOnFocus(true); didFocus && didFocus()}}
					onBlur={() => {setOnFocus(false); didBlur && didBlur()}}
					onChange={(e) => { setInputValue(e.target.value); onChange && onChange(e) }}
				/>
			}

			{
				type === 'password' && passwordVisibility !== undefined &&
				<button tabIndex={-1} className="inputWrapper__eye-button" type="button" onClick={onTogleVisibility} is-visible={passwordVisibility ? '' : null}>
					{!passwordVisibility ? IconFactory.eyeIcon() : IconFactory.eyeOffIcon()}
				</button>
			}

			{
				props.icon && type !== 'password' &&
				<span className="inputWrapper__icon">
					{props.icon}
				</span>
			}
		</div>
	);
}
