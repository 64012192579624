import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown, FormError } from 'src/components';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { Input } from 'src/components/molecules/Input/Input';
import { QuoterProfileFormFacade } from 'src/modules/quoter/controller/facades/quoter-profile-form.facade';
import { QuoterProfileFacade } from 'src/modules/quoter/controller/facades/quoter-profile.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { PROFILE_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import InputsGroup from '../../components/InputsGroup/InputsGroup';
import QuoterContent from '../../components/QuoterContent/QuoterContent';
import './QuoterProfile.scss';

/**
 *
 */
const QuoterProfilePage = (): JSX.Element => {

	/**
	 * 
	 */
	const { storedPerson } = QuoterFacade.useQuoterActors();
	const { RFC_ERRORS, formData } = QuoterProfileFormFacade.useQuoterProfileForm();
	const { 
		economicActivities, 
		economicOwnBusiness, 
		chosenEconomicActivity, 
		chosenOwnBusiness,
		countries, 
		countryStates,
		chosenCountry,
		chosenNationality,
		chosenCountryState,
		skipSteps,
		onSelectData,
		tracking,
	} = QuoterProfileFacade.useQuoterProfileEconomicActivities();
	const { urlId, stepNumber, isNextDisabled, isValidatingRFC, setIsNextDisabled, validatePersonRfc, nextPage, onBackRoute, stepHandler, setRFC } = QuoterProfileFacade.useQuoterProfile(skipSteps);
	const [triggerRender, setTriggerRender] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setTriggerRender(true)
		}, 500)
	}, [])

	/**
	 * 
	 */
	const onSetData = () => {
		setRFC(formData.getValues())
	}

	/**
	 * 
	 */
	useEffect(() => {
		if (storedPerson) {
			const disabledCondition = (urlId === PROFILE_ROUTE_ID.COUNTRY && storedPerson.profile && storedPerson.profile.originCountry >= 0) ||
				(urlId === PROFILE_ROUTE_ID.NATIONALITY && storedPerson.profile && storedPerson.profile.nationalityCode >= 0) ||
				(urlId === PROFILE_ROUTE_ID.STATE && storedPerson.profile && storedPerson.profile.originCountryState >= 0) ||
				(urlId === PROFILE_ROUTE_ID.ECONOMIC_ACTIVITIES && storedPerson.profile && storedPerson.profile.economicActivity >= 0) ||
				(urlId === PROFILE_ROUTE_ID.ECONOMIC_OWN_BUSINESS && storedPerson.profile && storedPerson.profile.economicActivity >=0) ||
				(urlId === PROFILE_ROUTE_ID.RFC && storedPerson.profile && storedPerson.profile.rfc.length === 13 && formData.formState.errors.rfc === undefined);

			if (disabledCondition)
				setIsNextDisabled(false)
			else 
				setIsNextDisabled(true)
		}
	}, [urlId, storedPerson, setIsNextDisabled, formData.formState.errors.rfc]);

  return (
    <div className="quoterProfile">
			<QuoterContent
				backButton={<BackButton to={onBackRoute()} />}
				title="¡Ya casi acabamos!"
				subtitle="Ayúdame con estos últimos datos personales 🙏🏻"
			>
				<div className="quoterProfile__form">

					{/* PATCH FOR SAFARI/CHROME NOT RENDERING COMPONENTS BUG ON IOS */}
					{ triggerRender && <div></div> }

					<InputsGroup
						elements={[
							{
								title: QuoterProfileFacade.STEPS_TITLES.COUNTRY,
								reactElement:
									<>							
										<Dropdown 
											items={countries.map((c) => {
												return {
													id: c.id,
													value: c.name
												}
											})} 
											placeholder="PAIS"
											defaultValue={chosenCountry ? { id: chosenCountry.id, value: chosenCountry.name } : undefined}
											onChange={(item) => onSelectData(item ? item.id : undefined)} 
											dropdownPlace="top"
											hasDeleteButton
											isLoading={countries.length === 0}
											disabled={countries.length === 0}
											notFoundText="No se encontró el país."
										/>
									</>
							},
							{
								title: QuoterProfileFacade.STEPS_TITLES.NATIONALITY,
								reactElement:
									<>							
										<Dropdown 
											items={countries.map((c) => {
												return {
													id: c.id,
													value: c.demonym
												}
											})} 
											placeholder="NACIONALIDAD"
											defaultValue={chosenNationality ? { id: chosenNationality.id, value: chosenNationality.demonym } : undefined}
											onChange={(item) => onSelectData(item ? item.id : undefined)}
											dropdownPlace="top"
											hasDeleteButton
											isLoading={countries.length === 0}
											disabled={countries.length === 0}
											notFoundText="No se encontró la nacionalidad."
										/>
									</>
							},
							{
								title: QuoterProfileFacade.STEPS_TITLES.STATE,
								reactElement:
									<>							
										<Dropdown 
											items={countryStates.map((c) => {
												return {
													id: c.id,
													value: c.name
												}
											})} 
											placeholder="ESTADO DE NACIMIENTO"
											defaultValue={chosenCountryState ? { id: chosenCountryState.id, value: chosenCountryState.name } : undefined}
											onChange={(item) => onSelectData(item ? item.id : undefined)} 
											dropdownPlace="top"
											hasDeleteButton
											isLoading={countryStates.length === 0}
											disabled={countryStates.length === 0}
											notFoundText="No se encontró el estado."
										/>
									</>
							},
							{
								title: QuoterProfileFacade.STEPS_TITLES.ECONOMIC_ACTIVITIES,
								reactElement:
									<>							
										<Dropdown 
											items={economicActivities.map((a) => {
												return {
													id: a.id,
													value: a.value
												}
											})} 
											placeholder="ACTIVIDAD ECONOMICA"
											defaultValue={chosenEconomicActivity ? { id: chosenEconomicActivity.id, value: chosenEconomicActivity.value } : undefined}
											onChange={(item) => onSelectData(item ? item.id : undefined)} 
											dropdownPlace="top"
											hasDeleteButton
											cancelSearch
											isLoading={economicActivities.length === 0}
											disabled={economicActivities.length === 0}
											notFoundText="No se encontró la actividad."
										/>
									</>
							},
							{
								title: QuoterProfileFacade.STEPS_TITLES.ECONOMIC_OWN_BUSINESS,
								reactElement:
									<>							
										<Dropdown 
											items={economicOwnBusiness.map((o) => {
												return {
													id: o.id,
													value: o.value
												}
											})}
											placeholder="SECTOR ECONOMICO"
											defaultValue={chosenOwnBusiness ? { id: chosenOwnBusiness.id, value: chosenOwnBusiness.value } : undefined}
											onChange={(item) => onSelectData(item ? item.id : undefined)} 
											dropdownPlace="top"
											hasDeleteButton
											isLoading={economicOwnBusiness.length === 0}
											disabled={economicOwnBusiness.length === 0}
											notFoundText="No se encontró el sector."
										/>
									</>
							},
							{
								title: QuoterProfileFacade.STEPS_TITLES.RFC,
								reactElement:
									<>	
										<form onChange={onSetData}>						
											<Controller 
												name="rfc"
												control={formData.control}
												rules={{ 
													required: true, 
													pattern: Util.PATTERN.RFC, 
													validate: { invalidRfc: async (rfc) => await validatePersonRfc(rfc) }
												}}
												render={({ field }) => (
													<Input 
														label="RFC" 
														required
														uppercase
														defaultValue={storedPerson && storedPerson.profile ? storedPerson.profile.rfc : ''}
														autoComplete="off"
														loading={isValidatingRFC}
														type="text"
														maxLength={13}
														errors={!!formData.formState.errors.rfc}
														onChange={(e) => field.onChange(e.target.value)}
													/>
												)}
											/>
										</form>						
										<FormError schema={RFC_ERRORS} type={formData.formState.errors.rfc?.type} />
									</>
							},
						]}
						defaultStep={stepNumber}
						onStepChange={(step, direction) => {stepHandler(step, direction); tracking(step); }}
						skipSteps={skipSteps}
						disabledButton={isNextDisabled}
						onComplete={() => nextPage(formData.getValues('rfc'))}
					/>
				</div>
			</QuoterContent>
    </div>
  );
};

export default QuoterProfilePage;