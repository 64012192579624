import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factories/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IAuthTokenData } from "../interfaces/IAuthTokenData";
import { ILoginRequest } from "../interfaces/ILoginRequest";
import { ILoginTemporaryRequest } from "../interfaces/ILoginTemporaryRequest";
import { IPolicyCreationVehicleRequest } from "../interfaces/IPolicyCreationVehicleRequest";
import { IPolicyRequest } from "../interfaces/IPolicyRequest";
import { IPolicyRequestData } from "../interfaces/IPolicyRequestData";
import { IRegisterData } from "../interfaces/IRegisterData";
import { IRegisterRequest } from "../interfaces/IRegisterRequest";

/**
 *
 */
export namespace QuoterService {

  /**
   * 
   * @param request 
   * @returns 
   */
  export const registerUser = (request: IRegisterRequest): AxiosPromise<IRegisterData> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.REGISTER_USER, request);
  };

  /**
   * 
   * @param request 
   * @returns 
   */
  export const loginService = (request: ILoginRequest | ILoginTemporaryRequest): AxiosPromise<IAuthTokenData> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.USER_LOGIN, request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
	export const createVehicleService = (request: IPolicyCreationVehicleRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.REGISTER_VEHICLE, request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const policyRequestService = (request: IPolicyRequest): AxiosPromise<IPolicyRequestData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_REQUEST, request);
  }
}