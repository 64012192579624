import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import { AppRoutes } from "src/routes/routes";
import './Main.scss';

/**
 *
 * @returns
 */
const Main = (): JSX.Element => {
  const routing = useRoutes(AppRoutes.routes());

  return (
    <div id="main">
      <div><Toaster containerStyle={{ zIndex: 99999 }} /></div>
      {routing}
    </div>
  );
};

export default Main;
