import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { User } from 'src/classes/person/User';

interface IInstanceStateReducer {

  user: User | undefined;
}

export namespace CoreInstanceSlice {

  /**
   * 
   */
  const initialState: IInstanceStateReducer = {
    user: undefined,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IInstanceStateReducer> = (state) => {
    state.user = undefined;
  }


  const reducers = {
    clear,
  };

  /**
   * 
   */
  const instanceSlice = createSlice({
    name: 'instance',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = instanceSlice;
}



