import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElementChangeEvent, StripeCardElementOptions, StripeElements } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
//import { Label } from "src/components";

import './InputCard.scss';

/**
 *
 */
interface IInputCard {
	/** */
	disabled?: boolean;
	/** */
  onComplete?: (isCompleted: boolean, stripe?: Stripe, elements?: StripeElements, cardType?: string) => void;
}

/**
 *
 */
export const InputCard = (props: IInputCard): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

	const appearance: StripeCardElementOptions = {
		style: {
			base: {
				'::placeholder': {
					color: '#B4B4B4',
				}
			}
		}
	}

  // Stripe module
  const stripe = useStripe();
  const elements = useElements();
	
  /**
   *  
   * @param event 
   */
  const handleOnChange = (event: StripeCardElementChangeEvent) => {
    if (event.complete && props.onComplete) {
      props.onComplete(true, stripe!, elements!, event.brand.toLocaleUpperCase());
    } else if (!event.complete && props.onComplete) {
      props.onComplete(false);
    }
  }

	/**
	 * 
	 */
	useEffect(( ) => {
		if (props.disabled !== undefined)
			elements?.getElement('card')?.update( { disabled: props.disabled })
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.disabled])

  return (
    <>
      <div className="inputCardWrapper" is-focused={isFocused ? '' : null}>
        <CardElement onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onChange={handleOnChange} options={appearance} />
      </div>
    </>
  );
};