import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { QUOTER_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterVehicleAction } from "../actions/quoter-vehicle.action";
import { QuoterVehicleFeature } from "../features/quoter-vehicle.feature";
import { IQuoterVehicleIdDataForm } from "../interfaces/IQuoterVehicleIdDataForm";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterVehicleFacade } from "./quoter-vehicle.facade";
import { QuoterFacade } from "./quoter.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
export namespace QuoterVehicleIdFacade {

	/**
	 * 
	 */
	enum VEHICLE_ID_STEPS {
		VIN = 'VIN',
		PLATE = 'PLATE'
	};
	const STEPS_ORDER = [VEHICLE_ID_STEPS.VIN, VEHICLE_ID_STEPS.PLATE];
	const NAVIGATION_ROUTES = [
		`${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${VEHICLE_ROUTE_ID.VIN}`,
		`${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${VEHICLE_ROUTE_ID.PLATE}`
	]
	/**
	 * 
	 */
	export const STEPS_TITLES = {
		VIN: 'ESCRIBE EL NUMERO DE SERIE DE TU AUTO',
		PLATE: 'ESCRIBE LAS PLACAS DE TU AUTO'
	};

	/**
	 * 
	 */
	export const useQuoterVehicleId = () => {

		const { storedVehicle } = QuoterFacade.useQuoterActors();
		const { search } = QuoterParamsFacade.useQuoterParams();
		const { fillVehicleData } = QuoterVehicleFacade.useFillVehicle();
		const [stepNumber, setStepNumber] = useState<number>(0);
		const [vehicle, setVehicle] = useState<Vehicle | undefined>();
		const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
		const [isValidatingVin, setIsValidatingVin] = useState<boolean>(false)
    const [validatedVin, setValidatedVin] = useState('');
		const [showVinLocationModal, setShowVinLocationModal] = useState<boolean>(false);
    const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);
		const { id: urlId } = useParams<string>();
		const isVinHelperVisible = urlId === VEHICLE_ROUTE_ID.VIN;
    const dispatch = useDispatch();
		const navigate = useNavigate();

		/**
		 * 
     * @param personForm
     */
		const setVehicleIdValues = (vehicleForm: IQuoterVehicleIdDataForm): void => {
			let vehicleData = fillVehicleData();

			if(vehicleForm.vin)
				vehicleData.vin = vehicleForm.vin;

			if(vehicleForm.plate)
				vehicleData.plate = vehicleForm.plate;

			setVehicle(vehicleData);
			dispatch(QuoterVehicleAction.setStoredVehicle(vehicleData));
		};

		/**
		 * 
		 */
		const nextPage = (): void => {
			if (vehicle) {
				
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.vehiclePlate
				});

				navigate(`${QUOTER_PATH.QUOTER_PERSON_TYPE_PATH}${search}`);
			}
		};

		/**
		 * 
		 * @returns 
		 */
		const onBackRoute = (): string => {
			let _route = '';

			if (urlId === VEHICLE_ROUTE_ID.VIN)
				_route = `${QUOTER_PATH.QUOTER_PLAN_PATH}${search}`;
			else if (urlId === VEHICLE_ROUTE_ID.PLATE)
				_route = `${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${VEHICLE_ROUTE_ID.VIN}${search}`;

			return _route
		};

		/**
		 * 
		 */
		const stepHandler = (_step: number) => {
			let _routeId: string = '';

			if ((STEPS_ORDER[_step] === VEHICLE_ID_STEPS.VIN) && (urlId !== VEHICLE_ROUTE_ID.VIN)) {
				_routeId = VEHICLE_ROUTE_ID.VIN
			} else if ((STEPS_ORDER[_step] === VEHICLE_ID_STEPS.PLATE) && (urlId !== VEHICLE_ROUTE_ID.PLATE)) {
				_routeId = VEHICLE_ROUTE_ID.PLATE;

				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.vehicleVin,
				});

			}

			navigate(`${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${_routeId}${search}`, { replace: true });
		}

		/**
     * 
     * @param vin 
     * @returns 
     */
    const validateVehicleVin = async (vin: string): Promise<boolean | undefined> => {
      try {
        if (Util.PATTERN.VEHICLE_VIN.test(vin.toUpperCase())) {
          if (validatedVin !== vin) {
            setIsValidatingVin(true);
            setValidatedVin(vin);
            const validation = await QuoterVehicleFeature.validateVinFeature(vin.toUpperCase());
            setPreviousValidation(validation)
            return validation;
          } else {
            return previousValidation as boolean;
          }

        }
      } catch (e) {

      } finally {
        setIsValidatingVin(false);
      }
    }

		/**
		 * 
		 */
		useEffect(() => {
			if (urlId) {
				let _step = 0;

				if(urlId === VEHICLE_ROUTE_ID.VIN) {
					_step = STEPS_ORDER.indexOf(VEHICLE_ID_STEPS.VIN)
				} else if (urlId === VEHICLE_ROUTE_ID.PLATE) {
					_step = STEPS_ORDER.indexOf(VEHICLE_ID_STEPS.PLATE)
				}

				setStepNumber(_step);
			}
		}, [urlId]);

		/**
		 * 
		 */
		useEffect(( ) => {
			if (storedVehicle && !vehicle)
				setVehicle(storedVehicle);
		}, [vehicle, storedVehicle, setVehicle]);

		return { 
			VEHICLE_ID_STEPS, 
			STEPS_TITLES, 
			NAVIGATION_ROUTES, 
			stepNumber, 
			urlId,
			vehicle, 
			isNextDisabled,
			isValidatingVin,
			isVinHelperVisible,
			showVinLocationModal,
			setShowVinLocationModal,
			validateVehicleVin,
			nextPage,
			setIsNextDisabled,
			onBackRoute,
			setVehicleIdValues, 
			stepHandler 
		}
	}
}