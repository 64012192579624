import { createRef, useEffect, useState } from "react";
import { Text } from "src/components/atoms";
import { Input } from "src/components/molecules";
import { IconFactory } from "src/factories/icon.factory";
import './Suggestion.scss';

/**
 *
 */
interface ISuggestion {
  /**
   * 
   */
  label: string;
  /**
   * 
   */
  onTextChange: (text: string) => void;
  /**
   * 
   */
  onSuggestionSelect: (index: number) => void;
  /**
   * 
   */
  inputValue?: string;
  /**
   * 
   */
  suggestions?: string[];
  /**
   * 
   */
  loading?: string;
	/**
	 * 
	 */
	invalidAddress?: boolean;

}

/**
 *
 */
export const Suggestion = (props: ISuggestion): JSX.Element => {
  const [currentTextValue, setCurrentTextValue] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const suggestionRef = createRef<HTMLDivElement>();

  /**
   * 
   * @param text 
   */
  const onInputChange = (text: string): void => {
    if (!showSuggestions && text !== '') {
      setShowSuggestions(true);
    }

    setCurrentTextValue(text);
    props.onTextChange(text);
  }

  /**
   * 
   * @param index 
   */
  const onSelect = (index: number): void => {
    props.onSuggestionSelect(index);
    setShowSuggestions(false);
  }

  /**
   * 
   * @param value 
   */
  const suggestionVisibility = (value: boolean): void => {
    setShowSuggestions(value);
  }

  /**
   * 
   */
  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target as Node)) {
        suggestionVisibility(false);
      }
    }

    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [suggestionRef]);

  /**
   * 
   */
  useEffect(
    () => {
      if (props.inputValue) {
        setCurrentTextValue(props.inputValue);
      }
    }, [props.inputValue]
  )

  return (
    <div className="suggestion" ref={suggestionRef}>
      <Input label={props.label}
        autoComplete="off"
        value={currentTextValue}
				errors={props.invalidAddress}
        onChange={(e) => onInputChange(e.target.value)}
        didFocus={() => suggestionVisibility(true)}
        icon={IconFactory.searchIcon()}
      />

      {
        props.suggestions && props.suggestions.length > 0 && showSuggestions && currentTextValue !== '' &&
        <ul className="suggestion__list">
          {
            props.suggestions.map(
              (suggestion, index) => {
                return (
                  <li className="suggestion__list__item" key={`suggestion-${index}`} onClick={() => onSelect(index)}>
                    <Text type="label" className="suggestion__list__item__text">{suggestion}</Text>
                  </li>
                )
              }
            )
          }
        </ul>
      }
    </div>
  );
};