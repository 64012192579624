import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { EnvironmentFactory } from './EnvironmentFactory/environment.factory';

export namespace TrackingFactory {

  /**
   * 
   */
  export const EVENTS = {
    vehicleYear: {
      name: 'quo_vehicleYearScreen-form_submitted',
      property: 'dlv-quo_vehicleYear'
    },
    
    vehicleBrand: {
      name: 'quo_vehicleBrandScreen-form_submitted',
      property: 'dlv-quo_vehicleBrand'
    },

    vehicleModel: {
      name: 'quo_vehicleModelScreen-form_submitted',
      property: 'dlv-quo_vehicleModel'
    },

    vehicleVersion: {
      name: 'quo_vehicleVersionScreen-form_submitted',
      property: 'dlv-quo_vehicleVersion'
    },

    quotation: {
      name: 'quo_priceScreen-form_submitted',
      properties: {
        paymentType: 'dlv-quo_paymentType',
        paymentMethod: 'dlv-quo_paymentMethod',
        value: 'dlv-value',
        coupon: 'dlv-quo_discountCoupon',
      }
    },

    userData: 'con_userDataScreen-form_submitted',

    paymentMethod: {
      name: 'con_paymentMethodScreen-form_submitted',
      properties: {
        paymentType: 'dlv-con_paymentType',
        paymentMethod: 'dlv-con_paymentMethod',
      }
    },

    paymentData: 'con_paymentDataScreen-form_submitted',

    email: {
      name: 'quo_signUpScreen-form_submitted',
      properties: {
        age: 'dlv-quo_userAge',
        email: 'dlv-quo_userEmail',
      }
    },

    signUp: {
      name: 'con_signUpScreen-form_submitted',
      properties: {
        userId: 'dlv-con_userId'
      }
    },

    susccessfullPayment: 'con_successfullPayment-message_received',
    personData: 'quo_userScreen-form_submitted',
    postalCode: {
      name: 'quo_locationScreen-form_submitted',
      property: 'dlv-quo_userZip'
    },
    gender: {
      name: 'con_genderScreen_form-submitted',
      property: 'dlv-con_gender'
    },
    personType: {
      name: 'con_vehicleUseScreen-form_submitted',
      property: 'dlv-con_vehicleUse'
    },
    vehicleVin: 'con_vehicleSerialScreen-form_submitted',
    vehiclePlate: 'con_vehicleLicensePlateScreen-form_submitted',
    country: {
      name: 'con_countryScreen-form_submitted',
      property: 'dlv-con_userCountry'
    },
    nationality: {
      name: 'con_nationalityScreen-form_submitted',
      property: 'dlv-con_userNationality'
    },
    countryState: {
      name: 'con_stateScreen-form_submitted',
      property: 'dlv-con_userState'
    },
    economicActivity: {
      name: 'con_economicActivityScreen-form_submitted',
      property: 'dlv-con_economicActivity'
    },
    rfc: {
      name: 'con_rfcScreen-form_submitted',
      property: 'dlv-con_rfc'
    },
    quoterInit: 'quo_quotationflow-firstScreen_load',
    flowUnload: {
      name: 'flow_userTryToLeavesTheFlow',
      property: 'dlv-quo_userPhone'
    },
    userChannel: {
      name: 'quo_userSourceScreen-form_submitted',
      property: 'dlv-quo_source'
    },
    vehicleRescue: {
      name: 'con_vehicleRescueScreen-form_submitted',
      property: 'dlv-con_vehicleRescue'
    },
    vehicleEnsurance: 'con_vehicleEnsuranceScreen-popUp_closed',
    helpButton: {
      name: 'quo_help-button_clicked',
      property: 'dlv-quo_url'
    },
  }

  /**
   * 
   */
  interface ITrackingEvent {

    event: string,

    /**
     * DLV property name
     */
    [key: string|number]: string | number,
  }

  /**
   * 
   */
  class TrackerClient {

    /**
     * Current instance
     */
    private static _currentInstance: TrackerClient | null;

    /**
     * Google tag manager ID (.env)
     */
    private readonly GTM_ID: string = EnvironmentFactory.ENV.REACT_APP_GTM;

    private constructor() {
      const tagManagerArgs: TagManagerArgs = {
        gtmId: this.GTM_ID,
        dataLayerName: 'dataLayer',
      }

      TagManager.initialize(tagManagerArgs);
    }

    /**
     * 
     * @returns 
     */
    public static instance(): TrackerClient {
      if (this._currentInstance == null) {
        this._currentInstance = new TrackerClient();
      }

      return this._currentInstance;
    }

    /**
     * 
     */
    public track(event: ITrackingEvent): void {
      TagManager.dataLayer({dataLayer: event});
    }
  }

  export const Tracker = TrackerClient.instance();
}