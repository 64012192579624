export const pattern = {

  NUMBERS: /^\d+$/,

	NAMES: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,

	COMPANY_NAMES: /^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?&-]+$/,

  EMAIL: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,

  ADDRESS: /^[A-Za-z0-9À-ÿ'#.,\-\s]/,

  ALPHANUMERIC: /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/,

  DESCRIPTION: /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9.,\s]+)$/,
  
  RFC: /^([A-ZÑ]|)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/i,

  VEHICLE_VIN: /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-npr-z\d]{8}$/i,
	
  VEHICLE_PLATE: /^[A-Za-z0-9]{5,}$/, 

  PLAN_COUPON: /^[0-9a-zA-Z\s]{4,}$/i,
}