import PasswordValidator from "password-validator";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertFactory } from "src/factories/alert.factory";
import { LoadingFactory } from "src/factories/loading.factory";
import { PAYMENT_ROUTE_ID, QUOTER_PATH } from "src/routes/paths/quoter.paths";
import { QuoterAction } from "../actions/quoter.action";
import { QuoterFeature } from "../features/quoter.feature";
import { IPasswordForm } from "../interfaces/IPasswordForm";
import { PlanSelector } from "../selectors/plan.selector";
import { QuoterPersonSelector } from "../selectors/quoter-person.selector";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

export namespace AccountFacade {


  const PATTERN_REPEATED_ELEMENTS = /(.)\1{2}/;

  const PATTERN_SPECIFIC_WORD = /CRABI/;

  const PATTERN_SECUENTIAL_ELEMENTS = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)|(098)|(987)|(876)|(765)|(654)|(543)|(432)|(321)|(210)|(ABC)|(BCD)|(CDE)|(DEF)|(EFG)|(FGH)|(GHI)|(HIJ)|(IJK)|(JKL)|(KLM)|(LMN)|(MNO)|(NOP)|(OPQ)|(PQR)|(QRS)|(RST)|(STU)|(TUV)|(UVW)|(VWX)|(WXY)|(XYZ)|(ZYX)|(YXW)|(XWV)|(WVU)|(VUT)|(UTS)|(TSR)|(SRQ)|(RQP)|(QPO)|(PON)|(ONM)|(NML)|(MLK)|(LKJ)|(KJI)|(JIH)|(IHG)|(HGF)|(GFE)|(FED)|(EDC)|(DCB)|(CBA)/;

  /**
   * 
   */
  export const PASSWORD_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'La contraseña no cumple con los parámetros requeridos.',
    },
    {
      key: 'secuentialElements',
      message: 'La contraseña no puede contener secuencias de 3 o más números o letras',
    },
    {
      key: 'repeatedElements',
      message: 'La contraseña no puede tener 3 o más caracteres iguales juntos.',
    },
    {
      key: 'patternSpecificWord',
      message: 'La contraseña no puede tener la palabra CRABI',
    },
    {
      key: 'invalidParams',
      message: 'La contraseña no cumple con los parámetros requeridos.',
    },
    {
      key: 'includesCurrentEmail',
      message: 'La contraseña no puede tener el correo que estás registrando o registraste.',
    },
  ];

  export const CONFIRM_PASSWORD_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Confirma tu contraseña.',
    },
    {
      key: 'notSame',
      message: 'Las contraseña no coinciden.',
    },

  ]

  /**
   * 
   * @returns 
   */
  export const usePasswordValidation = () => {
    const person = useSelector(QuoterPersonSelector.storedPerson);
    const [showRules, setShowRules] = useState<boolean>(false);
    const { control, formState: { errors, isValid, dirtyFields }, watch, setError, clearErrors, getValues } = useForm<IPasswordForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    const [hasUppercase, setHasUppercase] = useState<boolean>(false);
    const [hasLowercase, setHasLowercase] = useState<boolean>(false);
    const [hasDigit, setHasDigit] = useState<boolean>(false);
    const [validLength, setValidLength] = useState<boolean>(false);

    const passwordValidator = new PasswordValidator();
    passwordValidator
      .is().min(8)
      .has().uppercase()
      .has().lowercase()
      .has().digits()
      .has().not().spaces()

    /**
     * 
     */
    const validateOnChange = (password: string, confirmPassword: string): void => {
      if (password === '') {
        setHasUppercase(false);
        setHasLowercase(false);
        setHasDigit(false);
        setValidLength(false);
      }

      if (password && dirtyFields.confirmNewPassword && password !== confirmPassword) {
        setError('confirmNewPassword', { type: 'notSame' });
      } else if (password && dirtyFields.confirmNewPassword && password === confirmPassword) {
        clearErrors('confirmNewPassword');
      }
    }

    /**
     * 
     */
    const validatePasswordParams = (password: string): boolean => {
      const invalidParams: string[] = passwordValidator.validate(password, { list: true }) as string[];
      setHasUppercase(invalidParams.find(p => p === 'uppercase') ? false : true);
      setHasLowercase(invalidParams.find(p => p === 'lowercase') ? false : true);
      setHasDigit(invalidParams.find(p => p === 'digits') ? false : true);
      setValidLength(invalidParams.find(p => p === 'min') ? false : true);
      return passwordValidator.validate(password) as boolean;
    }

    /**
     * 
     */
    const validateSpecificWord = (password: string): boolean => {
      return !PATTERN_SPECIFIC_WORD.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateRepeatedElements = (password: string): boolean => {
      return !PATTERN_REPEATED_ELEMENTS.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateSecuentialElements = (password: string): boolean => {
      return !PATTERN_SECUENTIAL_ELEMENTS.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateCurrentEmail = (password: string, email?: string): boolean => {
      let emailName: string = '';
      if (email) {
        emailName = email.split('@')[0];
        const passwordWithoutNumbers = password.replaceAll(/[0-9]/g, '');
        return !(emailName.toLocaleUpperCase() === password.toLocaleUpperCase()) || !(emailName.toLocaleUpperCase() === passwordWithoutNumbers.toLocaleUpperCase());
      } else if (person) {
        emailName = person.email.split('@')[0];
        return !(emailName.toLocaleUpperCase() === password.toLocaleUpperCase());
      } else {
        return true;
      }

    }

    /**
     * 
     */
    const validateSamePassword = (confirmNewPassword: string, newPassword: string): boolean => {
      return confirmNewPassword === newPassword;
    }

    return {
      person, showRules, setShowRules,
      form: { control, formState: { errors, isValid, dirtyFields }, watch, setError, clearErrors, getValues },
      passwordState: { hasUppercase, hasLowercase, hasDigit, validLength },
      validatePassword: {
        validatePasswordParams, validateSpecificWord, validateRepeatedElements,
        validateSecuentialElements, validateOnChange, validateCurrentEmail, validateSamePassword
      }
    }
  }

  /**
   * 
   */
  export const useAccount = () => {
    const creating = LoadingFactory.useLoader();
    const storedPerson = useSelector(QuoterPersonSelector.storedPerson);
    const storedVehicle = useSelector(QuoterVehicleSelector.storedVehicle);
    const plan = useSelector(PlanSelector.currentPlan);
		const { search } = QuoterParamsFacade.useQuoterParams();
		const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     */
    const createAccount = async (credentials: string) => {
      try {
        if (storedPerson && storedVehicle && plan) {
          creating.set(true);
          storedPerson.password = credentials;
          const user = await QuoterFeature.registerUserFeature(storedPerson);
					storedPerson.id = user.id;
          await QuoterFeature.loginFeature(storedPerson);
          await QuoterFeature.createVehicleFeature(storedVehicle);
          const policy = await QuoterFeature.policyRequestFeature(storedVehicle, plan, storedPerson);
					dispatch(QuoterAction.setPolicy(policy));

          TrackingFactory.Tracker.track({
            event: TrackingFactory.EVENTS.signUp.name,
            [TrackingFactory.EVENTS.signUp.properties.userId]: user.id
          });

          navigate(`${QUOTER_PATH.QUOTER_PAYMENT_PATH}/${PAYMENT_ROUTE_ID.METHOD}${search}`)
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        creating.set(false);
      }
    }

    return { creating, search, createAccount }
  }
}