import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";

/**
 *
 */
export namespace QuoterVehicleAction {
	/**
   * 
   */
	export const getStoredVehicle = createAction('GET_STORED_VEHICLE');
	/**
   * 
   */
	export const clear = createAction('CLEAR_QUOTER_VEHICLE');
  /**
   * 
   */
  export const getBrands = createAction('GET_VEHICLE_BRANDS');
	/**
	 * 
	 */
	export const setStoredVehicle: ActionCreatorWithPayload<Vehicle> = createAction('SET_STORED_VEHICLE');
  /**
   * 
   */
  export const getModels: ActionCreatorWithPayload<VehicleBrand> = createAction('GET_VEHICLE_MODELS');
  /**
   * 
   */
  export const getYears: ActionCreatorWithPayload<VehicleModel> = createAction('GET_VEHICLE_YEARS');
  /**
   * 
   */
  export const getVersion: ActionCreatorWithPayload<VehicleYear> = createAction('GET_VEHICLE_VERSION');
  /**
   * 
   */
  export const onItemSelectedFormList: ActionCreatorWithPayload<string> = createAction('ITEM_SELECTED');
}