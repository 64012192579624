import { AxiosPromise } from "axios";
import { Util } from "src/utils/Util";
import { IAddressData } from "../interfaces/IAddressData";
import { ICountriesData } from "../interfaces/ICountriesData";
import { ICountryStatesData } from "../interfaces/ICountryStatesData";
import { IEconomicActivityData } from "../interfaces/IEconomicActivityData";
import { IValidationData } from "../interfaces/IValidationData";
import { IOwnBusinessData } from "../interfaces/IOwnBusinessData";
import { EnvironmentFactory } from "src/factories/EnvironmentFactory/environment.factory";


/**
 *
 */
export namespace QuoterPersonService {

	/**
	 * 
	 * @returns 
	 */
	export const getCountries = (): AxiosPromise<ICountriesData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_COUNTRIES);
	}

	/**
	 * 
	 * @param countryId 
	 * @returns 
	 */
	export const getCountryStates = (countryId: number): AxiosPromise<ICountryStatesData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_COUNTRY_STATES(countryId));
	}

	/**
	 * 
	 * @returns
	 */
	export const getEconomicActivities = (): AxiosPromise<IEconomicActivityData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_ECONOMIC_ACTIVITIES);
	}

	/**
	 *
	 * @returns
	 */
	export const getOwnBusinessActivities = (): AxiosPromise<IOwnBusinessData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_OWN_BUSINESS_ACTIVITIES);
	}

	/**
   * 
   * @returns 
   */
	export const validatePostalCodeService = (postalCode: string): AxiosPromise<IAddressData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_POSTAL_CODE(postalCode));
	}

	/**
   * 
   * @returns 
   */
	export const validateEmailService = (email: string): AxiosPromise<IValidationData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_EMAIL(email));
	}

	/**
   * 
   * @returns 
   */
	export const validateRfcService = (rfc: string): AxiosPromise<IValidationData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_PERSON_RFC(rfc));
	}
}