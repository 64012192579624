import './Spinner.scss';

/**
 *
 */
interface ISpinner {

  color?: 'main' | 'white';

  relative?: boolean;

  classNames?: string;

}

/**
 *
 */
export const Spinner = (props: ISpinner): JSX.Element => {
  const {
    classNames = props.classNames? ` ${props.classNames}` : ''
  } = props;
  return (
    <svg
      // spinner-color={props.color ? props.color : null}
      // className={`spinner-wrapper ${spinnerClassNames}`}
      className={`spinner ${props.color? ` spinner--color-${props.color}` : ''}${props.relative? ` spinner--relative` : ''}${classNames}`}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="8" r="28" strokeDasharray="131.94689145077132 45.982297150257104">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.5s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );
};