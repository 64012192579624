import { useState } from 'react';
import './StepsBar.scss';

/**
 *
 */
interface IStepsBar {
	/** */
	steps: number;
	/** */
	currentStep: number;
}

/**
 *
 */
export const StepsBar = (props: IStepsBar): JSX.Element => {
	/** */
	const { steps, currentStep } = props;
	const [width, setWidth] = useState<number>(() => {

		let proportionalWidth: number = 0;

		if(currentStep !== 0) {
			proportionalWidth = +(((currentStep -1 ) / (steps )) * 100).toFixed(0);
		}

		return proportionalWidth;
	});


	setTimeout(() => {
		
		if(currentStep !== 0) {
			const PROPORTIONAL_WIDTH: number = +(((currentStep ) / (steps)) * 100).toFixed(0);
			setWidth(PROPORTIONAL_WIDTH);
		}
		
	}, 100);
	
  return (
		<div className="stepsBarWrapper">
			<div  className="stepsBarWrapper__bar" style={ { width: `${width}%`, transition: 'width 0.5s ease-in-out' } }></div>
		</div>
  );
};