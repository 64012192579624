import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { User } from "src/classes/person/User";
import { IQuoterPersonDataForm } from "../interfaces/IQuoterPersonDataForm";
import { Util } from "src/utils/Util";

/**
 *
 */
export namespace QuoterPersonFormFacade {

	const GENDERS = [
		{ id: Util.STATUS_CODE.GENDER.MALE, value: 'Masculino' },
		{ id: Util.STATUS_CODE.GENDER.FEMALE, value: 'Femenino' }
	];

	/**
   * 
   */
	const NAME_ERRORS_MESSAGE = {
		REQUIRED: 'El nombre es obligatorio.',
		INVALID: 'El nombre es inválido.'
	};
	const NAME_ERRORS: TFormErrorMessage[] = [
		{
			key: 'required',
			message: NAME_ERRORS_MESSAGE.REQUIRED
		},
		{
			key: 'pattern',
			message: NAME_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'minLength',
			message: NAME_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'invalid',
			message: NAME_ERRORS_MESSAGE.INVALID
		}
	];

	/**
   * 
   */
	const LASTNAME_ERRORS_MESSAGE = {
		REQUIRED: 'El apellido es obligatorio.',
		INVALID: 'El apellido es inválido.'
	};
	const LASTNAME_ERRORS: TFormErrorMessage[] = [
		{
			key: 'required',
			message: LASTNAME_ERRORS_MESSAGE.REQUIRED
		},
		{
			key: 'pattern',
			message: LASTNAME_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'minLength',
			message: LASTNAME_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'invalid',
			message: NAME_ERRORS_MESSAGE.INVALID
		}
	];

	/**
	 * 
	 * @param storedPerson 
	 * @returns 
	 */
	export const useQuoterPersonForm = (storedPerson?: User) => {
		const { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IQuoterPersonDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
		
    /**
     * 
     */
    const validateDefaultData = useCallback((storedPerson: User): void => {
      if (storedPerson) {
				if (storedPerson.name && !touchedFields.name) {
					setValue('name', storedPerson.name, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.lastName && !touchedFields.firstLastname) {
					setValue('firstLastname', storedPerson.lastName, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.secondLastName && !touchedFields.secondLastname) {
					setValue('secondLastname', storedPerson.secondLastName, { shouldValidate: true, shouldTouch: true });
				}
				
				if (storedPerson.profile && storedPerson.profile.gender > -1 && !touchedFields.gender) {
					setValue('gender', storedPerson.profile.gender, { shouldValidate: true, shouldTouch: true });
				}
      }
 
			const allDataCondition = storedPerson.name && storedPerson.lastName && storedPerson.secondLastName;

			if(allDataCondition)
				trigger();
    }, [setValue, touchedFields, trigger]);

    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }
    }, [storedPerson, validateDefaultData]);

		return {
			GENDERS,
      FORM_DATA_ERRORS: { NAME_ERRORS, LASTNAME_ERRORS },
      formData: { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
		}
  }
}