import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createInjectorsEnhancer } from 'redux-injectors';

import { rootReducer } from "./root-reducers.index";
import { IAppState } from "./states/app-state/IAppState";

const sagaMiddleware = createSagaMiddleware();
const runSaga = sagaMiddleware.run;

export const store = configureStore(
  { 
    reducer: rootReducer(),
    middleware: [
      sagaMiddleware,
    ],
    enhancers: [
      createInjectorsEnhancer({ createReducer: rootReducer, runSaga }),
    ],
  }
);

export type RootState = IAppState;
export type AppDispatch = typeof store.dispatch;