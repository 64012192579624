import { MdArrowBackIos, MdArrowForwardIos, MdKeyboardArrowDown, MdInfo, MdClose } from 'react-icons/md';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { RiCouponFill, RiErrorWarningLine } from 'react-icons/ri';
import { BiCheck, BiPencil, BiSearch, BiSupport } from 'react-icons/bi';
import { BsFillEyeFill, BsFillEyeSlashFill, BsWhatsapp } from 'react-icons/bs';
import { IconBaseProps } from "react-icons";
import { Icon } from "src/components";

/**
 *
 */
export namespace IconFactory {

  export const backArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdArrowBackIos);
  }

  export const forwardArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdArrowForwardIos);
  }

  export const downArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdKeyboardArrowDown);
  }

  export const leftArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, HiArrowNarrowLeft);
  }

  export const rightArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, HiArrowNarrowRight);
  }

  export const infoIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdInfo);
  };

  export const couponIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, RiCouponFill);
  }

  export const warningIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiErrorWarningLine);
  };

  export const editIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiPencil)
  }

  export const checkIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiCheck);
  };

  export const eyeIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsFillEyeFill);
  };

  export const eyeOffIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsFillEyeSlashFill);
  }

  export const closeIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdClose);
  };

  export const searchIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiSearch);
  };

  export const supportIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiSupport);
  };

  export const whatsappIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsWhatsapp);
  };
}

