import { useState } from 'react';
import Switch from "react-switch";

import './Toggle.scss';

/**
 *
 */
interface IToggle {

  defaultValue: boolean;

  onChange: (value: boolean) => void;
}

/**
 *
 */
export const Toggle = (props: IToggle): JSX.Element => {

  const [isEnabled, setIsEnabled] = useState(props.defaultValue);

  /**
   * 
   */
  const toggleChange = () => {
    setIsEnabled(!isEnabled);
    props.onChange(!isEnabled);
  }

  return (
    <Switch
      uncheckedIcon={false}
      checkedIcon={false}
      className="toggle-wrapper"
      handleDiameter={28}
      height={32}
      width={52}
      offColor="#e5e7eb"
      onColor="#0D4EC9"
      onHandleColor="#fff"
      boxShadow="0 0 5px #6a6a6a"
      activeBoxShadow="0 0 5px #6a6a6a"
      onChange={toggleChange} checked={isEnabled} />
  );
};