import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlanAction } from "../actions/plan.action";
import { PlanSelector } from "../selectors/plan.selector";
import { Util } from "src/utils/Util";
import { PlanPayment } from "src/classes/plan/PlanPayment";
import { Coverage } from "src/classes/plan/Coverage";
import { QuoterPersonSelector } from "../selectors/quoter-person.selector";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { QuoterSelector } from "../selectors/quoter.selector";
import { useNavigate } from "react-router-dom";
import { QUOTER_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/quoter.paths";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { PlanCostFacade } from "./plan-cost.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
export namespace PlanFacade {

  export const usePlan = () => {
    const plans = useSelector(PlanSelector.policyPlans);
    const person = useSelector(QuoterPersonSelector.storedPerson);
    const vehicle = useSelector(QuoterVehicleSelector.storedVehicle);
    const currentPlanConfig = useSelector(PlanSelector.currentPlanConfig);
    const temporalAuth = useSelector(QuoterSelector.temporalAuth);
    const currentPlan = useSelector(PlanSelector.currentPlan);
    const [defaultPaymentType, setDefaultPaymentType] = useState<{ id: number, value: string }>();
    const [selectedPlanPayment, setSelectedPlanPayment] = useState<PlanPayment>();
    const [showPaymentTypeModal, setShowPaymentTypeModal] = useState<boolean>(false);
    const [damageCoverages, setDamageCoverages] = useState<Coverage[]>([]);
    const [extraCoverages, setExtraCoverages] = useState<Coverage[]>([]);
    const [requirementPrompt, setRequirementPrompt] = useState<boolean>(false);
    const [showVehicleVin, setShowVehicleVin] = useState<boolean>(false);
    const { search } = QuoterParamsFacade.useQuoterParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * 
     * @param index 
     */
    const setPlanPayment = (typeCostId: number): void => {
      if (typeCostId === PlanCostFacade.PLAN_COST.ANNUAL.id) {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) {
            let planPayment = currentPlan.payments[i]
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      } else if (typeCostId === PlanCostFacade.PLAN_COST.SUSCRIPTION.id) {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
            let planPayment = currentPlan.payments[i]
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      } else {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && ((typeCostId - 2) * 3) === currentPlan.payments[i].paymentsCount) {
            let planPayment = currentPlan.payments[i];
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      }

    }

    /**
     * 
     */
    const selectPlan = (): void => {
      if (currentPlan && selectedPlanPayment) {
        let _currentPlan = currentPlan;
        _currentPlan.selectedPlanPayment = selectedPlanPayment;
        dispatch(PlanAction.currentPlan(_currentPlan));
        setRequirementPrompt(true);
      }
    };

    /**
     * 
     */
    const redirectToVehicle = () => {
      navigate(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}`);
    }

    /**
     * 
     */
    const nextPage = (): void => {
      let paymentMethodName: string = '';
      if (currentPlan.selectedPlanPayment.paymentsCount === 1) {
        paymentMethodName = 'Pago de contado';
      } else if (currentPlan.selectedPlanPayment.isSubscription) {
        paymentMethodName = 'Pago por suscripción';
      } else if (!currentPlan.selectedPlanPayment.isSubscription && currentPlan.selectedPlanPayment.paymentsCount > 1) {
        paymentMethodName = `Pago a meses (${currentPlan.selectedPlanPayment.paymentsCount} meses)`;
      }
      TrackingFactory.Tracker.track({
        event: TrackingFactory.EVENTS.quotation.name,
        [TrackingFactory.EVENTS.quotation.properties.paymentType]: currentPlan.selectedPlanPayment.selectedPaymentMethod as string,
        [TrackingFactory.EVENTS.quotation.properties.paymentMethod]: paymentMethodName,
        [TrackingFactory.EVENTS.quotation.properties.coupon]: currentPlan.coupon,
        [TrackingFactory.EVENTS.quotation.properties.value]: currentPlan.cost.total,
      })
      navigate(`${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${VEHICLE_ROUTE_ID.VIN}${search}`);
    }

    /**
     * 
     */
    useEffect(
      () => {
        let type = { id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name }
        if (currentPlan) {
          if (currentPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
            type = { id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name }
          } else if (currentPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) {
            for (let idx = 0; idx < currentPlan.monthly.costs.length; idx++) {
              if (currentPlan.selectedPlanPayment.paymentsCount === currentPlan.monthly.costs[idx].monthsQuantity) {
                type = {
                  id: (idx + 1) + PlanCostFacade.PLAN_COST.SUSCRIPTION.id,
                  value: `Pago a ${currentPlan.monthly.costs[idx].monthsQuantity} meses`,
                }
              }
            }
          }
        }
        setDefaultPaymentType(type);
      }, [currentPlan, setDefaultPaymentType]
    )

    useEffect(
      () => {
        if (currentPlan) {
          setDamageCoverages(
            () => {
              let list: Coverage[] = [];
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type)!);

              return list;
            }
          );
          setExtraCoverages(
            () => {
              let list: Coverage[] = [];
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type)!);
              return list;
            }
          );
        }
      }, [currentPlan]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (person && vehicle && vehicle.version && vehicle.version.id > 0 && temporalAuth) {
          dispatch(PlanAction.planConfiguration({ vehicle: vehicle, user: person, planType: currentPlanConfig || undefined }));
          dispatch(PlanAction.quotation({ vehicle: vehicle, user: person, coverages: [], coupon: '', loadAll: true, planType: currentPlanConfig }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [person, vehicle, temporalAuth, dispatch]
    );

    return {
      plans, currentPlan, person, showPaymentTypeModal, defaultPaymentType, selectPlan, requirementPrompt, search, setShowVehicleVin, showVehicleVin, nextPage, setRequirementPrompt,
      setShowPaymentTypeModal, setPlanPayment, redirectToVehicle, coverages: { damageCoverages, extraCoverages }
    };
  }

  /**
   * 
   */
  export const usePlanSync = (cost?: boolean) => {
    const isLoading = useSelector(PlanSelector.isLoadingPlan);
    const isUpdating = useSelector(PlanSelector.isUpdatingPlan);
    const dispatch = useDispatch();

    /**
     * 
     */
    useEffect(
      () => {
        if (cost) {
          dispatch(PlanAction.setUpdating(true));
          setTimeout(() => {
            dispatch(PlanAction.setUpdating(false));
          }, 1000);
        } else {
          dispatch(PlanAction.setUpdating(false));
        }
      }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return { isLoading, isUpdating }
  }
}