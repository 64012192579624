import { PropsWithChildren } from 'react';
import { Coverage } from 'src/classes/plan/Coverage';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { Text, Toggle } from 'src/components';
import { IconFactory } from "src/factories/icon.factory";
import { PlanCoverageFacade } from "src/modules/quoter/controller/facades/plan-coverage.facade";
import { PlanFacade } from "src/modules/quoter/controller/facades/plan.facade";
import { Util } from 'src/utils/Util';
import PlanCoverageOption from '../PlanCoverageOption/PlanCoverageOption';
import './PlanCoverages.scss';

/**
 *
 */
interface IPlanCoverages {
  /**
   * 
   */
  coverages: Coverage[];
  /**
   * 
   */
  vehicle?: Vehicle;
  /**
   * 
   */
  collapsable?: boolean;
}

/**
 *
 */
const PlanCoverages = (props: PropsWithChildren<IPlanCoverages>): JSX.Element => {
  const { onChangeCoverageValue } = PlanCoverageFacade.useCoverageCustomization();
  const { defaultOptionValue } = PlanCoverageFacade.usePlanCoverage();
  const { isUpdating } = PlanFacade.usePlanSync();

  return (
    <div className="planCoverages">

      {
        props.collapsable &&
        <div className="planCoverages__header">

          <div className="planCoverages__header__vehicle">
            {
              props.vehicle &&
              <>
                <img alt="vehicle-default" src={Util.ASSET.PLAN_QUOTER_CAR} />
                <Text type="subtitle" className="planCoverages__header__vehicle__details">
                  {props.vehicle.brand?.name} {props.vehicle.model?.name} {props.vehicle.year?.value}
                </Text>
                {props.children}
              </>
            }
          </div>
        </div>
      }

      <div className={`planCoverages__content ${props.collapsable ? 'planCoverages__content--border' : ''}`}>
        {
          props.coverages.map(
            (coverage, index) => (
              <section key={`dmgCoveragge-${index}`} className="planCoverages__content__item">
                <div className="planCoverages__content__item__image">
                  <div>
                    <img alt="coverage-indicator" src={coverage.image} />
                  </div>
                </div>

                <div className="planCoverages__content__item__values">

                  <div className="planCoverages__content__item__values__name">
                    <div>
                      <Text type="paragraph" bold>{coverage.name}</Text>
                      <Text type="paragraph">{coverage.description}</Text>
                    </div>

                    {
                      coverage.allowDisable &&
                      <div className="planCoverages__content__item__values__name__toggle" element-disabled={isUpdating ? '' : undefined}>
                        <Toggle
                          onChange={(value) => onChangeCoverageValue(coverage, value)}
                          defaultValue={coverage.isEnabled}
                        />
                      </div>
                    }
                  </div>


                  <ul className="planCoverages__content__item__values__list">
                    {coverage.includeList.map(
                      (value, index) => <li key={`dmgCoveragge-${index}-in-${index}`} className="planCoverages__content__item__values__list__item">
                        <span>{IconFactory.checkIcon()}</span>
                        <Text type="label" className="planCoverages__content__item__values__list__item__value">{value}</Text>
                      </li>
                    )}
                  </ul>

                  {
                    coverage.customitationOptions.length > 0 &&
                    <PlanCoverageOption
                      onSelect={(option) => onChangeCoverageValue(coverage, coverage.isDeductible ? option.deductible : option.assured)}
                      coverage={coverage}
                      selectedOption={defaultOptionValue(coverage.isDeductible ? coverage.deductible : coverage.assured, coverage.customitationOptions, coverage.isDeductible)}
                    />
                  }
                </div>
              </section>
            )
          )
        }
      </div>
    </div>
  );
};

export default PlanCoverages;