import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { QUOTER_PATH, QUOTER_QUERY_PARAMS } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterPersonAction } from "../actions/quoter-person.action";
import { QuoterVehicleAction } from "../actions/quoter-vehicle.action";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { QuoterPersonFacade } from "./quoter-person.facade";
import { QuoterVehicleFacade } from "./quoter-vehicle.facade";
import { QuoterPersonFeature } from "../features/quoter-person.feature";
import { Referral } from "src/classes/referrals/Referral";
import { User } from "src/classes/person/User";
import { PlanAction } from "../actions/plan.action";

/**
 *
 */
export namespace QuoterParamsFacade {

	/**
	 * 
	 */
	export const useInitParams = () => {
		const brandsList = useSelector(QuoterVehicleSelector.vehicleBrandsList);
    const modelsList = useSelector(QuoterVehicleSelector.vehicleModelsList);
    const yearsList = useSelector(QuoterVehicleSelector.vehicleYearsList);
    const versionsList = useSelector(QuoterVehicleSelector.vehicleVersionsList);
		const storedVehicle = useSelector(QuoterVehicleSelector.storedVehicle);
		
		const { fillPersonData } = QuoterPersonFacade.useFillPerson();
		const { fillVehicleData } = QuoterVehicleFacade.useFillVehicle();

		const [vehicleBrand, setVehicleBrand] = useState<number>();
		const [vehicleModel, setVehicleModel] = useState<number>();
		const [vehicleYear, setVehicleYear] = useState<number>();
		const [vehicleVersion, setVehicleVersion] = useState<number>();
		const location = useLocation();
		const { pathname } = location;
		const { getParam, search } = useQuoterParams();
		const dispatch = useDispatch();
		const navigate = useNavigate();

		const initParams = async() => {

			// Vehicle data
			const vehicleBrandParam = getParam(QUOTER_QUERY_PARAMS.VEHICLE_BRAND);
			const vehicleModelParam = getParam(QUOTER_QUERY_PARAMS.VEHICLE_MODEL);
			const vehicleYearParam = getParam(QUOTER_QUERY_PARAMS.VEHICLE_YEAR);
			const vehicleVersionParam = getParam(QUOTER_QUERY_PARAMS.VEHICLE_VERSION);

			// User data
			const userNameParam = getParam(QUOTER_QUERY_PARAMS.USER_NAME);
			const userLastNameParam = getParam(QUOTER_QUERY_PARAMS.USER_LASTNAME);
			const userSecondLastNameParam = getParam(QUOTER_QUERY_PARAMS.USER_SECOND_LASTNAME);
			const userEmailParam = getParam(QUOTER_QUERY_PARAMS.USER_EMAIL);
			const userPhoneCodeParam = getParam(QUOTER_QUERY_PARAMS.USER_PHONE_CODE);
			const userPhoneNumberParam = getParam(QUOTER_QUERY_PARAMS.USER_PHONE_NUMBER);
			const userBirthdateParam = getParam(QUOTER_QUERY_PARAMS.USER_BIRTHDATE);
			const userPostalCodeParam = getParam(QUOTER_QUERY_PARAMS.USER_POSTAL_CODE);
			const userStateParam = getParam(QUOTER_QUERY_PARAMS.USER_STATE);
			const userNeighborhoodParam = getParam(QUOTER_QUERY_PARAMS.USER_NEIGHBORHOOD); 
			const userLatitudeParam = getParam(QUOTER_QUERY_PARAMS.USER_LATITUDE);
			const userLongitudeParam = getParam(QUOTER_QUERY_PARAMS.USER_LONGITUDE); 
			const userStreetParam = getParam(QUOTER_QUERY_PARAMS.USER_STREET); 
			const userExternalReferenceParam = getParam(QUOTER_QUERY_PARAMS.USER_EXTERNAL_REFERENCE);
			const userGenderParam = getParam(QUOTER_QUERY_PARAMS.USER_GENDER); 
			const userCivilStatusParam = getParam(QUOTER_QUERY_PARAMS.USER_CIVIL_STATUS);
			const userReferralParam = getParam(QUOTER_QUERY_PARAMS.REFERRAL);
			
			// External source of quote data
			const mediatorSrcParam = getParam(QUOTER_QUERY_PARAMS.MEDIATOR_SRC);
			const planTypeParam = getParam(QUOTER_QUERY_PARAMS.PLAN_TYPE);

			const allVehicleParameters = vehicleBrandParam && vehicleModelParam && vehicleYearParam  && vehicleVersionParam;

			const user = await initialDataUser(userNameParam, userLastNameParam, userSecondLastNameParam, userEmailParam, userGenderParam, userCivilStatusParam, userPhoneCodeParam, userPhoneNumberParam, userBirthdateParam, userPostalCodeParam, userStateParam, userLatitudeParam, userLongitudeParam, userStreetParam, userExternalReferenceParam, userNeighborhoodParam, userReferralParam);
	
			if(user) {
				dispatch(QuoterPersonAction.setStoredPerson(user));
			}

			setVehicleData(vehicleBrandParam, vehicleModelParam, vehicleYearParam, vehicleVersionParam);

			if(!isPersonDataValid(userNameParam, userLastNameParam, userSecondLastNameParam, userGenderParam)) {
				navigateTo(QUOTER_PATH.QUOTER_PERSON_PATH, search);
			} else if (! await isPersonContactValid(userEmailParam, userPhoneCodeParam, userPhoneNumberParam , userBirthdateParam, userCivilStatusParam)) {
				navigateTo(QUOTER_PATH.QUOTER_EMAIL_PATH, search);
			} else if (!isPersonLocationValid(userLatitudeParam, userLongitudeParam, userPostalCodeParam, userStateParam, userStreetParam, userNeighborhoodParam, userExternalReferenceParam)) {
				navigateTo(QUOTER_PATH.QUOTER_LOCATION_PATH, search);
			} else if( pathname === QUOTER_PATH.QUOTER_PLAN_PATH) {
				navigateTo(QUOTER_PATH.QUOTER_PLAN_PATH, search)
			}

			if(!allVehicleParameters) {
				if(!vehicleBrandParam) {
					navigateTo(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${QUOTER_QUERY_PARAMS.VEHICLE_BRAND}`, search);
				} else if (!vehicleModelParam) {
					navigateTo(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${QUOTER_QUERY_PARAMS.VEHICLE_MODEL}`, search);
				} else if(!vehicleYearParam) {
					navigateTo(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${QUOTER_QUERY_PARAMS.VEHICLE_YEAR}`, search);
				} else if(!vehicleVersionParam) {
					navigateTo(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${QUOTER_QUERY_PARAMS.VEHICLE_VERSION}`, search);
				}
			}

			if(mediatorSrcParam && mediatorSrcParam === Util.KEY.EXTERNAL_SRC.RTR) {

				const planType = parseInt(planTypeParam) || undefined;
				
				if(planType && (planType > 0 && planType <= 5) ) {
					dispatch(PlanAction.setPlanConfig(planType));
				} else {
					dispatch(PlanAction.setPlanConfig(1));
				}
				
			}

		}

		const isPersonDataValid = (userNameParam: string, userLastNameParam: string, userSecondLastNameParam: string, userGenderParam: string): boolean => {

			const nameValid = userNameParam && userNameParam.length >= 2 && Util.PATTERN.NAMES.test(userNameParam);
			const lastNameValid = userLastNameParam &&  userLastNameParam.length >= 2  && Util.PATTERN.NAMES.test(userLastNameParam);
			const secondLastnameValid = userSecondLastNameParam && userSecondLastNameParam.length >= 2 && Util.PATTERN.NAMES.test(userSecondLastNameParam);
			const genderValid = userGenderParam && (userGenderParam === '1' || userGenderParam === '2');

			return !!nameValid && !!lastNameValid && !!secondLastnameValid  && !!genderValid;
		}

		const isPersonContactValid = async (userEmailParam: string, userPhoneCodeParam: string, userPhoneNumberParam: string , userBirthdateParam: string, userCivilStatusParam: string):  Promise<boolean> => {
			try {
				let emailValid: boolean = false;
				Util.PATTERN.EMAIL.lastIndex = 0;
				if (Util.PATTERN.EMAIL.test(userEmailParam)) {
					emailValid = await QuoterPersonFeature.validateEmailFeature(userEmailParam);
				} 
				const MIN_LENGTH = 10;
				const phoneNumberValid = userPhoneNumberParam && userPhoneNumberParam.length === MIN_LENGTH && Util.PATTERN.NUMBERS.test(userPhoneNumberParam);
				const phoneCodeValid = userPhoneCodeParam && Util.COUNTRIES.find(c => c.dialCode === userPhoneCodeParam);
				const civilStatusValid = userCivilStatusParam && Object.values(Util.STATUS_CODE.CIVIL_STATUS).find(status => status === parseInt(userCivilStatusParam));
				const birthdateValid = Util.TRANSFORM.DATE.dateToElapsedYears(Util.TRANSFORM.DATE.getDateFromString(userBirthdateParam)) >= Util.CONSTANT.ALLOWED_DATE_OF_BIRTH_RANGE.MAX_DATE;
				
				return  emailValid && !!phoneNumberValid && !!phoneCodeValid && !!civilStatusValid && birthdateValid;

			} catch (e) {
				return false;
			} 
		}

		const isPersonLocationValid = (userLatitudeParam: string, userLongitudeParam: string, userPostalCodeParam: string, userStateParam: string, userStreetParam: string, userNeighborhoodParam: string, userExternalReferenceParam: string) => {
			return !!userLatitudeParam || !!userLongitudeParam || !!userPostalCodeParam || !!userStateParam || !!userStreetParam || !!userNeighborhoodParam || !!userExternalReferenceParam;
		}

		const navigateTo = (pathname:string, search:string ) => {
			navigate({ pathname, search });
		}

		const initialDataUser = async (name: string, lastName: string, secondLastname: string, email: string, gender: string, civilStatus:string, phoneCode:string, phoneNumber:string, birthdate:string, postalCode:string, stateCode:string, latitude: string, longitude: string, street: string, externalReference: string, neighborhood: string, referral: string): Promise<User | undefined> => {
			let user = fillPersonData();

			if (!user.profile || !user.profile.phone || !user.profile.address) return

			user.name = name;
			user.lastName = lastName;
			user.secondLastName = secondLastname;
			user.profile.gender = parseInt(gender);
			user.email = email;
			user.profile.civilStatus = parseInt(civilStatus);
			user.profile.phone.countryCode = { code: phoneCode || '' }
			user.profile.phone.number = phoneNumber;
			user.profile.birthdate = birthdate ? Util.TRANSFORM.DATE.getDateFromString(birthdate) : null;
			user.profile.address.postalCode = postalCode
			user.profile.address.stateCode = parseInt(stateCode);
			user.profile.address.latitude = parseFloat(latitude);
			user.profile.address.longitude = parseFloat(longitude);
			user.profile.address.street = street;
			user.profile.address.externalReference = externalReference;
			user.profile.address.neighborhood = neighborhood;
			user.profile.referral = new Referral(referral);

			if (postalCode) {
				const address = await QuoterPersonFeature.validatePostalCodeFeature(postalCode);
				if (address) {
					user.profile.address.countryCode = address.countryCode;
					user.profile.address.municipalityCode = address.municipalityCode;
				}
			}

			return user;

		}

		const setVehicleData = (brand: string, model: string, year:string, version: string) => {
			try {
				setVehicleBrand(parseInt(brand));
				setVehicleModel(parseInt(model));
				setVehicleYear(parseInt(year));

				if(parseInt(version)) {
					setVehicleVersion(parseInt(version));
				} else {
					setVehicleVersion(parseInt(window.atob(version)));
				}
			} catch (e) {
				console.log(e);
			}
			
		}

		const useMountEffect = (): void => {
			useEffect(() => {
				initParams();
			}, []);
		};
		useMountEffect();

		useEffect(() => {

			const vehicle = fillVehicleData();

			if(!storedVehicle) {

				if(!brandsList.length && vehicleBrand) {
					dispatch(QuoterVehicleAction.getBrands());
				} else if (brandsList.length && vehicleBrand) {
					vehicle.brand = brandsList.find(brand => brand.id === vehicleBrand);
				}
	
				if(!modelsList.length && vehicleModel && vehicle.brand) {
					dispatch(QuoterVehicleAction.getModels(vehicle.brand));
				} else if(modelsList.length && vehicleModel && vehicle.brand) {
					vehicle.model = modelsList.find(model => model.id === vehicleModel);
				}
	
				if(!yearsList.length && vehicleYear && vehicle.model) {
					dispatch(QuoterVehicleAction.getYears(vehicle.model))
				} else if(yearsList.length && vehicleYear && vehicle.model) {
					vehicle.year = yearsList.find(year => year.value === vehicleYear);
				}
	
				if(!versionsList.length && vehicleVersion && vehicle.year) {
					dispatch(QuoterVehicleAction.getVersion(vehicle.year));
				} else if(versionsList.length && vehicleVersion && vehicle.year) {
					vehicle.version = versionsList.find(version => version.id === vehicleVersion);
					dispatch(QuoterVehicleAction.setStoredVehicle(vehicle));
				}
			}
 			
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, pathname, brandsList, modelsList, yearsList, versionsList, vehicleBrand, vehicleModel, vehicleVersion, vehicleYear ]);

	}

	/**
	 * 
	 */
	export const useQuoterParams = () => {
		
		const { search } = useLocation();
		const queryParams = new URLSearchParams(search);
		const [searchParams, setSearchParams] = useSearchParams();

		/**
		 * 
		 * @param _param 
		 * @returns 
		 */
		const getParam = (_param: string): string => {
  		const param = queryParams.get(_param);

			return param || '';
		};

		/**
		 * 
		 * @param _param 
		 * @param _value 
		 */
		const setParam = (_param: string, _value: string): string => {
			queryParams.set(_param, _value);
			return `?${queryParams.toString()}`;
		}

		const updateSearchParams = (param: string, value: string): void => {
			searchParams.set(param, value)
			setSearchParams(searchParams);
		}

		return { search, getParam, setParam, updateSearchParams, queryParams };
	}
}