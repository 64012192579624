import { useState } from "react";

export namespace LoadingFactory {

  /**
   * 
   * @param defaultValue 
   * @returns 
   */
  export const useLoader = (defaultValue?: boolean): {value: boolean, set: React.Dispatch<React.SetStateAction<boolean>>} => {
    const [loaderState, setLoaderState] = useState<boolean>(defaultValue? defaultValue : false);

    return { value: loaderState, set: setLoaderState }
  }

}