import { PropsWithChildren } from "react";
import { Text } from "src/components";
import './QuoterContent.scss';
import { Util } from "src/utils/Util";

/**
 *
 */
interface IQuoterContent {

  title: string;

  subtitle?: string;

  subtitleType?: 'subtitle' | 'paragraph' | 'label',

  message?: string;

  backButton?: React.ReactNode;
}

/**
 *
 */
const QuoterContent = (props: PropsWithChildren<IQuoterContent>): JSX.Element => {
  const {
    title,
    subtitle,
    message,
    subtitleType = 'subtitle',
    backButton,
  } = props;

  return (
    <div className="quoterContent">

      <section className="quoterContent__section quoterContent__section--header">
        {
          backButton
        }
        <div className="quoterContent__section__transitioner">
          <div className="quoterContent__section__helper--mobile">
            <img alt="Helper" src={Util.ASSET.HELPER} />
          </div>
          <Text type="title" align="center" className="quoterContent__section__title">{title}</Text>
          {
            subtitle &&
            <Text type={subtitleType} align="center" className="quoterContent__section__subtitle">{subtitle}</Text>
          }

          {
            message &&
            <Text type="label" align="center" block className="quoterContent__section__message">{message}</Text>
          }
        </div>

      </section>

      <section className="quoterContent__section quoterContent__section--content">

        <div className="quoterContent__section__transitioner quoterContent__section__transitioner--delay">
          {props.children}
        </div>
      </section>
    </div>
  );
};

export default QuoterContent;