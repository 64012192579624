import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Address } from "src/classes/person/Address";
import { Phone } from "src/classes/person/Phone";
import { Profile } from "src/classes/person/Profile";
import { User } from "src/classes/person/User";
import { QUOTER_PATH, QUOTER_QUERY_PARAMS } from "src/routes/paths/quoter.paths";
import { QuoterPersonAction } from "../actions/quoter-person.action";
import { IQuoterPersonDataForm } from "../interfaces/IQuoterPersonDataForm";
import { QuoterPersonSelector } from "../selectors/quoter-person.selector";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { TrackingFactory } from "src/factories/tracking.factory";
import { Util } from "src/utils/Util";


/**
 *
 */
export namespace QuoterPersonFacade {

	/**
	 * 
	 */
	export const useFillPerson = () => {

		const storedPerson = useSelector(QuoterPersonSelector.storedPerson);

		/**
		 * 
		 */
		const fillPersonData = (): User => {
			let _person = new User('');
			_person.profile = new Profile();
			_person.profile.phone = new Phone();
			_person.profile.address = new Address();
			
			if (storedPerson) {
				_person.email = storedPerson.email || '';
				_person.name = storedPerson.name || '';
				_person.lastName = storedPerson.lastName || '';
				_person.secondLastName = storedPerson.secondLastName || '';

				if (storedPerson.profile) {
					_person.profile.rfc = storedPerson.profile.rfc || '';
					_person.profile.birthdate = storedPerson.profile.birthdate || null;
					_person.profile.gender = storedPerson.profile.gender >= 0 ? storedPerson.profile.gender : -1;
					_person.profile.civilStatus = storedPerson.profile.civilStatus;
					_person.profile.nationalityCode = storedPerson.profile.nationalityCode >= 0 ? storedPerson.profile.nationalityCode : -1;
					_person.profile.originCountry = storedPerson.profile.originCountry >= 0 ? storedPerson.profile.originCountry : -1;
					_person.profile.originCountryState = storedPerson.profile.originCountryState >= 0 ? storedPerson.profile.originCountryState : -1;
					_person.profile.economicActivity = storedPerson.profile.economicActivity >= 0 ? storedPerson.profile.economicActivity : -1;

					if (storedPerson.profile.phone)
						_person.profile.phone = storedPerson.profile.phone;

					if (storedPerson.profile.address)
						_person.profile.address = storedPerson.profile.address;
					
					if (storedPerson.profile.referral)
						_person.profile.referral = storedPerson.profile.referral;
				}
			}

			return _person
		};

		return { fillPersonData };
	};

  /**
   * 
   */
  export const useQuoterPerson = () => {
		const { fillPersonData } = useFillPerson();
		const { search, setParam } = QuoterParamsFacade.useQuoterParams();
		const navigate = useNavigate();
		const dispatch = useDispatch();
		 
		/**
		 * 
		 */
		const onNavigate = (personForm: IQuoterPersonDataForm) => {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.personData
				});
				
				if (personForm.gender) {
					const genderName: string = personForm.gender === Util.STATUS_CODE.GENDER.MALE? 'Masculino' : 'Femenino';
					TrackingFactory.Tracker.track({
						event: TrackingFactory.EVENTS.gender.name,
						[TrackingFactory.EVENTS.gender.property]: genderName
					});
				}

				let _params: string = '';
				_params = setParam(QUOTER_QUERY_PARAMS.USER_NAME, personForm.name);
				_params = setParam(QUOTER_QUERY_PARAMS.USER_LASTNAME, personForm.firstLastname);
				_params = setParam(QUOTER_QUERY_PARAMS.USER_SECOND_LASTNAME, personForm.secondLastname);
				_params = setParam(QUOTER_QUERY_PARAMS.USER_GENDER, personForm.gender.toString());
				navigate(`${QUOTER_PATH.QUOTER_EMAIL_PATH}${_params}`);
			
		};

		/**
		 * 
     * @param personForm
     */
		const setPersonValues = (personForm: IQuoterPersonDataForm): Promise<void> => {
			return new Promise(
				(resolve) => {
					let _person = fillPersonData();
					if(personForm.name) {
						_person.name = personForm.name;
					}
		
					if(personForm.firstLastname) {
						_person.lastName = personForm.firstLastname;
					}
		
					if(personForm.secondLastname) {
						_person.secondLastName = personForm.secondLastname;
					}

					if (personForm.gender && _person.profile) {
						_person.profile.gender = personForm.gender;
					}

					dispatch(QuoterPersonAction.setStoredPerson(_person));
					resolve();
				}
			)
		};

		/**
		 * 
		 * @param name 
		 * @returns 
		 */
		const validateNames = (name: string): boolean => {
			let occurrencies = 0;
			for(const c of name)
				if (c === '-' || c === '`' || c === ',' || c === '.' || c === "'")
					occurrencies ++;
			
			if (occurrencies > 1) 
				return false;
			else 
				return true;
		}

		return { onNavigate, setPersonValues, search, validateNames }
  }

}