export const statusCode = {

  STRIPE_ERROR_CODE: {
    DECLINED: {
      CODE: 'card_declined',
      DECLINE_CODE: {
        INSUFFICIENT_FUNDS: 'insufficient_funds',
      }
    },
    EXPIRED: 'expired_card',
    INCORRECT_CVC: 'incorrect_cvc',
    PROCESSING: 'processing_error'
  },

	PAYMENT_ERROR_CODE: {
		INVALID_CARD: {
			CODE: 'invalid_card',
			DECLINE_CODE: 'installment_not_available'
		}
	},

  PLAN_TYPE: {
    SUBSCRIPTION: 'cfb3737f-fac8-45eb-9830-d071c53c9ebd',
    ANNUAL: '198ecffe-736f-4786-89fd-c0be764609c2',
		MONTHLY: '63f9bd2f-763a-4d16-8013-5609537417cb'
  },
  
	POLICY_PAYMENT_STATUS: {
    UNKNOW: 0,
    ACTIVE: 1,
    GRACE_PERIOD: 2,
    CANCELLED: 3
  },

	GENDER: {
		MALE: 1,
		FEMALE: 2
	},

  CIVIL_STATUS: {
    SINGLE: 1,
    MARRIED: 2,
    DIVORCED: 4,
    OTHER: 9,
  },

	CITIZENSHIP: {
		FOREIGNER: 'Extranjero'
	},

	PLAN_TYPES: {
		ANNUAL: 'ANNUAL',
		SUBSCRIPTION: 'SUBSCRIPTION',
		INSTALLMENTS: 'INSTALLMENTS'
	}
}