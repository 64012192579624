import { RootState } from "src/store/store";

/**
 *
 */
export namespace QuoterVehicleSelector {

	export const storedVehicle = (state: RootState) => state.quoterVehicle?.vehicle!;

  export const vehicleBrandsList = (state: RootState) => state.quoterVehicle?.brands!;

  export const vehicleModelsList = (state: RootState) => state.quoterVehicle?.models!;

  export const vehicleYearsList = (state: RootState) => state.quoterVehicle?.years!;

  export const vehicleVersionsList = (state: RootState) => state.quoterVehicle?.version!;

	export const isLoadingBrands = (state: RootState) => state.quoterVehicle?.isLoadingBrands!;

	export const isLoadingModels = (state: RootState) => state.quoterVehicle?.isLoadingModels!;

	export const isLoadingYears = (state: RootState) => state.quoterVehicle?.isLoadingYears!;

	export const isLoadingVersions = (state: RootState) => state.quoterVehicle?.isLoadingVersions!;
}