import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { TrackingFactory } from "src/factories/tracking.factory";
import { QUOTER_PATH, QUOTER_QUERY_PARAMS, VEHICLE_ROUTE_ID } from "src/routes/paths/quoter.paths";
import { QuoterVehicleAction } from "../actions/quoter-vehicle.action";
import { QuoterVehicleSelector } from "../selectors/quoter-vehicle.selector";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterFacade } from "./quoter.facade";

/**
 *
 */
export namespace QuoterVehicleFacade {

	/**
	 * 
	 */
	export enum VEHICLE_STEPS {
		YEAR = 'YEAR',
		BRAND = 'BRAND',
		MODEL = 'MODEL',
		VERSION = 'VERSION'
	};
	const STEPS_ORDER = [VEHICLE_STEPS.BRAND, VEHICLE_STEPS.MODEL, VEHICLE_STEPS.YEAR, VEHICLE_STEPS.VERSION];
	const NAV_DIRECTION = {
		FORWARD: 'forward',
		BACKWARD: 'backward'
	}
	/**
	 * 
	 */
	export const STEPS_TITLES = {
		YEAR: 'ESCRIBE O SELECCIONA EL AÑO DE TU AUTO',
		BRAND: 'ESCRIBE O SELECCIONA LA MARCA DE TU AUTO',
		MODEL: 'ESCRIBE O SELECCIONA EL MODELO',
		VERSION: 'ESCRIBE O SELECCIONA LA VERSIÓN DE TU AUTO'
	};

	/**
	 * 
	 */
	export const useVehicleLists = () => {

		const brandsList = useSelector(QuoterVehicleSelector.vehicleBrandsList);
		const modelsList = useSelector(QuoterVehicleSelector.vehicleModelsList);
		const yearsList = useSelector(QuoterVehicleSelector.vehicleYearsList);
		const versionsList = useSelector(QuoterVehicleSelector.vehicleVersionsList);
		const isLoadingBrands = useSelector(QuoterVehicleSelector.isLoadingBrands);
		const isLoadingModels = useSelector(QuoterVehicleSelector.isLoadingModels);
		const isLoadingYears = useSelector(QuoterVehicleSelector.isLoadingYears);
		const isLoadingVersions = useSelector(QuoterVehicleSelector.isLoadingVersions);

		return { brandsList, modelsList, yearsList, versionsList, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions };
	}

	export const useFillVehicle = () => {
		const { storedVehicle } = QuoterFacade.useQuoterActors();
		/**
		 * 
		 */
		const fillVehicleData = (): Vehicle => {
			let _vehicle = new Vehicle();

			if (storedVehicle) {
				_vehicle.plate = storedVehicle.plate || '';
				_vehicle.vin = storedVehicle.vin || '';

				if (storedVehicle.brand)
					_vehicle.brand = storedVehicle.brand

				if (storedVehicle.model)
					_vehicle.model = storedVehicle.model

				if (storedVehicle.year)
					_vehicle.year = storedVehicle.year

				if (storedVehicle.version)
					_vehicle.version = storedVehicle.version

				if (storedVehicle.isThirdParty !== undefined)
					_vehicle.isThirdParty = storedVehicle.isThirdParty;
			}

			return _vehicle;
		};

		return { fillVehicleData }
	}

	/**
	 * 
	 */
	export const useQuoterVehicle = () => {

		/**
		 * 
		 */
		const { search, setParam } = QuoterParamsFacade.useQuoterParams();
		const { storedVehicle } = QuoterFacade.useQuoterActors();
		const { brandsList, modelsList, yearsList, versionsList } = useVehicleLists();
		const [stepNumber, setStepNumber] = useState<number>(0);
		const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);
		const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
		const { id: urlId } = useParams<string>();
		const dispatch = useDispatch();
		const navigate = useNavigate();

		/**
		 * 
		 */
		const getVehicleBrands = useCallback((): void => {
			dispatch(QuoterVehicleAction.getBrands())
		}, [dispatch])

		/**
		 * 
		 * @param brandId 
		 */
		const onSelectBrand = useCallback((brand: number | undefined) => {
			setVehicle((prevState) => {
				let newVehicle = new Vehicle();
				newVehicle.model = prevState?.model;
				newVehicle.year = prevState?.year;
				newVehicle.version = prevState?.version;

				if (brand === undefined) {
					newVehicle.brand = undefined;
					return newVehicle;
				}

				newVehicle.brand = brandsList?.find(b => b.id === brand);

				if (newVehicle.brand) {
					if (!prevState || !prevState.brand || (prevState.brand.id !== newVehicle.brand.id)) {
						newVehicle.model = undefined;
						newVehicle.year = undefined;
						newVehicle.version = undefined;
						dispatch(QuoterVehicleAction.onItemSelectedFormList('brand'));
						dispatch(QuoterVehicleAction.getModels(newVehicle.brand));
					}
				}

				return newVehicle;
			});
		}, [brandsList, dispatch]);

		/**
		 * 
		 * @param modelId 
		 */
		const onSelectModel = useCallback((model: number | undefined) => {
			setVehicle((prevState) => {
				let newVehicle = new Vehicle();
				newVehicle.brand = prevState?.brand;
				newVehicle.year = prevState?.year;
				newVehicle.version = prevState?.version;

				if (model === undefined) {
					newVehicle.model = undefined;
					return newVehicle;
				}

				newVehicle.model = modelsList?.find(m => m.id === model)

				if (newVehicle.model) {
					if (!prevState || !prevState.model || (prevState.model.id !== newVehicle.model.id)) {
						newVehicle.year = undefined;
						newVehicle.version = undefined;
						dispatch(QuoterVehicleAction.onItemSelectedFormList('model'))
						dispatch(QuoterVehicleAction.getYears(newVehicle.model));
					}
				}

				return newVehicle;
			});

		}, [modelsList, dispatch])

		/**
		 * 
		 * @param yearValue 
		 */
		const onSelectYear = useCallback((yearValue: number | undefined) => {
			setVehicle((prevState) => {
				let newVehicle = new Vehicle();
				newVehicle.brand = prevState?.brand;
				newVehicle.model = prevState?.model;
				newVehicle.version = prevState?.version;

				newVehicle.year = yearsList?.find(y => y.value === yearValue);

				if (yearValue === undefined) {
					newVehicle.year = undefined;
					return newVehicle;
				}

				if (newVehicle.year) {
					if (!prevState || !prevState.year || (prevState.year.value !== newVehicle.year.value)) {
						newVehicle.version = undefined
						dispatch(QuoterVehicleAction.onItemSelectedFormList('year'));
						dispatch(QuoterVehicleAction.getVersion(newVehicle.year));
					}
				}

				return newVehicle;
			});
		}, [yearsList, dispatch]);

		/**
		 * 
		 * @param versionId 
		 */
		const onSelectVersion = useCallback((versionId: number | undefined) => {
			setVehicle((prevState) => {
				if (!prevState)
					return;

				let newVehicle = new Vehicle();
				newVehicle.brand = prevState?.brand;
				newVehicle.model = prevState?.model;
				newVehicle.year = prevState?.year;

				if (versionId === undefined) {
					newVehicle.version = undefined;
					return newVehicle;
				}

				newVehicle.version = versionsList?.find(v => v.id === versionId);
				return newVehicle;
			});

		}, [versionsList, setVehicle])

		/**
		 * 
		 */
		const onSelectData = useCallback((id: number | undefined, step: VEHICLE_STEPS) => {
			if (urlId === VEHICLE_ROUTE_ID.BRAND && step === VEHICLE_STEPS.BRAND) {
				onSelectBrand(id);
			} else if (urlId === VEHICLE_ROUTE_ID.MODEL && step === VEHICLE_STEPS.MODEL) {
				onSelectModel(id);
			} else if (urlId === VEHICLE_ROUTE_ID.YEAR && step === VEHICLE_STEPS.YEAR) {
				onSelectYear(id);
			} else if (urlId === VEHICLE_ROUTE_ID.VERSION && step === VEHICLE_STEPS.VERSION) {
				onSelectVersion(id);
			}
		}, [urlId, onSelectBrand, onSelectModel, onSelectYear, onSelectVersion]);

		/**
		 * 
		 */
		const nextPage = (): void => {
			if (vehicle)
				dispatch(QuoterVehicleAction.setStoredVehicle(vehicle));

			const _params = setPageParams();
			navigate(`${QUOTER_PATH.QUOTER_PERSON_PATH}${_params}`);
		};

		/**
		 * 
		 * @returns 
		 */
		const onBackRoute = (): string => {
			let _route = '';

			if (urlId === VEHICLE_ROUTE_ID.MODEL)
				_route = `${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${VEHICLE_ROUTE_ID.BRAND}${search}`
			else if (urlId === VEHICLE_ROUTE_ID.YEAR)
				_route = `${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${VEHICLE_ROUTE_ID.MODEL}${search}`
			else if (urlId === VEHICLE_ROUTE_ID.VERSION)
				_route = `${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${VEHICLE_ROUTE_ID.YEAR}${search}`

			return _route
		};

		/**
		 * 
		 * @param _step 
		 * @returns 
		 */
		const setPageParams = (_step?: number): string => {
			let _params: string = search;

			if (vehicle) {
				if (_step) {
					if (STEPS_ORDER[_step] === VEHICLE_STEPS.MODEL && vehicle.brand) {
						_params = setParam(QUOTER_QUERY_PARAMS.VEHICLE_BRAND, vehicle.brand.id.toString());
						TrackingFactory.Tracker.track({
							event: TrackingFactory.EVENTS.vehicleBrand.name,
							[TrackingFactory.EVENTS.vehicleBrand.property]: vehicle.brand.name
						});
					} else if (STEPS_ORDER[_step] === VEHICLE_STEPS.YEAR && vehicle.model) {
						_params = setParam(QUOTER_QUERY_PARAMS.VEHICLE_MODEL, vehicle.model.id.toString());
						TrackingFactory.Tracker.track({
							event: TrackingFactory.EVENTS.vehicleModel.name,
							[TrackingFactory.EVENTS.vehicleModel.property]: vehicle.model.name
						});
					} else if (STEPS_ORDER[_step] === VEHICLE_STEPS.VERSION && vehicle.year) {
						_params = setParam(QUOTER_QUERY_PARAMS.VEHICLE_YEAR, vehicle.year.value.toString());
						TrackingFactory.Tracker.track({
							event: TrackingFactory.EVENTS.vehicleYear.name,
							[TrackingFactory.EVENTS.vehicleYear.property]: vehicle.year.value
						});
					}
				} else {
					//This means it will set the last param for going to the next page.
					if (vehicle.version) {
						_params = setParam(QUOTER_QUERY_PARAMS.VEHICLE_VERSION, btoa(vehicle.version.id.toString()));
						TrackingFactory.Tracker.track({
							event: TrackingFactory.EVENTS.vehicleVersion.name,
							[TrackingFactory.EVENTS.vehicleVersion.property]: vehicle.version.description
						});
					}
				}
			}

			return _params
		}

		/**
		 * 
		 */
		const stepHandler = (_step: number, _direction: string) => {
			let _routeId: string = '';
			let _params: string = search;

			if ((STEPS_ORDER[_step] === VEHICLE_STEPS.MODEL) && (urlId !== VEHICLE_ROUTE_ID.MODEL)) {
				_routeId = VEHICLE_ROUTE_ID.MODEL
			} else if ((STEPS_ORDER[_step] === VEHICLE_STEPS.YEAR) && (urlId !== VEHICLE_ROUTE_ID.YEAR)) {
				_routeId = VEHICLE_ROUTE_ID.YEAR;
			} else if ((STEPS_ORDER[_step] === VEHICLE_STEPS.VERSION) && (urlId !== VEHICLE_ROUTE_ID.VERSION)) {
				_routeId = VEHICLE_ROUTE_ID.VERSION;
			}

			if (_direction === NAV_DIRECTION.FORWARD) {
				_params = setPageParams(_step);
			}

			navigate(`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${_routeId}${_params}`, { replace: true });
		}

		/**
		 * 
		 * @returns 
		 */
		const useMountEffect = () => useEffect(
			() => {
				if (urlId) {
					let _stepNumber: number;
					_stepNumber = STEPS_ORDER.indexOf(VEHICLE_STEPS.BRAND);

					if (urlId === VEHICLE_ROUTE_ID.BRAND && brandsList.length === 0) {
						getVehicleBrands();
					} else if (urlId === VEHICLE_ROUTE_ID.MODEL) {
						_stepNumber = STEPS_ORDER.indexOf(VEHICLE_STEPS.MODEL);
					} else if (urlId === VEHICLE_ROUTE_ID.YEAR) {
						_stepNumber = STEPS_ORDER.indexOf(VEHICLE_STEPS.YEAR);
					} else if (urlId === VEHICLE_ROUTE_ID.VERSION) {
						_stepNumber = STEPS_ORDER.indexOf(VEHICLE_STEPS.VERSION);
					}

					setStepNumber(_stepNumber);
				}
			}, []
		);
		useMountEffect();

		/**
		 * 
		 */
		useEffect(() => {
			if (vehicle) {
				const disabledCondition = (urlId === VEHICLE_ROUTE_ID.BRAND && vehicle.brand && vehicle.brand.id) ||
					(urlId === VEHICLE_ROUTE_ID.MODEL && vehicle.model && vehicle.model.id) ||
					(urlId === VEHICLE_ROUTE_ID.YEAR && vehicle.year && vehicle.year.value) ||
					(urlId === VEHICLE_ROUTE_ID.VERSION && vehicle.version && vehicle.version.id);

				if (disabledCondition)
					setIsNextDisabled(false)
				else
					setIsNextDisabled(true)
			} else {
				setIsNextDisabled(false);
			}
		}, [urlId, vehicle, setIsNextDisabled]);

		/**
		 * 
		 */
		useEffect(() => {
			let _step = 0;
			
			if (urlId === VEHICLE_ROUTE_ID.BRAND && VEHICLE_STEPS.BRAND !== STEPS_ORDER[stepNumber]) {
				_step = STEPS_ORDER.indexOf(VEHICLE_STEPS.BRAND)
			}
			else if (urlId === VEHICLE_ROUTE_ID.MODEL && VEHICLE_STEPS.MODEL !== STEPS_ORDER[stepNumber])
				_step = STEPS_ORDER.indexOf(VEHICLE_STEPS.MODEL)
			else if (urlId === VEHICLE_ROUTE_ID.YEAR && VEHICLE_STEPS.YEAR !== STEPS_ORDER[stepNumber])
				_step = STEPS_ORDER.indexOf(VEHICLE_STEPS.YEAR)
			else if (urlId === VEHICLE_ROUTE_ID.VERSION && VEHICLE_STEPS.VERSION !== STEPS_ORDER[stepNumber])
				_step = STEPS_ORDER.indexOf(VEHICLE_STEPS.VERSION)

			setStepNumber(_step);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [urlId]);

		/**
		 * 
		 */
		useEffect(() => {
			if (storedVehicle)
				setVehicle(storedVehicle)
		}, [storedVehicle, setVehicle]);


		useEffect(
			() => {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.quoterInit
				});
			}, []
		)
		
		return { stepNumber, vehicle, isNextDisabled, nextPage, onBackRoute, onSelectData, stepHandler }
	}
}