import { Coverage } from "src/classes/plan/Coverage";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Text } from "src/components";
import { IconFactory } from "src/factories/icon.factory";
import './PlanReviewCoverage.scss';

/**
 *
 */
interface IPlanReviewCoverage {
	/**
	 * 
	 */
	coverage: Coverage
	/**
	 * 
	 */
	vehicle: Vehicle
}

/**
 *
 */
const PlanReviewCoverage = (props: IPlanReviewCoverage): JSX.Element => {

  const { coverage, vehicle } = props;

  return (
    <div className="planReviewCoverage">
			<section className="planReviewCoverage__description">
				<div className="planReviewCoverage__description__image" style={ { backgroundImage: `url(${coverage.image})` } } />

				<div className="planReviewCoverage__description__text">
					<Text type="paragraph" align="left" bold uppercase>{coverage.name}</Text>
					<Text type="paragraph" align="left">{coverage.description}</Text>
					<br />
					<div className="planReviewCoverage__description__text__details">
						<Text type="label" align="center">
							{ coverage.isDeductible && !coverage.assured && 'Deducible de '}
							{ coverage.isDeductible && coverage.assured && 'Cobertura de '}
							{ !coverage.isDeductible && !coverage.assured && 'Incluído' }
							{ coverage.getCoverageDetails(vehicle) }
						</Text>
					</div>

					<div>
						<ul className="planReviewCoverage__description__text__list">
							{
								coverage.includeList.map((value, index) => 
									<li key={`${coverage.id}-includeList-${index}`} className="planReviewCoverage__description__text__list__item">
										<span>{IconFactory.checkIcon()}</span>
										<Text type="label" className="planReviewCoverage__description__text__list__item__value">{value}</Text>
									</li>
								)
							}
						</ul>
					</div>
				</div>
			</section>
    </div>
  );
};

export default PlanReviewCoverage;