import { Outlet } from 'react-router-dom';
import { QuoterGooglesheetsFacade } from '../../controller/facades/quoter-googlesheets.facade';
import { QuoterFacade } from '../../controller/facades/quoter.facade';
import QuoterHeader from '../components/QuoterHeader/QuoterHeader';
import './Quoter.scss';

/**
 *
 */
const QuoterPage = (): JSX.Element => {
	const { currentStep, totalPaths } = QuoterFacade.useQuoterFacade();
	QuoterGooglesheetsFacade.useGooglesheetFacade();

  return (
		<div className="quoter">
			<QuoterHeader currentStep={currentStep} totalSteps={totalPaths}/>

			<div className="quoter__routes">
				<Outlet />
			</div>


		</div>
  );
};

export default QuoterPage;