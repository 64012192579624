import { QuoterRoutes } from "src/modules/quoter/routes/quoter.routes";


export const publicRoutes = () => {
  
  return [
    {
      ...QuoterRoutes()
    },
  ];
}