import { Address } from "src/classes/person/Address";
import { QuoterPersonAdapter } from "../adapters/quoter-person.adapter";
import { ICountry } from "../interfaces/ICountry";
import { ICountryState } from "../interfaces/ICountryState";
import { IEconomicActivity } from "../interfaces/IEconomicActivity";
import { QuoterPersonService } from "../services/quoter-person.service";

/**
 *
 */
export namespace QuoterPersonFeature {

	/**
	 * 
	 * @returns 
	 */
	export const getCountriesFeature = async(): Promise<ICountry[]> => {
		try {
			const { data } = await QuoterPersonService.getCountries();
			const adaptedData = QuoterPersonAdapter.getCountriesDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
	
	/**
	 * 
	 * @param countryId 
	 * @returns 
	 */
	export const getCountryStatesFeature = async(countryId: number): Promise<ICountryState[]> => {
		try {
			const { data } = await QuoterPersonService.getCountryStates(countryId);
			const adaptedData = QuoterPersonAdapter.getCountryStatesDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	/**
	 * 
	 * @returns 
	 */
	export const getEconomicActivitiesFeature = async(): Promise<IEconomicActivity[]> => {
		try {
			const { data } = await QuoterPersonService.getEconomicActivities();
			const adaptedData = QuoterPersonAdapter.economicActivitiesDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	/**
	 * 
	 */
	export const getOwnBusinessActivitiesFeature = async(): Promise<IEconomicActivity[]> => {
		try {
			const { data } = await QuoterPersonService.getOwnBusinessActivities();
			const adaptedData = QuoterPersonAdapter.ownBusinessDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	/**
   * 
   * @returns 
   */
	export const validatePostalCodeFeature = async (postalCode: string): Promise<Address | undefined> => {
		try {
			const { data } = await QuoterPersonService.validatePostalCodeService(postalCode);
			if (data.postal_entities) {
				const address = QuoterPersonAdapter.postalEntitiesDataAdapter(data);
				return address;
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	/**
   * 
   * @returns 
   */
	export const validateEmailFeature = async (email: string): Promise<boolean> => {
		try {
			const { data } = await QuoterPersonService.validateEmailService(email);
			return !data.is_in_use;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	/**
   * 
   * @returns 
   */
	export const validateRfcFeature = async (rfc: string): Promise<boolean> => {
		try {
			const { data } = await QuoterPersonService.validateRfcService(rfc);
			return !data.is_in_use;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}