import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { Policy } from "src/classes/policy/Policy";
import { QuoterAction } from "../actions/quoter.action";
import { QuoterSlice } from "../slices/quoter.slice";

/**
 *
 */
export namespace QuoterSaga {

  /**
   * 
   * @param action 
   */
  function* setPolicy(action: PayloadAction<Policy>) {
    yield put(QuoterSlice.actions.policy(action.payload));
  }

  /**
   * 
   * @param action 
   */
  function* setTemporalAuth(action: PayloadAction<boolean>) {
    yield put(QuoterSlice.actions.temporalAuth(action.payload));
  }

  /**
   * 
   */
  export function* quoterSaga() {
    yield takeLatest(QuoterAction.setPolicy.type, setPolicy);
    yield takeLatest(QuoterAction.temporalAuth.type, setTemporalAuth);
  }

}