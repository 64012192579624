import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "src/classes/person/User";
import { IQuoterPersonStateReducer } from "src/store/states/modules-state/IQuoterPersonStateReducer";

/**
 *
 */
export namespace QuoterPersonSlice {
	/**
	 * 
	 */
	const initialState: IQuoterPersonStateReducer = {
		user: undefined,
	};

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const clear: CaseReducer<IQuoterPersonStateReducer> = (state) => {
		state.user = undefined;
	}

	/**
   * 
   * @param state 
   * @param action 
   */
	const setUser: CaseReducer<IQuoterPersonStateReducer, PayloadAction<User>> = (state, action) => {
		state.user = action.payload;
	}

	const reducers = {
		clear,
		setUser
	};

	/**
	 * 
	 */
	const slice = createSlice({
		name: 'quoterPerson',
		initialState,
		reducers: reducers,
	});

	export const { reducer, actions } = slice;
}