import { useState, useEffect, useRef } from "react";

export namespace DebounceFactory {

	/**
	 * 
	 * @param value 
	 * @param delay 
	 * @returns 
	 */
	export const useDebounce = <T,>(value: T, delay?: number): T => {
		const [debouncedValue, setDebouncedValue] = useState<T>(value);
		useEffect(() => {
			//create a timer to delay setting the value.
			const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
			//if the value changes, we clear the timeout and do not change the value
			return () => {
				clearTimeout(timer);
			};
		}, [value, delay]);
		return debouncedValue;
	}

	/**
	 * 
	 * @param callback 
	 * @param wait 
	 * @returns 
	 */
	export const useDebouncedCallback = <A extends any[]>(callback: (...args: A) => void, wait: number) => {
		// track args & timeout handle between calls
		const argsRef = useRef<A>();
		const timeout = useRef<ReturnType<typeof setTimeout>>();
	
		function cleanup() {
			if(timeout.current) {
				clearTimeout(timeout.current);
			}
		}
	
		// make sure our timeout gets cleared if
		// our consuming component gets unmounted
		useEffect(() => cleanup, []);
	
		return function debouncedCallback(
			...args: A
		) {
			// capture latest args
			argsRef.current = args;
	
			// clear debounce timer
			cleanup();
	
			// start waiting again
			timeout.current = setTimeout(() => {
				if(argsRef.current) {
					callback(...argsRef.current);
				}
			}, wait);
		};
	}

}