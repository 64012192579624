import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { AlertFactory } from "src/factories/alert.factory";
import { PlanAction } from "../actions/plan.action";
import { PlanFeature } from "../features/plan.feature";
import { IPlanManagement } from "../interfaces/IPlanManagement";
import { PlanSelector } from "../selectors/plan.selector";
import { PlanSlice } from "../slices/plan.slice";

/**
 *
 */
export namespace PlanSaga {

  type Plans = SagaReturnType<typeof PlanFeature.quotationFeature>;

  /**
   * 
   * @param action 
   */
  function* setCoverages(action: PayloadAction<Coverage[]>) {
    yield put(PlanSlice.actions.coverages(action.payload));
  }


  /**
   * 
   * @param action 
   */
  function* getQuotation(action: PayloadAction<IPlanManagement>) {
    try {
      const isUpdating: boolean = yield select(PlanSelector.isUpdatingPlan);
      if (!isUpdating) {
        const isCustomizing: boolean = yield select(PlanSelector.isCustomizingPlan);
        if (isCustomizing) {
          yield put(PlanSlice.actions.isUpdating(true));
        } else if (action.payload.loadAll) {
          yield put(PlanSlice.actions.isLoading(true));
        }

        if (action.payload.coupon && action.payload.coupon !== '') {
          yield put(PlanSlice.actions.currentCoupon(action.payload.coupon));
        } else if (action.payload.coupon === '') {
          yield put(PlanSlice.actions.currentCoupon(''));
        }

        const coupon: string = yield select(PlanSelector.currentCoupon);

        const management: IPlanManagement = { 
          vehicle: action.payload.vehicle, 
          user: action.payload.user, 
          coverages: action.payload.coverages, 
          coupon: coupon,
          planType: action.payload.planType
        }

        const plans: Plans = yield call(PlanFeature.quotationFeature, management);
        if (plans) {
          yield put(PlanSlice.actions.plansList(plans as Plan[]));
          yield put(PlanSlice.actions.setCurrentPlan(plans[0] as Plan));
        }

        if (action.payload.coverages) {
          yield put(PlanSlice.actions.coverages(action.payload.coverages));
        } else {
          yield put(PlanSlice.actions.coverages([]));
        }

      }

    } catch (e: any) {
      // Coupon Error null
      if ((e as Error).name === '4000') {
        yield put(PlanSlice.actions.currentCoupon(''));
        AlertFactory.errorAlert(e.message);
        yield put(PlanSlice.actions.isUpdating(false));
      }
      // End Coupon Error null
      else if ((e as Error).name === '4001') {
        AlertFactory.errorAlert(e.message);
        window.history.go(-1);
      }
      else {
        yield put(PlanSlice.actions.plansList([]));
      }
    } finally {
      yield put(PlanSlice.actions.isLoading(false));
    }
  }

  /***
   * 
   */
  function* setUpdating(action: PayloadAction<boolean>) {
    yield put(PlanSlice.actions.isUpdating(action.payload));
  }

  /***
   * 
   */
  function* customizationState(action: PayloadAction<boolean>) {
    yield put(PlanSlice.actions.isCustomizing(action.payload));
  }

  /**
   * 
   */
  function* clearManagement() {
    yield put(PlanSlice.actions.clear());
  }

  /**
   * 
   */
  function* setPlanConfiguration(action: PayloadAction<IPlanManagement>) {
    yield put(PlanSlice.actions.setPlanConfiguration(action.payload));
  }

  /**
   * 
   */
  function* setCurrentPlan(action: PayloadAction<Plan>) {
    yield put(PlanSlice.actions.setCurrentPlan(action.payload));
  }

    /**
   * 
   */
    function* setPlanConfig(action: PayloadAction<number>) {
      yield put(PlanSlice.actions.setPlanConfig(action.payload));
    }

  export function* planManagementSaga() {
    yield takeLatest(PlanAction.coverages.type, setCoverages);
    yield takeLatest(PlanAction.quotation.type, getQuotation);
    yield takeLatest(PlanAction.customizationState.type, customizationState);
    yield takeLatest(PlanAction.clearManagement.type, clearManagement);
    yield takeLatest(PlanAction.planConfiguration.type, setPlanConfiguration);
    yield takeLatest(PlanAction.currentPlan.type, setCurrentPlan);
    yield takeLatest(PlanAction.setUpdating.type, setUpdating);
    yield takeLatest(PlanAction.setPlanConfig, setPlanConfig);
  }
}