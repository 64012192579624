import React from 'react';
import './Checkbox.scss';

/**
 *
 */
interface ICheckboxProps {

  position?: 'start' | 'end';
}

/**
 *
 */
export const Checkbox = React.forwardRef(
  (
    props: ICheckboxProps & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.Ref<any>
  ): JSX.Element => {

    const {
      children,
      ...rest
    } = props;

    return (
      <div className="checkboxWrapper" position-end={props.position === 'end' ? '' : null}>
        <input type="checkbox" {...rest} ref={ref} />
        <span className="checkboxWrapper__checkMark"></span>
        <span className="checkboxWrapper__checkText">{props.children}</span>
      </div>
    );
  }
);