import { PropsWithChildren, useEffect } from "react";
import './Modal.scss';

/**
 *
 */
interface IModal {

  show: boolean;

  position: 'top' | 'center' | 'bottom';

  onClose?: () => void;

  backgroundDismiss?: boolean;
}

/**
 *
 */
export const Modal = (props: PropsWithChildren<IModal>): JSX.Element => {


  /**
   * 
   */
  const onHandleBackgroundClick = (): void => {
    if (props.backgroundDismiss === true && props.onClose) {
      props.onClose();
    }
  }

  /**
   * 
   */
  useEffect(
    () => {
      const scrollBarWidth = window.innerWidth - document.body.clientWidth;
      if (props.show) {
        document.body.style.width = `${window.innerWidth - scrollBarWidth}px`;
        document.body.style.overflow = 'hidden';
      }
      else {
        document.body.removeAttribute('style');
      }

      return () => {
        document.body.removeAttribute('style');
      }
    }, [props.show]
  );

  return (
    <>
      {
        props.show &&
        <div className={`modalWrapper modalWrapper--${props.position}`}>
          <div className="modalWrapper__background-overlay" onClick={(e) => { e.preventDefault(); onHandleBackgroundClick() }}></div>
          <div className={`modalWrapper__content modalWrapper__content--${props.position}`}>
            {props.children}
          </div>
        </div>
      }
    </>
  );
};