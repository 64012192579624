import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Util } from 'src/utils/Util';
import { Text } from 'src/components/atoms';
import './InputDate.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/**
 *
 */
interface IInputDate extends React.InputHTMLAttributes<HTMLInputElement> {

	validateConsent?: boolean;
	/**
	 * 
	 */
	errors?: boolean;
	/**
	 * 
	 */
	required?: boolean;
	/**
	 * 
	 */
	icon?: JSX.Element;
	/**
	 * 
	 */
	disabled?: boolean;
  /**
   * 
   */
  position?: 'bottom' | 'top';
	/**
	 * 
	 */
	onSelectDate?: (newDate?: Date) => void;
}

/**
 *
 */
export const InputDate = (props: IInputDate): JSX.Element => {

	/**
	 * 
	 */
	const {
		errors = false,
		children,
		position = 'bottom',
		onSelectDate,
		defaultValue,
		disabled,
		validateConsent,
	} = props;

	const minDate = useMemo(
		() => {
			const d = new Date();
			return new Date(d.getFullYear() - Util.CONSTANT.ALLOWED_DATE_OF_BIRTH_RANGE.MIN_DATE, d.getMonth(), d.getDate());
		}, []
	);

	const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
	const [date, setDate] = useState<Date>(
		() => {
			const date = new Date();
			return validateConsent ? new Date(date.getFullYear() - Util.CONSTANT.ALLOWED_DATE_OF_BIRTH_RANGE.MAX_DATE, date.getMonth(), date.getDate()) : date;
		}
	);
	const [selectedDate, setSelectedDate] = useState<string>('');

	const labelClasses = classNames({
		'inputDate__label--active': (selectedDate.length > 0),
	});
	const inputWrapperClassNames = classNames({
		'inputDate--invalid': errors ? true : false,
		'inputDate--disabled': disabled,
		'inputDate--focused': displayCalendar
	});

	/**
	 * 
	 * @param newDate 
	 */
	const onDateChange = (newDate: Date): void => {
		if (newDate && isNaN(Date.parse(newDate.toDateString())) === false) {
			setDate(newDate);
			setDisplayCalendar(false);
			setSelectedDate(Util.TRANSFORM.DATE.formatDate(newDate));
			if (onSelectDate) {
				onSelectDate(newDate);
			}
		} else {
			setDate(new Date());
		}
	}

	/**
	 * 
	 * @returns 
	 */
	const validateDateConsent = (): Date | undefined => {
		if (validateConsent) {
			const d = new Date();
			return new Date(d.getFullYear() - Util.CONSTANT.ALLOWED_DATE_OF_BIRTH_RANGE.MAX_DATE, d.getMonth(), d.getDate());
		}
	}

	/**
	 * 
	 */
	// const checkDate = useCallback((): void => {
	// 	const incompletedDate = /[A-Z]/.test(selectedDate);

	// 	if (!incompletedDate) {
	// 		const day = +selectedDate.split('/')[0],
	// 			month = +selectedDate.split('/')[1],
	// 			year = +selectedDate.split('/')[2];

	// 		const newDate = new Date(year, month - 1, day);
	// 		setDate(newDate);
	// 		if (onSelectDate)
	// 			onSelectDate(newDate);
	// 		if (displayCalendar)
	// 			setDisplayCalendar(false);
	// 	} else {
	// 		if (onSelectDate)
	// 			onSelectDate(undefined);
	// 	}
	// }, [selectedDate, displayCalendar, onSelectDate]);

	// Detect when click component
	const wrapperRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const closeCalendar = (event: MouseEvent) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				if (displayCalendar) {
					setDisplayCalendar(false);
				}
			} else {
				setDisplayCalendar(true);
			}
		}

		document.addEventListener("mousedown", closeCalendar);
		return () => {
			document.removeEventListener("mousedown", closeCalendar);
		};
	}, [wrapperRef, displayCalendar]);
	// end Detection

	/**
	 * 
	 */
	useEffect(
		() => {
			if (defaultValue && defaultValue !== '' && !isNaN(Date.parse(defaultValue as string))) {
				const newDate = new Date(defaultValue as string);
				setDate(newDate);
				setSelectedDate(Util.TRANSFORM.DATE.formatDate(newDate));
			}
		}, [defaultValue]
	);

	return (
		<div className={`inputDate ${inputWrapperClassNames}`} ref={wrapperRef}>
			<div className={`inputDate__label ${labelClasses}`}>
				<Text type="label">{children}</Text>
			</div>

			<div className="inputDate__selectedDate">
				<Text type="paragraph" className="inputDate__selectedDate__text">{selectedDate}</Text>
			</div>

			{/* <div className="inputDate__inputWrapper">
				<ReactInputMask
					mask="99/99/9999"
					alwaysShowMask={true}
					maskPlaceholder="DD/MM/AAAA"
					value={selectedDate}
					className="inputDate__inputWrapper__input"
					onChange={(e) => setSelectedDate(e.target.value)}
					//onBlur={(e) => checkDate(e)}
					{...rest}
				//ref={ref} 
				/>
			</div> */}

			{displayCalendar &&
				<Calendar className={`inputDate__calendarWrapper ${position}`}
					maxDate={validateDateConsent()}
					minDate={minDate}
					date={date}
					onChange={onDateChange}
				/>
			}
		</div>
	);
};