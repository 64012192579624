import { Address } from "src/classes/person/Address";
import { IAddressData } from "../interfaces/IAddressData";
import { ICountriesData } from "../interfaces/ICountriesData";
import { ICountry } from "../interfaces/ICountry";
import { ICountryState } from "../interfaces/ICountryState";
import { ICountryStatesData } from "../interfaces/ICountryStatesData";
import { IEconomicActivity } from "../interfaces/IEconomicActivity";
import { IEconomicActivityData } from "../interfaces/IEconomicActivityData";
import { IOwnBusinessData } from "../interfaces/IOwnBusinessData";

/**
 * 
 */
export namespace QuoterPersonAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const getCountriesDataAdapter = (data: ICountriesData): ICountry[] => {
		let countries = data.countries;
		let returningData: ICountry[] = [];

		returningData = [...countries];

    return returningData;
  };

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const getCountryStatesDataAdapter = (data: ICountryStatesData): ICountryState[] => {
		let states = data.states;
		let returningData: ICountryState[] = [];

		returningData = [...states];

    return returningData;
  };

	/**
   * 
   * @param data 
   * @returns 
   */
	export const economicActivitiesDataAdapter = (data: IEconomicActivityData): IEconomicActivity[] => {
		let dataEntities = data.economic_activities;
		let returningData: IEconomicActivity[] = [];
		if(dataEntities[0].id === undefined)
			dataEntities[0].id = 0;

		dataEntities.forEach((item) => {
			let newItem: IEconomicActivity = {
				id: item.id,
				value: item.description
			}

			returningData.push(newItem);
		});

		return returningData;
	};

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const ownBusinessDataAdapter = (data: IOwnBusinessData): IEconomicActivity[] => {
		let dataEntities = data.complex_economic_activities;
		let returningData: IEconomicActivity[] = [];

		dataEntities.forEach((item) => {
			let newItem: IEconomicActivity = {
				id: item.id,
				value: item.description
			}

			returningData.push(newItem);
		})

		return returningData;
	};

	/**
   * 
   * @param data 
   * @returns 
   */
	export const postalEntitiesDataAdapter = (data: IAddressData): Address => {
		const dataEntities = data.postal_entities[0];
		let address: Address = new Address(dataEntities.postal_code, dataEntities.country_code, dataEntities.state_code, dataEntities.municipality_sepomex_code);

		return address;
	}

}