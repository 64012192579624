import { Dropdown, BackButton } from 'src/components';
import { QuoterVehicleFacade } from 'src/modules/quoter/controller/facades/quoter-vehicle.facade';
import InputsGroup from '../../components/InputsGroup/InputsGroup';
import QuoterContent from "../../components/QuoterContent/QuoterContent";

import './QuoterVehicle.scss';

/**
 *
 */
const QuoterVehiclePage = (): JSX.Element => {

	/**
	 * 
	 */
	const { brandsList, modelsList, yearsList, versionsList, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions } = QuoterVehicleFacade.useVehicleLists();
	const { stepNumber, isNextDisabled, vehicle, nextPage, onBackRoute, onSelectData, stepHandler } = QuoterVehicleFacade.useQuoterVehicle();

  return (
    <div className="quoterVehicle">
			<QuoterContent
				title="¡Hola! 👋 soy María"
				subtitle="Cotizar es súper rápido ¿Empezamos?"
				backButton={ stepNumber > 0 ? <BackButton to={onBackRoute()} /> : undefined }
			>
			<InputsGroup
				elements={[
					{
						title: QuoterVehicleFacade.STEPS_TITLES.BRAND,
						reactElement: 					
							<Dropdown 
								items={brandsList.map((b) => ({ id: b.id, value: b.name }) )} 
								placeholder="MARCA"
								defaultValue={vehicle && vehicle.brand ? { id: vehicle.brand.id, value: vehicle.brand.name } : undefined}
								onChange={(item) => onSelectData(item && item.value ? item.id : undefined, QuoterVehicleFacade.VEHICLE_STEPS.BRAND)} 
								dropdownPlace="top"
								hasDeleteButton
								isLoading={brandsList.length === 0 || isLoadingBrands}
								disabled={brandsList.length === 0 || isLoadingBrands}
								notFoundText="No se encontró la marca."
							/>
					},
					{
						title: QuoterVehicleFacade.STEPS_TITLES.MODEL,
						reactElement:
							<Dropdown 
								items={modelsList.map((m) => ({ id: m.id, value: m.name }) )} 
								placeholder="MODELO"
								defaultValue={vehicle && vehicle.model ? { id: vehicle.model.id, value: vehicle.model.name } : undefined}
								onChange={(item) => onSelectData(item && item.value ? item.id : undefined, QuoterVehicleFacade.VEHICLE_STEPS.MODEL)} 
								dropdownPlace="top"
								hasDeleteButton
								isLoading={modelsList.length === 0 || isLoadingModels}
								disabled={modelsList.length === 0 || isLoadingModels}
								notFoundText="No se encontró el modelo."
							/>
					},
					{
						title: QuoterVehicleFacade.STEPS_TITLES.YEAR,
						reactElement:
							<Dropdown 
								items={yearsList.map((y) => ({ id: y.value, value: y.value.toString() }) )} 
								placeholder="AÑO"
								number={true}
								defaultValue={vehicle && vehicle.year ? { id: vehicle.year.value, value: vehicle.year.value.toString() } : undefined}
								onChange={(item) => onSelectData(item && item.value ? +item.value : undefined, QuoterVehicleFacade.VEHICLE_STEPS.YEAR)} 
								dropdownPlace="top"
								hasDeleteButton
								isLoading={yearsList.length === 0 || isLoadingYears}
								disabled={yearsList.length === 0 || isLoadingYears}
								notFoundText="No se encontró el año."
							/>
					},
					{
						title: QuoterVehicleFacade.STEPS_TITLES.VERSION,
						reactElement:
							<Dropdown 
								items={versionsList.map((v) => ({ id: v.id, value: v.description }) )} 
								placeholder="VERSIÓN"
								defaultValue={vehicle && vehicle.version ? { id: vehicle.version.id, value: vehicle.version.description } : undefined}
								onChange={(item) => onSelectData(item && item.value ? item.id : undefined, QuoterVehicleFacade.VEHICLE_STEPS.VERSION)} 
								dropdownPlace="top"
								hasDeleteButton
								isLoading={versionsList.length === 0 || isLoadingVersions}
								disabled={versionsList.length === 0 || isLoadingVersions}
								notFoundText="No se encontró la versión."
							/>
					}
				]}
				defaultStep={stepNumber}
				onStepChange={stepHandler}
				disabledButton={isNextDisabled}
				onComplete={nextPage}
			/>
			</QuoterContent>
    </div>
  );
};

export default QuoterVehiclePage;