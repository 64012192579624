import { Util } from "src/utils/Util";
import { Address } from "./Address";
import { Phone } from "./Phone";
import { Referral } from "../referrals/Referral";

/**
 * 
 */
export class Profile {
	/**
	 * 
	 */
  private _rfc: string;
  get rfc(): string { return this._rfc; }
  set rfc(_rfc: string) { this._rfc = _rfc; }
	/**
	 * 
	 */
  private _birthdate: Date | null;
  get birthdate(): Date | null { return this._birthdate; }
  set birthdate(_birthdate: Date | null) { this._birthdate = _birthdate; }
	/**
	 * 
	 */
  private _gender: number;
  get gender(): number { return this._gender; }
  set gender(_gender: number) { this._gender = _gender; }

  /**
   *
   */
  private _civilStatus: number;
  set civilStatus(_civilStatus: number) { this._civilStatus = _civilStatus };
  get civilStatus(): number { return this._civilStatus };
  /**
   *
   */
  private _address: Address | undefined;
  set address(_address: Address | undefined) { this._address = _address };
  get address(): Address | undefined { return this._address };
  /**
   *
   */
  private _phone: Phone | undefined;
  set phone(_phone: Phone | undefined) { this._phone = _phone };
  get phone(): Phone | undefined { return this._phone };
  /**
   *
   */
  private _referral: Referral | undefined;
  set referral(_referral: Referral | undefined) { this._referral = _referral };
  get referral(): Referral | undefined { return this._referral };
	/**
	 * 
	 */
	private _identityStatus: number;
	set identityStatus(_identityStatus: number) { this._identityStatus = _identityStatus };
	get identityStatus(): number { return this._identityStatus };
	/**
	 * 
	 */
	private _nationalityCode: number;
	set nationalityCode(_nationalityCode: number) { this._nationalityCode = _nationalityCode };
	get nationalityCode(): number { return this._nationalityCode };
	/**
	 * 
	 */
	private _originCountry: number;
	set originCountry(_originCountry: number) { this._originCountry = _originCountry };
	get originCountry(): number { return this._originCountry };
	/**
	 * 
	 */
	private _originCountryState: number;
	set originCountryState(_originCountryState: number) { this._originCountryState = _originCountryState };
	get originCountryState(): number { return this._originCountryState };
	/**
	 * 
	 */
	private _economicActivity: number;
	set economicActivity(_economicActivity: number) { this._economicActivity = _economicActivity };
	get economicActivity(): number { return this._economicActivity };

  constructor (_rfc?: string, _birthDate?: Date, _gender?: number) {
    this._rfc = _rfc? _rfc : '';
    this._birthdate = _birthDate? _birthDate : null;
    this._gender = _gender? _gender : -1;
		this._economicActivity = -1;
		this._identityStatus = -1;
		this._nationalityCode = -1;
		this._originCountry = -1;
		this._originCountryState = -1;
    this._civilStatus = -1;
  }

  /**
   * 
   */
  public getPhoneNumber(): string {
    let _number: string = '';
    if (this._phone && this._phone.countryCode) {
      _number = `${this._phone.countryCode.code.length > 1 ? `(${this._phone.countryCode.code})` : ''}${this._phone.number}`;
    }
    return _number;
  }

  /**
   * 
   */
  public getBirthDate(): string {
    if (this._birthdate) {
      return Util.TRANSFORM.DATE.formatDate(this._birthdate);
    }

    return '--';
  }

  /**
   * 
   */
  public getAddress(): string {
    if (this._address) {
      const fullAddress: string = `${this._address.street}, ${this._address.externalReference}${this._address.internalReference? `, ${this._address.internalReference}.` : '.'} ${this._address.neighborhood}.`
      return `${fullAddress.charAt(0).toUpperCase()}${fullAddress.slice(1)}`;
    }

    return '--';
  }
}