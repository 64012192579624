import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Policy } from "src/classes/policy/Policy";

/**
 *
 */
export namespace QuoterAction {
  /**
   * 
   */
  export const setPolicy: ActionCreatorWithPayload<Policy> = createAction('SET_NEW_POLICY');

  export const temporalAuth: ActionCreatorWithPayload<boolean> = createAction('SET_TEMPORAL_AUTH');

}