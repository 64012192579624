import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Util } from "src/utils/Util";
import { PlanAction } from "../actions/plan.action";
import { IPlanCouponForm } from "../interfaces/IPlanCouponForm";
import { PlanSelector } from "../selectors/plan.selector";


export namespace PlanCouponFacade {

  const COUPON_ERRORS: TFormErrorMessage[] = [
    {
      key: 'pattern',
      message: 'El cupón ingresado no es válido.'
    },
  ]

  /**
   * 
   */
  export const usePlanCouponForm = () => {
    const currentCoupon = useSelector(PlanSelector.currentCoupon);
    const currentCoverages = useSelector(PlanSelector.coverages);
    const user = useSelector(PlanSelector.currentUser);
    const vehicle = useSelector(PlanSelector.currentVehicle);
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm<IPlanCouponForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
    const dispatch = useDispatch();

    useEffect(() => {
      if (currentCoupon && !getValues().coupon) {
        setValue('coupon', currentCoupon);
      } else if (currentCoupon === '' && getValues().coupon) {
        setValue('coupon', '');
      }
    }, [currentCoupon, getValues, setValue])

    /**
     * 
     */
    const onApplyCoupon = (): void => {
      if (!currentCoupon) {
        if (Util.PATTERN.PLAN_COUPON.test(getValues().coupon)) {
          dispatch(PlanAction.customizationState(true));
          dispatch(PlanAction.quotation({ vehicle: vehicle, user: user, coupon: getValues().coupon, coverages: currentCoverages }));
        }
      } else if (currentCoupon) {
        dispatch(PlanAction.customizationState(true));
        dispatch(PlanAction.quotation({ vehicle: vehicle, user: user, coupon: '', coverages: currentCoverages }));
      }

    }

    return { register, handleSubmit, formState: { errors }, COUPON_ERRORS, currentCoupon, onApplyCoupon }
  }

}