import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TrackingFactory } from "src/factories/tracking.factory";
import { QUOTER_PATH, PROFILE_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import { IQuoterPlanReviewDataForm } from "../interfaces/IQuoterPlanReviewDataForm";
import { QuoterParamsFacade } from "./quoter-params.facade";


export namespace PlanReviewFacade {

  /**
   * 
   * @returns 
   */
  export const usePlanReview = () => {
    const { search } = QuoterParamsFacade.useQuoterParams();
    const form = useForm<IQuoterPlanReviewDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
    const navigate = useNavigate();

    /**
     * 
     */
    const back = (): string => {
      return `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.RFC}${search}`;
    }

    /**
     * 
     */
    const nextPage = (): void => {
      TrackingFactory.Tracker.track({
        event: TrackingFactory.EVENTS.userData
      });
      navigate(`${QUOTER_PATH.QUOTER_PERSON_ACCOUNT_PATH}${search}`);
    }

    return { form, nextPage, back }
  }

}