import { Navigate } from 'react-router-dom';
import { QUOTER_PATH, VEHICLE_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import QuoterPage from '../ui/pages/Quoter';
import QuoterEmailPage from '../ui/pages/QuoterEmail/QuoterEmail';
import QuoterPersonPage from '../ui/pages/QuoterPerson/QuoterPerson';
import QuoterLocationPage from '../ui/pages/QuoterLocation/QuoterLocation';
import QuoterVehiclePage from '../ui/pages/QuoterVehicle/QuoterVehicle';
import QuoterVehicleIdPage from '../ui/pages/QuoterVehicleId/QuoterVehicleId';
import QuoterPersonAccountPage from '../ui/pages/QuoterPersonAccount/QuoterPersonAccount';
import QuoterProfilePage from '../ui/pages/QuoterProfile/QuoterProfile';
import QuoterPaymentPage from '../ui/pages/QuoterPayment/QuoterPayment';
import QuoterPlanPage from "../ui/pages/QuoterPlan/QuoterPlan";
import QuoterPersonTypePage from "../ui/pages/QuoterPersonType/QuoterPersonType";
import QuoterCompletionPage from '../ui/pages/QuoterCompletion/QuoterCompletion';
import QuoterPlanReviewPage from '../ui/pages/QuoterPlanReview/QuoterPlanReview';

/**
 *
 */
export const QuoterRoutes = () => {
  return {
    path: QUOTER_PATH.QUOTER,
    element: <QuoterPage />,
    children : [
      { path: `${QUOTER_PATH.QUOTER}`, element: <Navigate to={`${QUOTER_PATH.QUOTER_VEHICLE_PATH}`} replace /> },
			{ path: `${QUOTER_PATH.QUOTER_VEHICLE_PATH}/:id`, element: <QuoterVehiclePage /> },
			{ path: `${QUOTER_PATH.QUOTER_VEHICLE_PATH}`, element: <Navigate to={`${QUOTER_PATH.QUOTER_VEHICLE_PATH}/${VEHICLE_ROUTE_ID.BRAND}`} replace /> },
			{ path: `${QUOTER_PATH.QUOTER_PERSON_PATH}`, element: <QuoterPersonPage /> },
			{ path: `${QUOTER_PATH.QUOTER_EMAIL_PATH}`, element: <QuoterEmailPage /> },
			{ path: `${QUOTER_PATH.QUOTER_LOCATION_PATH}`, element: <QuoterLocationPage /> },
			{ path: `${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}`, element: <QuoterVehicleIdPage /> },
			{ path: `${QUOTER_PATH.QUOTER_PERSON_ACCOUNT_PATH}`, element: <QuoterPersonAccountPage /> },
			{ path: `${QUOTER_PATH.QUOTER_PLAN_PATH}`, element: <QuoterPlanPage />},
			{ path: `${QUOTER_PATH.QUOTER_PERSON_TYPE_PATH}`, element: <QuoterPersonTypePage /> },
			{ path: `${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/:id`, element: <QuoterVehicleIdPage /> },
			{ path: `${QUOTER_PATH.QUOTER_PROFILE_PATH}/:id`, element: <QuoterProfilePage /> },
			{ path: `${QUOTER_PATH.QUOTER_PLAN_REVIEW}`, element: <QuoterPlanReviewPage /> },
			{ path: `${QUOTER_PATH.QUOTER_PAYMENT_PATH}/:id`, element: <QuoterPaymentPage /> },
			{ path: `${QUOTER_PATH.QUOTER_COMPLETION_PATH}`, element: <QuoterCompletionPage /> },

			{ path: `*`, element: <Navigate to={`${QUOTER_PATH.QUOTER_VEHICLE_PATH}`} replace /> },
    ]
  }
}