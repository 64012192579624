import { AxiosError } from "axios";
import { Plan } from "src/classes/plan/Plan";
import { PlanAdapter } from "../adapters/plan.adapter";
import { IPlanManagement } from "../interfaces/IPlanManagement";
import { PlanService } from "../services/plan.service";

/**
 *
 */
export namespace PlanFeature {
  /**
   * 
   * @param vehicle 
   * @param user 
   * @param coupon 
   * @returns 
   */
  export const quotationFeature = async (management: IPlanManagement): Promise<Plan[] | undefined> => {
    try {
      const requestData = PlanAdapter.quotationRequestAdapter(management.vehicle, management.user, management.coverages, management.coupon, undefined, management.planType);
      const { data } = await PlanService.quotationService(requestData);
      const plans: Plan[] = PlanAdapter.quotationPlanDataAdapter(data);
      return plans;
    } catch (e: any | AxiosError) {
      console.error(e);
      if (e.response) {
        let error = new Error();
        const axiosError = e as AxiosError<TErrorServer>;
        const message = axiosError.response?.data.message;
        if (message?.includes("coupon") && message?.includes("doesn't exists")) {
          error = {
            message: 'El cupón ingresado no existe.',
            name: '4000'
          }
        } else if (message?.includes('coupon') && message?.includes('invalid')) {
          error = {
            message: 'El cupón ingresado es inválido.',
            name: '4000'
          }
        } else if (message && message === 'Postal code is not available for quotation') {
          error = {
            message: 'Lo sentimos, por el momento no cubrimos ese código postal',
            name: '4001'
          }
        }

        throw error;
      } else {
        throw e;
      }
    }
  }

}