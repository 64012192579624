import { BackButton, Button, RadioGroup, Spinner, Anchor, Modal, Text } from "src/components";
import { QuoterPersonTypeFacade } from "src/modules/quoter/controller/facades/quoter-person-type.facade";
import { QUOTER_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterPersonType.scss';

/**
 *
 */
const QuoterPersonTypePage = (): JSX.Element => {

  const { TYPES, type, search, currentStep, isSalvage, showSalvageModal, setType, onBackRoute, setIsSalvage, salvageConfirmation, validateType, nextPage, validateSalvageValue, invalidType, vehicle, editVehicle, setShowSalvageModal } = QuoterPersonTypeFacade.usePersonType();

  return (
    <div className="personType">
      {
        vehicle ?
          <>
						{
							currentStep === QuoterPersonTypeFacade.STEPS.VEHICLE_USE_STEP && 
								<>
									{
										!invalidType ?
											<QuoterContent
												backButton={<BackButton to={`${QUOTER_PATH.QUOTER_VEHICLE_ID_PATH}/${VEHICLE_ROUTE_ID.PLATE}${search}`} />}
												title="Otra pregunta"
												subtitle={`¿Qué uso le das a tu ${vehicle.brand?.name} ${vehicle.model?.name}?`}
												message=""
											>
												<>									
													<div className="personType__radios">
														<RadioGroup
															defaultValue={type !== undefined ? (type === TYPES.THIRD_PARTY.name ? TYPES.THIRD_PARTY.id : TYPES.PERSONAL.id) : undefined}
															label
															items={
																[
																	{ id: TYPES.PERSONAL.id, value: 'Uso particular' },
																	{ id: TYPES.THIRD_PARTY.id, value: 'Uso con fines comerciales' },
																]
															}
															onSelectItem={(i) => setType(i.id === TYPES.THIRD_PARTY.id ? TYPES.THIRD_PARTY.name : TYPES.PERSONAL.name)}
														/>
														<Text type="label" align="center" block>(Por ejemplo para viajes compartidos como DiDi, Uber, etc.)</Text>
													</div>
													<div className="personType__buttons-wrapper">
														<Button type="button" disabled={!type} onClick={validateType}>Siguiente</Button>
													</div>
												</>
											</QuoterContent>
											:
											<QuoterContent
												title="Houston, tenemos un problema 👨‍🚀"
												subtitle="Por el momento no aseguramos autos de uso comercial ☹️ Muchas gracias por tu honestidad, te prometo que en cuanto tengamos el servicio serás el primero en enterarte."
												subtitleType="paragraph"
											>
												<div className="personType__content__section__radios">
													<>
														<div className="personType__invalid-image" style={{ backgroundImage: `url(${Util.ASSET.COMERCIAL_CAR})` }}>
														</div>

														<div className="personType__buttons-wrapper">
															<Button type="button" disabled={!type} onClick={editVehicle}>Cotizar otro auto</Button>
															<Anchor className="personType__buttons-wrapper__link" to={Util.CONSTANT.WEB_LANDING} external block>crabi.com</Anchor>
														</div>
													</>
												</div>
											</QuoterContent>
									}
								</>
						}

						{
							currentStep === QuoterPersonTypeFacade.STEPS.SALVAGE_STEP &&
							<>
								<QuoterContent
									backButton={<BackButton to={onBackRoute()} />}
									title="Una última preguntita"
									subtitle={`¿Tu vehículo es o ha sido un auto de salvamento?`}
									message="Los autos de salvamento son aquellos que tuvieron algún accidente donde fueron considerados pérdida total, o autos recuperados después de un robo."
								>
									<>									
										<div className="personType__radios">
											<RadioGroup
												defaultValue={isSalvage !== undefined ? (isSalvage ? 0 : 1) : undefined}
												label
												items={
													[
														{ id: 1, value: 'No, mi vehículo no es un salvamento' },
														{ id: 0, value: 'Sí, mi vehículo ha sido un salvamento' },
													]
												}
												onSelectItem={(i) => setIsSalvage(i.id === 0)}
											/>
										</div>
										<div className="personType__buttons-wrapper">
											<Button type="button" disabled={isSalvage === undefined} onClick={validateSalvageValue}>Siguiente</Button>
										</div>
									</>
								</QuoterContent>
							</>
						}
          </>
          :
          <div className="personType__loader">
            <Spinner color="main" relative />
          </div>
      }

			<Modal show={showSalvageModal} position="center"
				backgroundDismiss
				onClose={() => setShowSalvageModal(false)}
			>
				<div className="personType__salvageMessage">
					<span className="personType__salvageMessage__image" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>

					<Text type="subtitle" align="center" className="personType__salvageMessage__title">Solo se cubrirá el 50% <b>del valor comercial</b> del vehículo</Text>
					<Text type="paragraph" align="center" className="personType__salvageMessage__description">En caso de que tengamos que indemnizarte por la pérdida total, ya sea por robo o siniestro, te cubrimos un 50% del valor comercial.</Text>

					<div className="personType__salvageMessage__buttons">
						<Button type="button" className="personType__salvageMessage__buttons__button"
							onClick={() => {salvageConfirmation(); nextPage()}}
						>
							Entiendo y acepto
						</Button>

						<Text type="label" align="left" className="">Leer
							<a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer"> términos y condiciones</a>
						</Text>
					</div>
				</div>
			</Modal>
    </div>
  );
};

export default QuoterPersonTypePage;