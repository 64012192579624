import { BrowserRouter as Router } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Main from './modules/main/ui/pages/Main';
import { EnvironmentFactory } from './factories/EnvironmentFactory/environment.factory';
import './App.scss';

const stripePromise: Promise<Stripe | null> | null = loadStripe(EnvironmentFactory.ENV.REACT_APP_STRIPE_PUBLIC_KEY!);

function App() {
  EnvironmentFactory.initHttpClient();

  return (
		<GoogleOAuthProvider clientId="586470958119-ms6ctmbkjcluh7lhoftoo1m3036c16em.apps.googleusercontent.com">
			<Elements stripe={stripePromise}>
				<Router>
					<Main />
				</Router>
			</Elements>
		</GoogleOAuthProvider>
  );
}

export default App;
