import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { User } from "src/classes/person/User";
import { IQuoterProfileDataForm } from "../interfaces/IQuoterProfileDataForm";

/**
 *
 */
export namespace QuoterProfileFormFacade {

	/**
   * 
   */
  const RFC_ERRORS_MESSAGE = {
    FORMAT: 'El formato del RFC que ingresaste es inválido. Revisa tu RFC y vuelve a ingresarlo.',
    INVALID: 'El RFC que ingresaste ya está registrado en otra póliza.'
  };
  const RFC_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: RFC_ERRORS_MESSAGE.FORMAT
    },
    {
      key: 'pattern',
      message: RFC_ERRORS_MESSAGE.FORMAT
    },
    {
      key: 'invalidRfc',
      message: RFC_ERRORS_MESSAGE.INVALID
    },
  ];

	/**
	 * 
	 * @param storedPerson 
	 * @returns 
	 */
	export const useQuoterProfileForm = (storedPerson?: User) => {
		
		const { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IQuoterProfileDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
		
    /**
     * 
     */
    const validateDefaultData = useCallback((storedPerson: User): void => {
      if (storedPerson) {
				if (storedPerson.profile && storedPerson.profile.rfc && !touchedFields.rfc) {
					setValue('rfc', storedPerson.name, { shouldValidate: true, shouldTouch: true });
				}
      }

			const allDataCondition = storedPerson && storedPerson.profile && storedPerson.profile.rfc.length > 0;

			if(allDataCondition)
				trigger();
    }, [setValue, touchedFields, trigger]);

    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }
    }, [storedPerson, validateDefaultData]);

		return {
      RFC_ERRORS,
      formData: { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
		}
  }
}