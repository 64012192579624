import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { Button, StepsBar, Text } from "src/components";
import { IconFactory } from "src/factories/icon.factory";
import './InputsGroup.scss';

/**
 *
 */
interface IInputsGroup {
	/**
	 * 
	 */
  elements: { 
		title: string, 
		reactElement: ReactNode, 
		hintOnMobile?: {
			text: string,
			trigger: () => void;
		} 
	}[];
	/**
	 * 
	 */
	onComplete: () => void;
  /**
	 * 
	 */
  defaultStep?: number;
	/**
	 * 
	 */
	disabledButton?: boolean;
	/**
	 * 
	 */
	backButton?: boolean;
	/**
	 * 
	 */
	skipSteps?: number[];
	/**
	 * 
	 * @param currentStep 
	 */
	onStepChange?: (currentStep: number, direction: 'forward' | 'backward') => void;
}

/**
 *
 */
const InputsGroup = (props: IInputsGroup): JSX.Element => {

  const { elements, onComplete, defaultStep, disabledButton, backButton = false, skipSteps, onStepChange } = props;
  const [currentStep, setCurrentStep] = useState<number>(0);
	const buttonClassnames = classNames({
		'inputsGroup__buttonSection--spaced': backButton
	})

  /**
   * 
   */
  const onNext = (): void => {
    if (currentStep < elements.length - 1) {
      setCurrentStep(currentStep => {
				let nextStep = currentStep + 1;

				if(skipSteps && (skipSteps.indexOf(nextStep) >= 0)) {
					nextStep += 1;

					if (nextStep === elements.length) {
						onComplete();
						return nextStep
					}
				}

				if (onStepChange)
					onStepChange(nextStep, 'forward')

				return nextStep
			})
    } else 
			onComplete();
  }

  /**
   * 
   */
  const onPrevious = (): void => {
    if (currentStep > 0) {
      setCurrentStep(currentStep => {
				let previousStep = currentStep - 1;

				if(skipSteps && (skipSteps.indexOf(previousStep) >= 0)) {
					previousStep -= 1;
				}

				if (onStepChange)
					onStepChange(previousStep, 'backward')
					
				return previousStep
			})
    }
  }

	/**
	 * 
	 */
	useEffect(
		() => {
			if (defaultStep !== undefined && defaultStep >= 0)
				setCurrentStep(defaultStep)
		}, [defaultStep]
	)

  return (
    <div className="inputsGroup">
      {
        elements.length > 0 &&
        <>
          {
            elements.map(
              (item, index) => {
                if (index === currentStep) {
                  return (
										<div key={`element-${index}`} className={`inputsGroup__inputSection ${item.hintOnMobile && 'inputsGroup__inputSection--hasHint'}`}>
											<div>
												<>
													<Text type="paragraph" className="inputsGroup__inputSection__title" bold>{item.title}</Text>
													{item.reactElement}
												</>
											</div>

											<StepsBar currentStep={currentStep} steps={elements.length} />

											{
												item.hintOnMobile &&
												<button type="button" className="inputsGroup__inputSection__hintButton" onClick={() => { item.hintOnMobile && item.hintOnMobile.trigger() }}>
													{item.hintOnMobile.text}
												</button>
											}
										</div>
									)
                } else {
									return undefined;
								}
              }
            )
          }
        </>
      }

			<div className={`inputsGroup__buttonSection ${buttonClassnames}`}>
				{
					backButton &&
						<div className="inputsGroup__buttonSection__backButton">
							{
								currentStep !== 0 && 
									<button className="inputsGroup__buttonSection__backButton__button" type="button" disabled={currentStep === 0} onClick={onPrevious}>
										{IconFactory.leftArrowIcon()}
									</button>
							}
						</div>
				}
				<Button type="button" onClick={onNext} disabled={disabledButton}>Siguiente</Button>
			</div>
    </div>
  );
};

export default InputsGroup;