import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { User } from "src/classes/person/User";
import { Util } from "src/utils/Util";
import { IQuoterEmailDataForm } from "../interfaces/IQuoterEmailDataForm";

/**
 *
 */
export namespace QuoterEmailFormFacade {

  /**
   * 
   */
  const EMAIL_ERRORS_MESSAGE = {
    REQUIRED: 'El correo electrónico es obligatorio.',
    PATTERN: 'El formato del correo electrónico es inválido.',
    INVALID: 'El correo que ingresaste ya está ligado a otra póliza. Por favor ingresa un correo diferente.'
  };
  const EMAIL_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: EMAIL_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: EMAIL_ERRORS_MESSAGE.PATTERN
    },
    {
      key: 'invalidEmail',
      message: EMAIL_ERRORS_MESSAGE.INVALID
    },
  ];

	const PHONE_ERRORS_MESSAGE = {
		INVALID: 'El número de celular debe ser de 10 digitos.'
	};
	const PHONE_ERRORS: TFormErrorMessage[] = [
		{
			key: 'required',
			message: PHONE_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'pattern',
			message: PHONE_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'minLength',
			message: PHONE_ERRORS_MESSAGE.INVALID
		},
	];

	const DATE_ERRORS_MESSAGE = {
		INVALID: 'Fecha inválida',
		REQUIRED: 'requerido'
	};
	const DATE_ERRORS: TFormErrorMessage[] = [
		{
			key: 'invalidDate',
			message: DATE_ERRORS_MESSAGE.INVALID
		},
		{
			key: 'required',
			message: DATE_ERRORS_MESSAGE.REQUIRED
		},
	];

	const CIVIL_STATUS = [
		{ id: Util.STATUS_CODE.CIVIL_STATUS.SINGLE, value: 'Soltero' },
		{ id: Util.STATUS_CODE.CIVIL_STATUS.MARRIED, value: 'Casado' },
		{ id: Util.STATUS_CODE.CIVIL_STATUS.DIVORCED, value: 'Divorciado' },
		{ id: Util.STATUS_CODE.CIVIL_STATUS.OTHER, value: 'Otro' },
	]

	/**
	 * 
	 * @param storedPerson 
	 * @returns 
	 */
	export const useQuoterEmailForm = (storedPerson?: User) => {
		const { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IQuoterEmailDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
		
    /**
     * 
     */
    const validateDefaultData = (storedPerson: User): void => {
      if (storedPerson) {
				if (storedPerson.email && !touchedFields.email) {
					setValue('email', storedPerson.email, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.birthdate && !touchedFields.birthdate) {
					setValue('birthdate', storedPerson.profile.birthdate.toDateString(), { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile && storedPerson.profile.phone && storedPerson.profile.phone.number.length > 0 && !touchedFields.phone) {
					setValue('phone', storedPerson.profile.phone.number, { shouldValidate: true, shouldTouch: true })
				}
				if (storedPerson.profile && storedPerson.profile.phone && storedPerson.profile.phone.countryCode && storedPerson.profile.phone.countryCode.code && !touchedFields.countryCode) {
					setValue('countryCode', storedPerson.profile.phone.countryCode.code, { shouldValidate: true, shouldTouch: true })
				}

				if (storedPerson.profile && storedPerson.profile.civilStatus > -1 && !touchedFields.civilStatus) {
					setValue('civilStatus', storedPerson.profile.civilStatus, { shouldValidate: true, shouldTouch: true });
				}
      }
    };

		/**
		 * 
		 */
    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }
    },
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storedPerson]);

		return {
			CIVIL_STATUS,
      EMAIL_ERRORS,
			PHONE_ERRORS,
			DATE_ERRORS,
      formData: { register, control, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
		}
  }
}