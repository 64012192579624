import { combineReducers } from 'redux';
import { CoreInstanceSlice } from 'src/core/slices/instance.slice'


export const rootReducer = (injectedReducers = {}) =>
  combineReducers(
    {
      ...injectedReducers,
      instance: CoreInstanceSlice.reducer,
    }
  )

