import { apiPath } from "./resources/api-path";
import { asset } from "./resources/asset";
import { constant } from "./resources/constant";
import { countries, mxStates } from "./resources/countries";
import { key } from "./resources/key";
import { links } from "./resources/links";
import { pattern } from "./resources/pattern";
import { statusCode } from "./resources/status-code";
import { transform } from "./resources/transform";

export class Util {

  public static PATTERN = pattern;

  public static API_PATH = apiPath;
  
  public static KEY = key;

  public static TRANSFORM = transform;

  public static CONSTANT = constant;

  public static STATUS_CODE = statusCode;

  public static COUNTRIES = countries;

	public static MX_STATES = mxStates;

  public static ASSET = asset;

  public static LINKS = links;
}