import { useEffect, useState } from "react";
import { useForm, UseFormGetValues } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Address } from "src/classes/person/Address";
import { QUOTER_PATH, QUOTER_QUERY_PARAMS } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterPersonAction } from "../actions/quoter-person.action";
import { QuoterPersonFeature } from "../features/quoter-person.feature";
import { IQuoterLocationForm } from "../interfaces/IQuoterLocationForm";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterPersonFacade } from "./quoter-person.facade";
import { QuoterFacade } from "./quoter.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
export namespace QuoterLocationFacade {

	
	const POSTAL_CODE_ERRORS_MESSAGE = {
		LENGTH: 'El código postal debe ser de 5 digitos.',
		INVALID: 'Aún no llegamos a tu municipio.'
	};
	export const POSTAL_CODE_ERRORS: TFormErrorMessage[] = [
		{
			key: 'required',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'pattern',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'minLength',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'invalidPostalCode',
			message: POSTAL_CODE_ERRORS_MESSAGE.INVALID
		}
	];

	/**
	 * 
	 */
	export const usePostalCodeValidation = () => {
		const { storedPerson } = QuoterFacade.useQuoterActors();
		const { getParam } = QuoterParamsFacade.useQuoterParams();
		const { fillPersonData } = QuoterPersonFacade.useFillPerson();
		const [isValidatingPostalCode, setIsValidatingPostalCode] = useState<boolean>(false);
		const [validatedPostalCode, setValidatedPostalCode] = useState<string>('');
		const [previousPostalCodeValidation, setPreviousPostalCodeValidation] = useState<boolean | null>(null);
		const dispatch = useDispatch();
		const form = useForm<IQuoterLocationForm>(
			{
				mode: 'onChange',
				reValidateMode: 'onChange',
				defaultValues: {
					street: '',
					neighborhood: '',
					externalNumber: ''
				}
			}
		);

		/**
		 * 
		 * @param address 
		 */
		const setFormValuesByAddress = (address: Address): void => {
			// SET FORM VALUES

			if (getParam(QUOTER_QUERY_PARAMS.USER_STREET).length > 0 && form.getValues('street') === '') {
				form.setValue('street', getParam(QUOTER_QUERY_PARAMS.USER_STREET));
			} else if (address.street !== '') {
				form.setValue('street', address.street);
			} else {
				form.setValue('street', '');
			}

			if (getParam(QUOTER_QUERY_PARAMS.USER_NEIGHBORHOOD).length > 0 && form.getValues('neighborhood') === '') {
				form.setValue('neighborhood', getParam(QUOTER_QUERY_PARAMS.USER_NEIGHBORHOOD));
			} else if (address.neighborhood !== '') {
				form.setValue('neighborhood', address.neighborhood);
			} else {
				form.setValue('neighborhood', '');
			}

			if (getParam(QUOTER_QUERY_PARAMS.USER_EXTERNAL_REFERENCE).length > 0 && form.getValues('externalNumber') === '') {
				form.setValue('externalNumber', getParam(QUOTER_QUERY_PARAMS.USER_EXTERNAL_REFERENCE));
			} else if (address.externalReference !== '') {
				form.setValue('externalNumber', address.externalReference);
			} else {
				form.setValue('externalNumber', '');
			}

			if (address.postalCode !== '') {
				verifyPostalCode(address.postalCode);
				form.setValue('postalCode', address.postalCode);
			}

			form.trigger()
		}

		/**
		 * 
		 * @param postalCode 
		 * @returns 
		 */
		const verifyPostalCode = async (postalCode: string): Promise<boolean | undefined> => {
			try {
				if (Util.PATTERN.NUMBERS.test(postalCode)) {
					if (validatedPostalCode !== postalCode) {
						setIsValidatingPostalCode(true);
						setValidatedPostalCode(postalCode);
						const address = await QuoterPersonFeature.validatePostalCodeFeature(postalCode);
						if (address) {
							setPreviousPostalCodeValidation(true);
							let person = fillPersonData();
							if (person && person.profile && person.profile.address) {
								person.profile.address = address;
							}

							dispatch(QuoterPersonAction.setStoredPerson(person))
							return true;
						} else {
							return false;
						}
					} else {
						return previousPostalCodeValidation as boolean;
					}
				}
			} catch (e) {

			} finally {
				setIsValidatingPostalCode(false);
			}
		};

		/**
		 * 
		 */
		useEffect(() => {
			if (storedPerson && storedPerson.profile && storedPerson.profile.address) {
				if (storedPerson.profile.address.postalCode.length > 0) {
					form.setValue('postalCode', storedPerson.profile.address.postalCode);
					form.trigger('postalCode');
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [storedPerson])

		return {
			isValidatingPostalCode, verifyPostalCode, storedPerson, setFormValuesByAddress, form
		}
	}

	/**
	 * 
	 */
	export const useAddress = () => {
		const { storedPerson } = QuoterFacade.useQuoterActors();
		const { search, setParam } = QuoterParamsFacade.useQuoterParams();
		const [address, setAddress] = useState<Address>();
		const [defaultCoords, setDefaultCoords] = useState<{ lat: number, lng: number }>();
		const dispatch = useDispatch();
		const navigate = useNavigate();

		/**
		 * 
		 * @param address 
		 */
		const selectedMapAddress = (incomingAddress?: Address) => {	
			if (incomingAddress) {
				incomingAddress.externalReference = incomingAddress.externalReference.substring(0, 16);
				incomingAddress.street = incomingAddress.street.substring(0, 64);
				incomingAddress.neighborhood = incomingAddress.neighborhood.substring(0, 64);
			}
			
			setAddress(incomingAddress);
		}

		/**
		 * 
		 * @param internalNumber 
		 */
		const addAddressToPerson = (formValues: UseFormGetValues<IQuoterLocationForm>): void => {
			if (address && address.longitude && address.latitude) {
				if (formValues().internalNumber) {
					address.internalReference = formValues().internalNumber as string;
				}

				address.street = formValues().street;
				address.neighborhood = formValues().neighborhood;
				address.externalReference = formValues().externalNumber;

				let params: string = search;
				if (storedPerson && storedPerson.profile && storedPerson.profile.address) {
					params = setParam(QUOTER_QUERY_PARAMS.USER_POSTAL_CODE, storedPerson.profile.address.postalCode);
					params = setParam(QUOTER_QUERY_PARAMS.USER_STATE, storedPerson.profile.address.stateCode.toString());
					params = setParam(QUOTER_QUERY_PARAMS.USER_LATITUDE, address.latitude.toString());
					params = setParam(QUOTER_QUERY_PARAMS.USER_LONGITUDE, address.longitude.toString());
					params = setParam(QUOTER_QUERY_PARAMS.USER_STREET, address.street);
					params = setParam(QUOTER_QUERY_PARAMS.USER_NEIGHBORHOOD, address.neighborhood);
					params = setParam(QUOTER_QUERY_PARAMS.USER_EXTERNAL_REFERENCE, address.externalReference);
				}

				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.postalCode.name,
					[TrackingFactory.EVENTS.postalCode.property]: formValues('postalCode')
				});


				dispatch(QuoterPersonAction.setPersonAddress(address));
				navigate(`${QUOTER_PATH.QUOTER_PLAN_PATH}${params}`);
			}
		}

		useEffect(
			() => {
				if (storedPerson && storedPerson.profile && storedPerson.profile.address) {
					if (storedPerson.profile.address.latitude && storedPerson.profile.address.longitude) {
						setDefaultCoords({ lat: storedPerson.profile.address.latitude, lng: storedPerson.profile.address.longitude });
					}
				}
			}, [storedPerson]
		)

		return { address, selectedMapAddress, addAddressToPerson, search, defaultCoords }
	}
}