import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Phone } from "src/classes/person/Phone";
import { QUOTER_PATH, QUOTER_QUERY_PARAMS } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterPersonAction } from "../actions/quoter-person.action";
import { QuoterPersonFeature } from "../features/quoter-person.feature";
import { IQuoterEmailDataForm } from "../interfaces/IQuoterEmailDataForm";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterPersonFacade } from "./quoter-person.facade";
import { TrackingFactory } from "src/factories/tracking.factory";

/**
 *
 */
export namespace QuoterEmailFacade {

	/**
	 * 
	 */
	export const useQuoterEmail = () => {
		const { search, setParam, getParam } = QuoterParamsFacade.useQuoterParams();
		const { fillPersonData } = QuoterPersonFacade.useFillPerson();
		const [isValidatingEmail, setIsValidatingEmail] = useState<boolean>(false)
		const [validatedEmail, setValidatedEmail] = useState('');
		const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);
		const [showExistingEmailModal, setShowExistingEmailModal] = useState<boolean>(false);
		const navigate = useNavigate();
		const dispatch = useDispatch();

		/**
		 * 
		 */
		const setFormValues = (formValues: IQuoterEmailDataForm): Promise<void> => {
			return new Promise<void>(
				(resolve) => {
					let person = fillPersonData();
					if (formValues.email)
						person.email = formValues.email;

					if (person.profile) {
						if (formValues.phone) {
							if (!person.profile.phone)
								person.profile.phone = new Phone();

							person.profile.phone.number = formValues.phone;
						}

						if (formValues.countryCode) {
							if (!person.profile.phone)
								person.profile.phone = new Phone();

							if (!person.profile.phone.countryCode)
								person.profile.phone.countryCode = { code: formValues.countryCode };
							else
								person.profile.phone.countryCode.code = formValues.countryCode;
						}

						if (formValues.birthdate) {
							person.profile.birthdate = new Date(formValues.birthdate);
						}

						if (formValues.civilStatus) {
							person.profile.civilStatus = formValues.civilStatus;
						}
					}

					dispatch(QuoterPersonAction.setStoredPerson(person));
					resolve();
				}
			);
		}

		/**
		 * 
		 * @param email 
		 * @returns 
		 */
		const validateEmail = async (email: string): Promise<boolean | undefined> => {
			try {
				if (Util.PATTERN.EMAIL.test(email)) {
					if (validatedEmail !== email) {
						setIsValidatingEmail(true);
						setValidatedEmail(email);
						const validation = await QuoterPersonFeature.validateEmailFeature(email);
						setPreviousValidation(validation)
						return validation;
					} else {
						return previousValidation as boolean;
					}
				}
			} catch (e) {

			} finally {
				setIsValidatingEmail(false);
			}
		};

		/**
		 * 
		 */
		const redirectToCrabi = () => {
			window.location.href = Util.CONSTANT.CRABI_URL;
		}

		/**
		 * 
		 */
		const onNavigate = (formValues: IQuoterEmailDataForm) => {
			let _params: string = '';
			_params = setParam(QUOTER_QUERY_PARAMS.USER_EMAIL, formValues.email);
			_params = setParam(QUOTER_QUERY_PARAMS.USER_PHONE_CODE, formValues.countryCode);
			_params = setParam(QUOTER_QUERY_PARAMS.USER_PHONE_NUMBER, formValues.phone);
			_params = setParam(QUOTER_QUERY_PARAMS.USER_BIRTHDATE, Util.TRANSFORM.DATE.formatDate(new Date(formValues.birthdate)));
			_params = setParam(QUOTER_QUERY_PARAMS.USER_CIVIL_STATUS, formValues.civilStatus.toString());

			const today = new Date();
			const formBirthDate = new Date(formValues.birthdate);
			const m = today.getMonth() - formBirthDate.getMonth();
			let age = today.getFullYear() - formBirthDate.getFullYear();
			if (m < 0 || (m === 0 && today.getDate() < formBirthDate.getDate())) {
				age--;
			}
			TrackingFactory.Tracker.track({
				event: TrackingFactory.EVENTS.email.name,
				[TrackingFactory.EVENTS.email.properties.email]: formValues.email,
				[TrackingFactory.EVENTS.email.properties.age]: age,
			});

			
			// Esto no lo entiendo, por que estan cosas de address en esta parte?
			// Lo que modifique son solo las tabulaciones
			let person = fillPersonData();
			if (person.profile && person.profile.address && getParam(QUOTER_QUERY_PARAMS.USER_LATITUDE) && getParam(QUOTER_QUERY_PARAMS.USER_LONGITUDE)) {
				person.profile.address.latitude = parseFloat(getParam(QUOTER_QUERY_PARAMS.USER_LATITUDE));
				person.profile.address.longitude = parseFloat(getParam(QUOTER_QUERY_PARAMS.USER_LONGITUDE));
				dispatch(QuoterPersonAction.setStoredPerson(person));
			}

			setFormValues(formValues);
			navigate(`${QUOTER_PATH.QUOTER_LOCATION_PATH}${_params}`);
		};

		const validateDate = (date: string): boolean => {
			return Util.TRANSFORM.DATE.dateToElapsedYears(date) >= Util.CONSTANT.ALLOWED_DATE_OF_BIRTH_RANGE.MAX_DATE;
		}

		return { isValidatingEmail, search, showExistingEmailModal, previousValidation, setShowExistingEmailModal, redirectToCrabi, setFormValues, validateEmail, onNavigate, validateDate }
	}
}