import { AxiosError } from "axios";
import { AuthToken } from "src/classes/person/AuthToken";
import { User } from "src/classes/person/User";
import { Plan } from "src/classes/plan/Plan";
import { Policy } from "src/classes/policy/Policy";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { QuoterAdapter } from "../adapters/quoter.adapter";
import { IUtmParams } from "../interfaces/IUtmParams";
import { QuoterService } from "../services/quoter.service";

/**
 *
 */
export namespace QuoterFeature {

  /**
   * 
   * @param user 
   * @param referral 
   * @param utms 
   * @returns 
   */
  export const registerUserFeature = async (newUser: User, referral?: string, utms?: IUtmParams): Promise<User> => {
    try {
      const adaptedData = QuoterAdapter.registerUserRequestAdapter(newUser, referral!, utms!);
      const { data } = await QuoterService.registerUser(adaptedData);
      const user = QuoterAdapter.registerUserDataAdapter(data);
      return user;
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();

      if (axiosError.response) {
        if (axiosError.response.status === 400) {
          if (axiosError.response.data.code === 1) {
            if (axiosError.response.data.response.message.includes('street'))
              error.message = `La calle tiene una dirección inválida`;
            else if (axiosError.response.data.response.message.includes('external'))
              error.message = `El número exterior es inválido`;
            else if (axiosError.response.data.response.message.includes('internal'))
              error.message = `El número interior es inválido`;
            else
              error.message = `La contraseña no cumple con nuestros estándares de seguridad`;
          } else if (axiosError.response.data.code === 10)
            error.message = `Formato de fecha incorrecto.`;
          else if (axiosError.response.data.code === 4)
            error.message = `Debe de ingresar un género para continuar.`;
          else
            error.message = `Ha ocurrido un error con el servidor.`;
        }

        if (axiosError.response.status === 409) {
          if (axiosError.response.data.code === 10) {
            error.message = 'La edad no puede ser menor a 16.'
          } else if (axiosError.response.data.code === 11) {
            error.message = 'No se encontró el código postal.'
          } else if (axiosError.response.data.code === 12) {
            error.message = 'El email ya se encuentra registrado.'
          } else if (axiosError.response.data.code === 13) {
            error.message = 'El RFC ya se encuentra registrado.'
          }
        }

        if (axiosError.response.status === 500 || axiosError.response.status === 503)
          error.message = 'Error del servidor. Por favor, intente más tarde.'

      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }

      throw error;
    }
  }

  /**
   * 
   */
  export const loginFeature = async (user: User): Promise<void> => {
    try {
      const request = QuoterAdapter.loginRequestAdapter(user);
      const { data } = await QuoterService.loginService(request);
      const authToken: AuthToken = QuoterAdapter.authTokenDataAdapter(data);
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      document.cookie = `${Util.KEY.TOKEN_STORAGE}=${authToken.access}; expires=${expirationDate}`;
    } catch (e: any | AxiosError) {
      if (e.response) {
        if ((e as AxiosError).response?.status === 403) {
          const error: Error = {
            message: 'El usuario o contraseña son incorrectos.',
            name: '403'
          }
          throw error;
        }
      } else if (!navigator.onLine) {
        const error: Error = {
          message: 'No tienes conexión a internet.',
          name: ''
        }
        throw error;
      } else {
        const error: Error = {
          message: 'Error de conexión con el servidor.',
          name: ''
        }
        throw error;
      }

    }
  }

  export const temporalLoginFeature = async (os: string, osVersion: string, browser: string, browserVersion: string, deviceUUID: string): Promise<void> => {
    try {
      const request = QuoterAdapter.temporalLoginRequestAdapter(os, osVersion, browser, browserVersion, deviceUUID);
      const { data } = await QuoterService.loginService(request);
      const authToken: AuthToken = QuoterAdapter.authTokenDataAdapter(data);
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      document.cookie = `${Util.KEY.TOKEN_STORAGE}=${authToken.access}; expires=${expirationDate}`;
    } catch (e: any | AxiosError) {
      if (e.response) {
        const error: Error = {
          message: 'Error de conexión con el servidor.',
          name: ''
        }
        throw error;
      }

    }
  }

  /**
   * 
   * @param newVehicle 
   */
  export const createVehicleFeature = async (newVehicle: Vehicle): Promise<void> => {
    try {
      const data = QuoterAdapter.vehicleCreationRequestAdapter(newVehicle);
      await QuoterService.createVehicleService(data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param vehicle 
   * @param plan 
   * @param driver 
   * @returns 
   */
  export const policyRequestFeature = async (vehicle: Vehicle, plan: Plan, driver?: User): Promise<Policy> => {
    try {
      const requestData = QuoterAdapter.policyRequestAdapter(vehicle, plan, driver);
      const { data } = await QuoterService.policyRequestService(requestData);
      const policyOnlyWithId = QuoterAdapter.policyRequestDataAdapter(data);
      return policyOnlyWithId;
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }
}