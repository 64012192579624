import useCollapse from 'react-collapsed';
import { Controller } from 'react-hook-form';
import { Button, Checkbox, Text } from 'src/components';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { IconFactory } from 'src/factories/icon.factory';
import { QuoterPaymentFacade } from 'src/modules/quoter/controller/facades/quoter-payment.facade';
import { PlanReviewFacade } from 'src/modules/quoter/controller/facades/plan-review.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { Util } from 'src/utils/Util';
import PlanReviewCard from '../../components/PlanReviewCard/PlanReviewCard';
import QuoterContent from '../../components/QuoterContent/QuoterContent';
import './QuoterPlanReview.scss';

/**
 *
 */
const QuoterPlanReviewPage = (): JSX.Element => {

	const { storedPerson, storedPlan, storedVehicle } = QuoterFacade.useQuoterActors();
	const desktopCollapse = useCollapse({ duration: 350, defaultExpanded: false });
	const mobileCollapse = useCollapse({ duration: 350, defaultExpanded: false });
	const { form, nextPage, back } = PlanReviewFacade.usePlanReview();
	const {
		paymentMethod,
		paymentType,
		paymentCost,
		getTotal,
		getTotalByMonth,
		getNetAmount,
		getMonthFeeAmount,
		getSubTotal,
		getTaxes
	} = QuoterPaymentFacade.useQuoterPayment();


	return (
		<div className="quoterPlanReview">
			<QuoterContent
				backButton={<BackButton to={back()} />}
				title={`Perfecto ${storedPerson?.name}, verifica tus datos y tu plan`}>

				{
					<div className="quoterPlanReview__dataWrapper">
						<div className="quoterPlanReview__dataWrapper__dataSection">
							<PlanReviewCard
								title="DATOS PERSONALES"
								titleImage={Util.ASSET.POLICY_SUMMARY_DATA}
								type="personal"
								dataSource={storedPerson}
							/>

							<PlanReviewCard
								title="DATOS DEL AUTO"
								titleImage={Util.ASSET.POLICY_VEHICLE_DATA}
								type="vehicle"
								dataSource={storedVehicle}
							/>

							<PlanReviewCard
								title="DATOS DE CONTACTO"
								titleImage={Util.ASSET.POLICY_SUMMARY_CONTACT}
								type="contact"
								dataSource={storedPerson}
							/>

							{
								storedVehicle && storedVehicle.brand && storedVehicle.model && storedVehicle.year &&
								<PlanReviewCard
									title={`PLAN PARA TU ${storedVehicle.brand.name} ${storedVehicle.model.name} ${storedVehicle.year.value}`}
									titleImage={Util.ASSET.POLICY_VEHICLE_COVERAGE}
									type="plan"
									dataSource={storedPlan}
									coverageVehicle={storedVehicle}
								/>
							}
						</div>

						<aside className="quoterPlanReview__dataWrapper__infoSection">
							<section className="quoterPlanReview__dataWrapper__infoSection__amounts">
								{
									(paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<div>
										<Text type="label" align="center">Total a pagar</Text>
										<Text type="title" align="center" color="main" className="quoterPlanReview__dataWrapper__infoSection__amounts__bigAmount">{paymentCost.getTotal()}</Text>
									</div>
								}

								{
									(paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
									<div>
										<Text type="label" align="center" bold>Pago inicial <Text type="label" align="center" bold color="main">1ra cuota</Text></Text>
										<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__subscriptionAmount">
											{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
										</Text>
										<br />
										<Text type="label" align="center" bold>Por pagar <Text type="label" align="center" bold color="main">11 cuotas</Text></Text>
										<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__subscriptionAmount">
											{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}</Text>
									</div>
								}

								{
									(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id && paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
									<div className="quoterPlanReview__dataWrapper__infoSection__amounts__monthly">
										<Text type="label" align="center">{paymentCost.monthsQuantity} pagos de</Text>
										<Text type="title" align="center" color="main" className="quoterPlanReview__dataWrapper__infoSection__amounts__monthly__price">{paymentCost.getAmountByMonth()}</Text>
										<Text type="label" align="center">
											Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span>
										</Text>
									</div>
								}

								<button className="quoterPlanReview__dataWrapper__infoSection__amounts__button" {...desktopCollapse.getToggleProps()} is-expanded={desktopCollapse.isExpanded ? '' : null}>
									<Text type="label" align="center" color="main" bold>Desglose del monto</Text>
									{IconFactory.downArrowIcon()}
								</button>
							</section>

							<section className="quoterPlanReview__dataWrapper__infoSection__summary" {...desktopCollapse.getCollapseProps()}>
								{
									paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
									<>
										<Text className="quoterPlanReview__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen de tu plan seleccionado</Text>

										<ul className="quoterPlanReview__dataWrapper__infoSection__summary__list">
											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Prima neta</Text>
												<Text type="label" align="right">
													{
														paymentCost.monthsQuantity <= 1 && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code)
															? Util.TRANSFORM.CURRENCY.MXN(0)
															: getNetAmount()
													}
												</Text>
											</li>

											{
												<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Costo de expedici&oacute;n*</Text>
													<Text type="label" align="right">
														{Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights)}
													</Text>
												</li>
											}

											{
												(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code || ((paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code) && (paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id))) &&
												<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Responsabilidad Civil</Text>

													<Text type="label" align="right">
														{
															(paymentCost.monthsQuantity <= 1) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code)
																? Util.TRANSFORM.CURRENCY.MXN(0)
																: paymentCost.getCivilTaxAmount()
														}
													</Text>
												</li>
											}

											{
												(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
												<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__monthlyItem">
													<div className="quoterPlanReview__dataWrapper__infoSection__summary__list__monthlyItem__amount">
														<Text type="label" align="left">
															Pago
															{(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) ? ' diferido* ' : ' mensual '}
															({paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code ? paymentCost.getFeePercent() : '20%'})
														</Text>
														<Text type="label" align="right">{getMonthFeeAmount()}</Text>
													</div>
												</li>
											}

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Subtotal</Text>
												<Text type="label" align="right">{getSubTotal()}</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">IVA (16%)</Text>
												<Text type="label" align="right">{getTaxes()}</Text>
											</li>

											{
												(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
												<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Total por mes</Text>
													<Text type="label" align="right">{getTotalByMonth()}</Text>
												</li>
											}

											{
												(paymentMethod.id !== QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
												<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Total primer mes</Text>
													<Text type="label" align="right">
														{
															storedPlan.discount
																? storedPlan.discount.subscription.firstCost.getTotal()
																: storedPlan.subscription.firstCost.getTotal()
														}
													</Text>
												</li>
											}

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Total</Text>
												<Text type="label" align="right">{getTotal()}</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="center" className="quoterPlanReview__dataWrapper__infoSection__summary__list__monthlyItem__warning">
													* Este monto no será reembolsado <br /> en caso de cancelación anticipada.
												</Text>
											</li>
										</ul>
									</>
								}

								{
									paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
									<>
										<Text className="quoterPlanReview__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen del primer pago</Text>

										<ul className="quoterPlanReview__dataWrapper__infoSection__summary__list">
											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Prima neta</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.firstCost.getNetAmount()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Responsabilidad civil</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.firstCost.getCivilTaxAmount()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Pago diferido*</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.firstCost.getMonthFeeAmount()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Costo de expedición*</Text>
												<Text type="label" align="right">
													{Util.TRANSFORM.CURRENCY.MXN(storedPlan.subscription.firstCost.expeditionRights)}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Subtotal</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.firstCost.getSubTotal()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">IVA</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.firstCost.getTaxes()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left" bold>Total</Text>
												<Text type="label" align="right" bold>
													{storedPlan.subscription.firstCost.getTotal()}
												</Text>
											</li>
										</ul>

										<Text className="quoterPlanReview__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen de los siguientes 11 pagos</Text>

										<ul className="quoterPlanReview__dataWrapper__infoSection__summary__list">
											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Prima neta</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.cost.getNetAmount()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Pago diferido*</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.cost.getMonthFeeAmount()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">Subtotal</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.cost.getSubTotal()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left">IVA</Text>
												<Text type="label" align="right">
													{storedPlan.subscription.cost.getTaxes()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="left" bold>Total</Text>
												<Text type="label" align="right" bold>
													{storedPlan.subscription.cost.getTotal()}
												</Text>
											</li>

											<li className="quoterPlanReview__dataWrapper__infoSection__summary__list__listItem">
												<Text type="label" align="center" className="quoterPlanReview__dataWrapper__infoSection__summary__list__monthlyItem__warning">
													* Este monto no será reembolsado <br /> en caso de cancelación anticipada.
												</Text>
											</li>
										</ul>
									</>
								}
							</section>

							<section className="quoterPlanReview__dataWrapper__infoSection__bottomSection">
								<form>
									<Controller
										name="acceptTerms"
										control={form.control}
										rules={{ required: true }}
										render={({ field }) => (
											<Checkbox onChange={(e) => { field.onChange(e.target.checked) }}>
												<Text type="label" align="left">Acepto los
													<a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer"> términos y condiciones</a> y las
													<a target="_blank" href="https://crabi.com/legal/condiciones-generales" rel="noreferrer"> condiciones generales</a>
												</Text>
											</Checkbox>
										)}
									/>
								</form>

								<Button
									type="button"
									disabled={!form.formState.isValid}
									onClick={nextPage}
								>
									Continuar
								</Button>
							</section>
						</aside>
					</div>
				}
			</QuoterContent>

			<aside className="quoterPlanReview__mobileInfoSection">
				<section className="quoterPlanReview__mobileInfoSection__amounts">
					{
						(paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
						<div>
							<Text type="label" align="center">Total a pagar</Text>
							<Text type="title" align="center" color="main" className="quoterPlanReview__mobileInfoSection__amounts__bigAmount">{paymentCost.getTotal()}</Text>
						</div>
					}

					{
						(paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
						<div>
							{/* <Text type="label" align="center">
									1er Pago (1er mes de {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()} <br/> + Responsabilidad Civil {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}
									<br/> + Tarifa {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()} { paymentCost.expeditionRights && paymentCost.expeditionRights > 0 ? <><br/> + Costo de expedición* { Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights) }</> : '' } + IVA {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()})
								</Text> */}

							<Text type="title" align="center" color="main" className="quoterPlanReview__mobileInfoSection__amounts__subscriptionAmount">{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}</Text>
							<Text type="label" align="center">Pago inicial 1ra cuota</Text>

							<Text type="title" align="center" color="main" className="quoterPlanReview__mobileInfoSection__amounts__subscriptionAmount">{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}</Text>
							<Text type="label" align="center">Por pagar 11 cuotas</Text>
						</div>
					}

					{
						(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id && paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
						<div className="quoterPlanReview__mobileInfoSection__amounts__amounts__monthly">
							<Text type="label" align="center">{paymentCost.monthsQuantity} pagos de</Text>
							<Text type="title" align="center" color="main" className="quoterPlanReview__mobileInfoSection__amounts__monthly__price">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</Text>
							<Text type="label" align="center">
								Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span>
							</Text>
						</div>
					}

					<button className="quoterPlanReview__mobileInfoSection__amounts__button" {...mobileCollapse.getToggleProps()} is-expanded={!mobileCollapse.isExpanded ? '' : null}>
						<Text type="label" align="center" color="main" bold>Desglose del monto</Text>
						{IconFactory.downArrowIcon()}
					</button>
				</section>

				<section className="quoterPlanReview__mobileInfoSection__summary" {...mobileCollapse.getCollapseProps()}>
					{
						paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
						<>
							<Text className="quoterPlanReview__mobileInfoSection__summary__title" bold type="label" align="center">Resumen de tu plan seleccionado</Text>

							<ul className="quoterPlanReview__mobileInfoSection__summary__list">
								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{
											paymentCost.monthsQuantity <= 1 && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code)
												? Util.TRANSFORM.CURRENCY.MXN(0)
												: getNetAmount()
										}
									</Text>
								</li>

								{
									<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Costo de expedici&oacute;n*</Text>
										<Text type="label" align="right">
											{Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights)}
										</Text>
									</li>
								}

								{
									(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code || ((paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code) && (paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id))) &&
									<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Responsabilidad Civil</Text>

										<Text type="label" align="right">
											{
												(paymentCost.monthsQuantity <= 1) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code)
													? Util.TRANSFORM.CURRENCY.MXN(0)
													: paymentCost.getCivilTaxAmount()
											}
										</Text>
									</li>
								}

								{
									(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<li className="quoterPlanReview__mobileInfoSection__summary__list__monthlyItem">
										<div className="quoterPlanReview__mobileInfoSection__summary__list__monthlyItem__amount">
											<Text type="label" align="left">
												Pago
												{(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) ? ' diferido* ' : ' mensual '}
												({paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code ? paymentCost.getFeePercent() : '20%'})
											</Text>
											<Text type="label" align="right">{getMonthFeeAmount()}</Text>
										</div>

										{
											(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) &&
											<Text type="label" align="center" className="quoterPlanReview__mobileInfoSection__summary__list__monthlyItem__warning">
												* Este monto no será reembolsado <br /> en caso de cancelación anticipada.
											</Text>
										}
									</li>
								}

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">{getSubTotal()}</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA (16%)</Text>
									<Text type="label" align="right">{getTaxes()}</Text>
								</li>

								{
									(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Total por mes</Text>
										<Text type="label" align="right">{getTotalByMonth()}</Text>
									</li>
								}

								{
									(paymentMethod.id !== QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Total primer mes</Text>
										<Text type="label" align="right">
											{
												storedPlan.discount
													? storedPlan.discount.subscription.firstCost.getTotal()
													: storedPlan.subscription.firstCost.getTotal()
											}
										</Text>
									</li>
								}

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Total</Text>
									<Text type="label" align="right">{getTotal()}</Text>
								</li>
							</ul>
						</>
					}

					{
						paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
						<>
							<Text className="quoterPlanReview__mobileInfoSection__summary__title" bold type="label" align="center">Resumen del primer pago</Text>

							<ul className="quoterPlanReview__mobileInfoSection__summary__list">
								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Responsabilidad civil</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Pago diferido*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Costo de expedición*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ?
											Util.TRANSFORM.CURRENCY.MXN(storedPlan.discount.subscription.firstCost.expeditionRights)
											:
											Util.TRANSFORM.CURRENCY.MXN(storedPlan.subscription.firstCost.expeditionRights)
										}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getSubTotal() : storedPlan.subscription.firstCost.getSubTotal()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left" bold>Total</Text>
									<Text type="label" align="right" bold>
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
									</Text>
								</li>
							</ul>

							<Text className="quoterPlanReview__mobileInfoSection__summary__title" bold type="label" align="center">Resumen de los siguientes 11 pagos</Text>

							<ul className="quoterPlanReview__mobileInfoSection__summary__list">
								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getNetAmount() : storedPlan.subscription.cost.getNetAmount()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Pago diferido*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getMonthFeeAmount() : storedPlan.subscription.cost.getMonthFeeAmount()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getSubTotal() : storedPlan.subscription.cost.getSubTotal()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTaxes()}
									</Text>
								</li>

								<li className="quoterPlanReview__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left" bold>Total</Text>
									<Text type="label" align="right" bold>
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTotal()}
									</Text>
								</li>
							</ul>
						</>
					}
				</section>

				<section className="quoterPlanReview__mobileInfoSection__bottomSection">
					<form>
						<Controller
							name="acceptTerms"
							control={form.control}
							rules={{ required: true }}
							render={({ field }) => (
								<Checkbox onChange={(e) => { field.onChange(e.target.checked) }}>
									<Text type="label" align="left">Acepto los
										<a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer"> términos y condiciones</a> y las
										<a target="_blank" href="https://crabi.com/legal/condiciones-generales" rel="noreferrer"> condiciones generales</a>
									</Text>
								</Checkbox>
							)}
						/>
					</form>

					<Button
						type="button"
						disabled={!form.formState.isValid}
						onClick={nextPage}
					>
						Continuar
					</Button>
				</section>
			</aside>

		</div>
	);
};

export default QuoterPlanReviewPage;