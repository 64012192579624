import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { Policy } from "src/classes/policy/Policy";
import { IAutomaticRenewalRequest } from "../interfaces/IAutomaticRenewalRequest";
import { IPolicyPaymentRequest } from "../interfaces/IPolicyPaymentRequest";

/**
 * 
 */
export namespace QuoterPolicyAdapter {

	
  /**
   * 
   * @param plan 
   */
	export const policyPaymentRequestDataAdapter = (checkout: PlanCheckout, tokenId?: string): IPolicyPaymentRequest => {
    let data: IPolicyPaymentRequest = {
      card: { 
				card: checkout.cardType, 
				card_type: 2,
			},
      payment_plan_id: checkout.paymentId,
      device_session_id: 'UND3F1N3D'
    }

		if (tokenId)
			data.card['encoded_card'] = tokenId;

    return data;
  }

	/**
	 * 
	 * @param policy 
	 * @param value 
	 * @returns 
	 */
	export const policyAutomaticRenewalRequestAdapter = (policy: Policy, value: boolean): IAutomaticRenewalRequest => {
		const request: IAutomaticRenewalRequest = {
			policy_id: policy.id,
			accept: value
		};

		return request;
	}
}