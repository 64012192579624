import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Text } from "src/components/atoms";
import './Anchor.scss';

/**
 *
 */
interface IAnchor {

  to: string;

  external?: boolean;

  target?: string;

  block?: boolean;

  className?: string;

  type?: 'title' | 'subtitle' | 'paragraph' | 'label';
}

/**
 *
 */
export const Anchor = (props: PropsWithChildren<IAnchor>): JSX.Element => {
  const {
    type = 'paragraph'
  } = props;
  return (
    <>
      {
        props.external ?
          <a className={`anchor${props.block? ' anchor--block' : ''}${props.className? ` ${props.className}` : ''}`} href={props.to} target={props.target}>
            <Text type={type} className="anchor__text">{props.children}</Text>
          </a>
          :
          <Link className={`anchor${props.block? ' anchor--block' : ''}${props.className? ` ${props.className}` : ''}`} to={props.to}>
            <Text type={type} className="anchor__text">{props.children}</Text>
          </Link>
      }

    </>
  );
};